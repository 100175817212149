import { captureException } from '@sentry/browser'
import { defineNuxtPlugin } from '#app'
import { NetworkError, UserError, ServiceError } from '~/store/'
import awsExports from '~~/aws-exports.js'

const baseURL = awsExports.baseURL ?? awsExports.aws_cloud_logic_custom[0].endpoint
export class ApiClient {
  constructor (nuxtApp) {
    this.toast = nuxtApp.$toast
    this.error = nuxtApp.error
    this.$axios = nuxtApp.$axios
    this.$axios.defaults.baseURL = baseURL
  }

  async get ({ path, query, option }) {
    try {
      const res = await this.$axios.get(path, {
        params: query,
      })
      return this._handleResponse(res)
    } catch (e) {
      this._errorHandling(e, option)
    }
  }

  async post ({ path, data = null, option }) {
    try {
      const res = await this.$axios.post(path, data, option)
      return this._handleResponse(res)
    } catch (e) {
      this._errorHandling(e, option)
    }
  }

  async put ({ path, data = null, option }) {
    try {
      const res = await this.$axios.put(path, data, option)
      return this._handleResponse(res)
    } catch (e) {
      this._errorHandling(e, option)
    }
  }

  async del ({ path, option }) {
    try {
      const res = await this.$axios.delete(path, option)
      return this._handleResponse(res)
    } catch (e) {
      this._errorHandling(e, option)
    }
  }

  _handleResponse (res) {
    if (res.status < 300) {
      return res.data
    }
    return this._handleErrorResponse(res)
  }

  _handleErrorResponse (res) {
    // 400番台エラー
    // -> 例外発生、個別実装
    if (res.status < 500) {
      throw new UserError(res.status, res.data)
    }
    // 500番台エラー、その他
    // -> エラー画面へリダイレクト
    captureException(res.data)
    this.error({ statusCode: res.status })
    throw new ServiceError(`${res.request.url} returns ${res.status}`)
  }

  _errorHandling (e, option = null) {
    if (!e.response) {
      // ネットワークエラー
      if (!option?.disableToast) {
        // -> トースト表示
        this.toast.global.networkError()
      }
      throw new NetworkError(e)
    }
    return this._handleErrorResponse(e.response)
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const apiClient = new ApiClient(nuxtApp)
  nuxtApp.provide('_api', apiClient)
})
