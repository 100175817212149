/**
 * WebSocket connectionを管理する
 * - connection管理
 * - subsriberの管理
 *  {
 *    channel: [ cb1, cb2, ]
 *  }
 */
import { captureException } from '@sentry/browser'

const initialState = () => ({
  connection: null,
  subscribers: {},
})

export const state = () => Object.assign({}, initialState())

export const mutations = {
  addSubscriber (state, { channel, callback }) {
    if (!state.subscribers[channel]) {
      state.subscribers[channel] = []
    }
    state.subscribers[channel].push(callback)
  },
  setConnection (state, connection) {
    state.connection = connection
  },
}

export const actions = {
  /**
   * login時に呼び出す
   * 画面遷移時に呼びだす(middlewareの実装)
   */
  async connect ({ state, commit }) {
    // すでに接続済みでコネクションが有効であれば何もしない
    if (state.connection && state.connection.readyState === 1) {
      return true
    }

    const wsurl = this.$config.notificationApiEndpoint

    if (!wsurl) {
      console.error('wsurl not found.') // eslint-disable-line no-console
      return false
    }

    const session = await this.$_auth.fetchSession()
    const token = session.getAccessToken().getJwtToken()

    if (!token) {
      return false
    }

    return new Promise((resolve, reject) => {
      const url = `${wsurl}?token=${token}`
      const connection = new WebSocket(url)
      commit('setConnection', connection)

      connection.onopen = function (e) {
        resolve(true)
      }

      connection.onerror = function (e) {
        console.error('WebSocket error', e) // eslint-disable-line no-console
        captureException(e)
        reject(e)
      }

      connection.onmessage = function (e) {
        try {
          const message = JSON.parse(e.data)
          const channel = message.channel

          if (state.subscribers[channel]) {
            state.subscribers[channel].forEach((callback) => {
              callback(message)
            })
          }
        } catch (e) {
          captureException(e)
        }
      }
    })
  },

  /**
   * subscribeする(api呼ぶ)
   * callbackを追加する
   */
  async subscribe ({ state, commit, dispatch }, { channel, callback }) {
    await dispatch('connect')

    commit('addSubscriber', { channel, callback })

    const data = {
      action: 'subscribe',
      channel,
    }

    const command = JSON.stringify(data)
    state.connection.send(command)
  },
}
