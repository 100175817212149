import { NetworkError } from '~/store'

export default async (ctx) => {
  const { store, route, redirect } = ctx

  if (route.path.match(/^\/auth\//)) {
    return
  }
  try {
    await ctx.$_auth.fetchSession()
    return await store.dispatch('Auth/loadUserInfo')
  } catch (e) {
    if (e instanceof NetworkError) {
      const currentUser = store.getters['Auth/user']
      if (currentUser) {
        return
      }
    }
    console.error(e) // eslint-disable-line no-console
    if (route.path.match(/^\/inspection\/force\/*\//)) {
      return
    }
    return redirect('/auth/')
  }
}
