import { render, staticRenderFns } from "./PhotoStudioViewer.vue?vue&type=template&id=5015e543&scoped=true&lang=pug"
import script from "./PhotoStudioViewer.vue?vue&type=script&lang=js"
export * from "./PhotoStudioViewer.vue?vue&type=script&lang=js"
import style0 from "./PhotoStudioViewer.vue?vue&type=style&index=0&id=5015e543&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5015e543",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonTextControlButton: require('/codebuild/output/src2904404819/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonTextControlButton.vue').default,CommonIcon: require('/codebuild/output/src2904404819/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonIcon.vue').default,CommonGhostButton: require('/codebuild/output/src2904404819/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonGhostButton.vue').default,CommonTag: require('/codebuild/output/src2904404819/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonTag.vue').default})
