export default {
  methods: {
    filePresignedUrl (file) {
      if (file.card_image_file_presigned_url) {
        return file.card_image_file_presigned_url
      }

      return require('~/assets/img/common/no-preview.png')
    },
  }
}
