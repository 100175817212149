import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const apiName = 'service'
const endpoint = Object.freeze({
  designDocuments: '/v1/dashboard/task-unregistered-docs',
  bookingInspections: '/v1/dashboard/task-unbooked-inspections',
  improperWorks: '/v1/dashboard/task-improper-items',
  buildingCompletions: '/v1/dashboard/task-unfinished-buildings',
})

const initialState = () => ({
  designDocuments: {},
  bookingInspections: {},
  improperWorks: {},
  buildingCompletions: {},
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getCountDesignDocuments: state => 'meta' in state.designDocuments ? state.designDocuments.meta.total_items : 0,
  getCountBookingInspections: state => 'meta' in state.bookingInspections ? state.bookingInspections.meta.total_items : 0,
  getCountImproperWorks: state => 'meta' in state.improperWorks ? state.improperWorks.meta.total_items : 0,
  getCountBuildingCompletions: state => 'meta' in state.buildingCompletions ? state.buildingCompletions.meta.total_items : 0,
  getListDesignDocuments: state => 'data' in state.designDocuments ? state.designDocuments.data : [],
  getListBookingInspections: state => 'data' in state.bookingInspections ? state.bookingInspections.data : [],
  getListImproperWorks: state => 'data' in state.improperWorks ? state.improperWorks.data : [],
  getListBuildingCompletions: state => 'data' in state.buildingCompletions ? state.buildingCompletions.data : [],
})

export const mutations = Object.assign({}, baseMutations, {
  setDesignDocuments (state, data) {
    state.designDocuments = data
  },
  setBookingInspections (state, data) {
    state.bookingInspections = data
  },
  setImproperWorks (state, data) {
    state.improperWorks = data
  },
  setBuildingCompletions (state, data) {
    state.buildingCompletions = data
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async loadAll ({ commit }, conditions) {
    commit('setDesignDocuments', await this.$_api.get({ apiName, path: endpoint.designDocuments, query: conditions }))
    commit('setBookingInspections', await this.$_api.get({ apiName, path: endpoint.bookingInspections, query: conditions }))
    commit('setImproperWorks', await this.$_api.get({ apiName, path: endpoint.improperWorks, query: conditions }))
    commit('setBuildingCompletions', await this.$_api.get({ apiName, path: endpoint.buildingCompletions, query: conditions }))
  },
})
