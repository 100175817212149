import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/client-files/'
})

const initialState = () => ({
  items: [],
  meta: {},
  params: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, conditions) {
    return this.$_api.get({ path: endpoint.list, query: conditions })
  },
})
