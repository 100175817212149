
import CommonIcon from '~/components/atoms/common/CommonIcon'
import colors from '~/plugins/custom-color'
export default {
  components: {
    CommonIcon
  },
  props: {
    type: {
      type: String,
      default: '', // 'success' or 'info' or 'warning' or 'error'
      required: true
    },
    message: {
      type: String,
      default: '',
      required: true
    },
    linkButtonMessage: {
      type: String,
      default: '',
      required: false
    },
    dismissible: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    icon () {
      switch (this.type) {
        case 'success':
          return 'CheckmarkOutline32'
        case 'warning':
          return 'WarningAlt32'
        case 'error':
          return 'MisuseOutline32'
        default:
          return 'Information32'
      }
    },
    baseStyle () {
      switch (this.type) {
        case 'success':
          return {
            containerBackgroundColor: colors.green.custom1,
            containerBorderColor: colors.green.custom2,
            iconColor: colors.green.custom3,
          }
        case 'warning':
          return {
            containerBackgroundColor: colors.yellow.custom1,
            containerBorderColor: colors.yellow.custom2,
            iconColor: colors.yellow.custom3,
          }
        case 'error':
          return {
            containerBackgroundColor: colors.red.custom1,
            containerBorderColor: colors.red.custom2,
            iconColor: colors.red.custom3,
          }
        default:
          return {
            containerBackgroundColor: colors.blue.custom1,
            containerBorderColor: colors.blue.custom2,
            iconColor: colors.blue.custom3,
          }
      }
    }
  },
  methods: {
    // ______________
    //  Style
    _setContainerStyle () {
      return {
        backgroundColor: this.baseStyle.containerBackgroundColor,
        border: '1px solid ' + this.baseStyle.containerBorderColor,
        borderRadius: '10px'
      }
    },
    _setCommonIconStyle () {
      return {
        color: this.baseStyle.iconColor,
      }
    },
    _setLinkButtonStyle () {
      return {
        color: colors.blue.custom3,
        textDecoration: 'underline',
        marginLeft: '20px'
      }
    }
  }
}
