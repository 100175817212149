
import CommonIcon from 'atoms/common/CommonIcon'
import dateformat from '~/mixins/dateformat'

const defaultPage = 1
const defaultLoadLimit = 10

export default {
  components: {
    CommonIcon,
  },
  mixins: [
    dateformat
  ],
  props: {
    item: { type: Object, required: true }
  },
  computed: {
    date () {
      const today = new Date()
      const createdAt = new Date(this.item.created_at)
      const diffDay = (today - createdAt) / 86400000
      if (diffDay < 1) {
        const diffHour = diffDay * 24
        if (diffHour < 1) {
          return `${parseInt(diffHour * 60)}分前`
        } else {
          return `${parseInt(diffHour)}時間前`
        }
      } else if (diffDay < 5) {
        return `${parseInt(diffDay)}日前`
      } else {
        return this.dateformat(createdAt, 'M月D日')
      }
    }
  },
  methods: {
    async jump () {
      await this.$store.dispatch('Auth/setCurrentClient', { id: this.item.client_id })
      this.$store.dispatch('Mypage/updateNotificationRead', {
        notificationId: this.item.id,
        reloadConditions: { page: defaultPage, limit: defaultLoadLimit },
      })
      if (this.item.redirect_path) {
        this.$router.push(this.item.redirect_path)
        return
      }

      let path = null
      let query = null

      switch (this.item.notifiable_type) {
        case 'building':
          path = '/building/detail/'
          query = {
            id: this.item.notifiable_id,
          }
          break
        case 'post':
        case 'comment':
          path = '/building/handover/'

          query = {
            id: this.item.building.id,
            post: this.item.post.id
          }
          break
        default:
          break
      }

      if (path && query) {
        this.$router.push({ path, query })
      }
    },
  },
}
