const message = 'OSを最新のバージョンにアップデートしてください。'

export default function (toast) {
  try {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
      const iosVersion = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
      if (iosVersion[0] <= 13) {
        showOsWarning(toast.$toast)
      }
    }
    const ua = navigator.userAgent
    if (ua.indexOf('Android') > 0) {
      const androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8))
      if (androidversion <= 7) {
        showOsWarning(toast.$toast)
      }
    }
  } catch (e) {
    return true
  }
  return true
}

const showOsWarning = function (toast) {
  const isToast = toast.toasts.some(i => i.el.textContent === `${message}`)
  if (isToast) {
    return true
  }
  toast.error(message, {
    duration: 0,
    position: 'top-center'
  })
}
