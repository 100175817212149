import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endPoint = '/v1/item-sheet/{itemSheetId}/versions'
const endPointApprove = '/v1/item-sheet-version/{itemSheetVersionId}/approve'

const initialState = () => ({
  items: [],
  meta: {},
  params: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, { itemSheetId, conditions }) {
    const path = endPoint.replace('{itemSheetId}', itemSheetId)
    return this.$_api.get({ path, query: conditions })
  },
  approvedItem ({ commit }, itemSheetVersionId) {
    const path = endPointApprove.replace('{itemSheetVersionId}', itemSheetVersionId)
    return this.$_api.put({ path })
  },
})
