
import { mapGetters } from 'vuex'

import CommonPageHeader from 'atoms/common/CommonPageHeader'
import Copyright from 'atoms/Copyright'
import LayoutHeader from '~/components/organisms/layout/LayoutHeader'
export default {
  components: {
    LayoutHeader,
    CommonPageHeader,
    Copyright,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      layout: 'Layout/get',
      registerTo: 'Layout/getRegisterTo',
      getArrowLink: 'Layout/getArrowLink',
      buttons: 'Layout/getButtons',
    }),
    breadcrumbs () {
      return this.layout.breadcrumbs
    },
    title () {
      if (this.layout.title) {
        return this.layout.title
      }
      const last = this.$_.last(this.breadcrumbs)
      return last ? last.text : this.$nuxt.context.$config.serviceName
    },
    arrowLink () {
      return this.getArrowLink ? this.getArrowLink : '/'
    }
  },
}
