import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
const endpoint = Object.freeze({
  item: '/v1/audit-report/', // 現場監査記録書、第三者監査の発行
  items: '/v1/audit-report/exports/', // 現場監査記録書一覧取得
  auditReportDetail: '/v1/audit-report/{auditReportId}/', // 現場監査記録書内容取得
  auditReportExportDetail: '/v1/audit-report/export/{auditReportExportId}/', // 製本依頼内容情報取得
  delete: '/v1/audit-report/export/{auditReportExportId}/', // 現場監査記録書削除
  bookOrder: '/v1/audit-report/export/{auditReportExportId}/bookmaking', // 現場監査記録書製本依頼
  bookmakings: '/v1/audit-report/bookmakings/', // 現場監査記録書の製本注文履歴取得
})

export const getters = Object.assign({}, baseGetters)

const initialState = () => ({
  items: [],
  meta: {},
})

export const state = () => initialState()

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  },
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems (_, params) {
    return await this.$_api.get({ path: endpoint.items, query: params })
  },
  async submitItem (_, record) {
    return await this.$_api.post({ path: endpoint.item, data: record })
  },
  async deleteAuditReportExport (_, auditReportExportId) {
    const path = endpoint.delete.replace('{auditReportExportId}', auditReportExportId)
    return await this.$_api.del({ path })
  },
  async fetchAuditReport (_, auditReportId) {
    const path = endpoint.auditReportDetail.replace('{auditReportId}', auditReportId)
    return await this.$_api.get({ path })
  },
  async fetchBookmakings (_, params) {
    return await this.$_api.get({ path: endpoint.bookmakings, query: params })
  },
  async bookOrder (_, params) {
    const path = endpoint.bookOrder.replace('{auditReportExportId}', params.audit_report_export_id)
    return await this.$_api.post({ path, data: params })
  },
  async fetchItem (_, auditReportExportId) {
    const path = endpoint.auditReportExportDetail.replace('{auditReportExportId}', auditReportExportId)
    return await this.$_api.get({ path })
  },
})
