const apiName = 'service'
const endpoint = Object.freeze({
  progress: '/v1/buildings/progress',
})

export default {
  async fetchItemsWithAudit (_, params) {
    return await this.$_api.get({ apiName, path: endpoint.progress, query: params })
  },
}
