import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { defineNuxtPlugin } from '#app'
import awsExports from '~~/aws-exports.js'
const poolData = {
  UserPoolId: awsExports.aws_user_pools_id,
  ClientId: awsExports.aws_user_pools_web_client_id,
}

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

class Auth {
  constructor (nuxtApp) {
    this.context = nuxtApp
    this.session = null
    this.cognitoUser = null
    this.currentClientId = null
  }

  setCurrentClientId (clientId) {
    this.currentClientId = clientId
  }

  currentUser () {
    return this.cognitoUser
  }

  fetchCurrentUser () {
    if (this.cognitoUser) {
      return this.cognitoUser
    }
    this.cognitoUser = userPool.getCurrentUser()
    return this.cognitoUser
  }

  async fetchSession (force = false) {
    if (this.session && !force) {
      await this.tokenCheck()
      return this.session
    }

    if (!this.fetchCurrentUser()) {
      this.session = null
      return null
    }

    const self = this
    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession(async (e, session) => {
        if (e) {
          self.session = null
          return reject(e)
        }
        self.session = session
        await this.tokenCheck()
        resolve(session)
      })
    })
  }

  tokenCheck () {
    if (!this.isTokenExpired()) {
      return
    }
    return this.refreshToken()
  }

  refreshToken () {
    const self = this
    return new Promise((resolve, reject) => {
      this.cognitoUser.refreshSession(this.session.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err)
        }
        self.session = session
        resolve(session)
      })
    })
  }

  isTokenExpired () {
    const now = Math.floor(Date.now() / 1000)
    const expiration = this.session.getAccessToken().getExpiration()
    const limit = expiration - 300 // buffer 5 min
    return limit < now
  }

  signIn (email, password) {
    const userData = {
      Username: email,
      Pool: userPool
    }
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password
    })
    const self = this
    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (cognitoUserSession) => {
          self.session = cognitoUserSession
          resolve(cognitoUserSession)
        },
        onFailure: (err) => {
          reject(err)
        },
        newPasswordRequired: (userAttr, _) => {
          delete userAttr.email_verified

          const err = new Error()
          err.code = 'NewPasswordRequired'
          err.cognitoUser = userAttr
          reject(err)
        },
      })
    })
  }

  completeNewPassword (_, newPassword) {
    const self = this
    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (cognitoUserSession) => {
          self.session = cognitoUserSession
          resolve(cognitoUserSession)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  changePassword (oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
        if (err) {
          reject(err)
        }
        resolve(res)
      })
    })
  }

  forgotPassword (username) {
    const userData = {
      Username: username,
      Pool: userPool
    }
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onSuccess: (res) => {
          resolve(res)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  forgotPasswordSubmit (verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (res) => {
          resolve(res)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  signOut () {
    this.fetchCurrentUser()
    if (!this.cognitoUser) {
      return
    }
    this.cognitoUser.signOut()
    this.session = null
    this.cognitoUser = null
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const auth = new Auth(nuxtApp)
  auth.fetchSession()
  nuxtApp.provide('_auth', auth)
})
export { Auth }
