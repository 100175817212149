
import { mapGetters } from 'vuex'
import UserNotificationContainer from 'organisms/UserNotificationContainer'
import AccountMenu from 'organisms/AccountMenu'
import allMenu from '~/assets/json/navigationMenu'
import CommonRoleLabel from '~/components/atoms/common/CommonRoleLabel'
import CommonIcon from '~/components/atoms/common/CommonIcon'

export default {
  components: {
    UserNotificationContainer,
    AccountMenu,
    CommonRoleLabel,
    CommonIcon
  },
  data () {
    return {
      drawer: null,
      drawerWidth: 272,
      mini: true,
      allMenu,
    }
  },
  computed: {
    ...mapGetters({
      name: 'Auth/getName',
      role: 'Auth/getRole'
    }),
    topPage () {
      switch (this.role) {
        case 'admin':
          return '/dashboard/'
        case 'assignee':
        case 'designer':
        case 'qm':
        case 'workmen':
        default:
          return '/mypage/'
      }
    },
    menu () {
      return this.allMenu[this.role]
    },
    subGroup () {
      return new Array(this.menu.length)
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  mounted () {
    this.mini = !this.isMobile
  },
}
