import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/mypage/news/',
  unreadCount: '/v1/mypage/news/unread/count',
})

const initialState = () => ({
  items: [],
  meta: null,
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems (_, conditions) {
    return await this.$_api
      .get({
        path: endpoint.list,
        query: conditions,
      })
  },
  async unreadCount () {
    return await this.$_api
      .get({
        path: endpoint.unreadCount,
      })
  },
})
