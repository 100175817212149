import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions
} from './BaseStore'

const endpoint = Object.freeze({
  detail: '/v1/client/{clientId}/detail',
})

const initialState = () => ({
  items: [],
  meta: {},
  params: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItem (_, clientId) {
    const path = endpoint.detail.replace('{clientId}', clientId)
    return await this.$_api.get({ path })
  },
})
