import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const initialState = () => ({
  items: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems ({ commit }) {
    const path = '/v1/dictionary/document-types/'
    const items = await this.$_api.get({ path })
    commit('setItems', items)
    return items
  },
})
