import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpointOrganizations = '/v1/organizations'
const endpointCreateOrganization = '/v1/organization'
const endpointGetOrganization = '/v1/organization/'
const endpointUpdateOrganization = '/v1/organization/'

const initialState = () => ({
  items: [],
  meta: {},
  params: {},
  item: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getItems (state) {
    return state.items
  },
  getMeta (state) {
    return state.meta
  },
  getById: state => id => state.items.find(item => item.id === id),
  getAll: state => state.items,
})

export const mutations = Object.assign({}, baseMutations, {
  setMeta (state, meta) {
    state.meta = meta
  },
  setItem (state, item) {
    state.item = item
  },
  pushItem (state, item) {
    state.items.push(item)
  },
  updateItem (state, { id, entity }) {
    const target = state.items.find(item => item.id === id)
    if (target) {
      Object.assign(target, entity)
    }
  },
  setCurrent (state, item) {
    state.current = item
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, payload) {
    let params = {}
    if (payload) {
      params = Object.assign({}, payload)
    }
    if (Object.keys(params).includes('construction_type_ids')) {
      params.construction_type_ids = params.construction_type_ids.toString()
    }
    return this.$_api.get({ path: endpointOrganizations, query: params })
  },
  submit (_, entity) {
    return this.$_api.post({ path: endpointCreateOrganization, data: entity })
  },
  update (_, { id, entity }) {
    const path = endpointUpdateOrganization + id
    return this.$_api.put({ path, data: entity })
  },
  fetchItem (_, payload) {
    const path = endpointGetOrganization + payload.organizationId
    return this.$_api.get({ path })
  }
})
