export default {
  updateDetected ({ commit }) {
    commit('setUpdate', true)
  },
  async loadMaintenance ({ commit }) {
    const maintenance = await fetchMaintenanceData()
    if (!maintenance) {
      return
    }
    commit('setMaintenance', maintenance)
  }
}

const fetchMaintenanceData = async () => {
  try {
    const res = await fetch('/MAINTENANCE.json', { cache: 'no-store' })
    if (!res.ok) {
      throw new Error(`failed to fetch ${res.url}`)
    }
    return await res.json()
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
    return null
  }
}
