import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = '/v1/area/'

const initialState = () => ({
  items: []
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems (_) {
    const items = await this.$_api
      .get({ path: endpoint })
    return items
  },
  async submit (_, newArea) {
    const params = { area: newArea }
    await this.$_api.post({ path: endpoint, data: params })
  },
  async update (_, updated) {
    const params = { area: updated }
    const path = endpoint + updated.id
    await this.$_api.put({ path, data: params })
  },
})
