import { captureException } from '@sentry/browser'

export default function (ctx) {
  const { $axios, error } = ctx
  $axios.onError((e) => {
    if (!e.response) {
      return
    }
    if (e.response.status >= 500) {
      captureException(e)
      error({ statusCode: e.response.status })
    }
  })

  $axios.onRequest((config) => {
    const auth = ctx.$_auth
    if (!auth) {
      return config
    }
    if (auth.currentClientId) {
      config.headers['x-q-client-id'] = auth.currentClientId
    }
    const token = auth.session?.getAccessToken().getJwtToken()
    if (!token) {
      return config
    }
    config.headers.Authorization = `Bearer ${token}`

    return config
  })
}
