import { captureException } from '@sentry/browser'
import cameraData from '~/plugins/camera-data'

export class CameraError extends Error {
  constructor (e, ...params) {
    super(...params)
    this.name = 'CameraError'
    this.message = `${e.name}: ${e.message}`
  }
}

const initialState = () => ({
  stream: null,
  recorder: null,
  looseCamera: false,
})

export const state = () => Object.assign({}, initialState())

export const getters = {
  getStream: state => state.stream,
  getRecorder: state => state.recorder,
  getConstraints: state => state.looseCamera ? cameraData.loose : cameraData.strict,
  isLooseCamera: state => state.looseCamera,
  isValid: state => state.recorder?.state === 'recording'
}

export const mutations = {
  clearStream (state) {
    Object.assign(state, initialState())
  },
  setStream (state, { stream, recorder }) {
    state.stream = stream
    state.recorder = recorder
  },
  looseCamera (state) {
    state.looseCamera = true
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
}

export const actions = {
  async initializeStream ({ state, dispatch, commit, getters }) {
    if (state.stream !== null || state.recorder !== null) {
      await dispatch('terminateStream')
    }

    try {
      const constraints = getters.getConstraints
      const stream = await navigator.mediaDevices.getUserMedia({
        video: constraints,
        audio: false,
      })
      const track = stream.getVideoTracks()[0]
      await track.applyConstraints({
        aspectRatio: cameraData.config.aspect
      })
      dispatch('startStream', stream)
      return stream
    } catch (e) {
      if (!getters.isLooseCamera) {
        commit('looseCamera')
        return dispatch('initializeStream')
      }
      throwCameraError(e)
    }
  },
  startStream ({ commit }, stream) {
    const recorder = new MediaRecorder(stream)
    recorder.onerror = (e) => {
      captureException(e)
    }
    recorder.start()
    commit('setStream', { stream, recorder })
  },
  terminateStream ({ state, commit }) {
    terminateTracks({ state })
    terminateRecorder({ state })

    commit('clearStream')
  },

  pauseStream ({ state }) {
    if (!state.recorder) {
      return false
    }
    if (state.recorder.state !== 'recording') {
      return false
    }
    try {
      state.recorder.pause()
      return true
    } catch (e) {
      throwCameraError(e)
    }
  },
  resumeStream ({ state }) {
    if (!state.recorder) {
      return false
    }
    try {
      state.recorder.resume()
      return true
    } catch (e) {
      throwCameraError(e)
    }
  }
}
const terminateTracks = ({ state }) => {
  if (!state.stream) {
    return
  }
  try {
    state.stream.getTracks().forEach((camera) => {
      camera.stop()
    })
  } catch (e) {
    handleCameraError(e)
    // skip error
  }
}
const terminateRecorder = ({ state }) => {
  if (!state.recorder) {
    return
  }
  try {
    state.recorder.stop()
  } catch (e) {
    handleCameraError(e)
    // skip error
  }
}

const throwCameraError = (e) => {
  const cameraError = handleCameraError(e)
  throw cameraError
}
const handleCameraError = (e) => {
  const cameraError = new CameraError(e)
  captureException(cameraError)
  console.error(cameraError) // eslint-disable-line no-console
  return cameraError
}
