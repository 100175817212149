import Vue from 'vue'

Vue.mixin({
  methods: {
    nl2br (value) {
      if (value) {
        return value.replace(/\n/g, '<br>')
      }
      return ''
    },
    escapeHtml (value) {
      return value.replace(/[&'`"<>]/g, function (match) {
        return {
          '&': '&amp;',
          "'": '&#x27;',
          '`': '&#x60;',
          '"': '&quot;',
          '<': '&lt;',
          '>': '&gt;',
        }[match]
      })
    },
  }
})
