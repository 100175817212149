

export default {
  props: {
    text: { type: String, default: null },
    value: { type: [String, Number], default: null },
    color: { type: String, default: null },
    textColor: { type: String, default: null },
    small: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    customStyle: { type: Object, default: () => {} },
    close: { type: Boolean, default: false }
  },
}

