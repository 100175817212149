export default function (context) {
  maintenanceCheck(context)
}

const maintenanceCheck = async (ctx) => {
  await ctx.store.dispatch('app/loadMaintenance')

  const current = ctx.store.getters['app/currentMaintenance']
  if (current) {
    const message = current.message || 'ただいまメンテナンス中です。'
    ctx.error({ statusCode: 503, message, maintenance: current })
  }
}
