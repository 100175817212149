
import { mapGetters } from 'vuex'
import Copyright from 'atoms/Copyright'
export default {
  components: {
    Copyright,
  },
  computed: {
    ...mapGetters({
      currentVersion: 'app/getCurrentVersion',
    })
  }
}
