import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpointRoles = '/v1/roles/'

const initialState = () => ({
  items: [],
  currentId: null
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getItems (state) {
    return state.items
  }
})

export const mutations = Object.assign({}, baseMutations, {
  setItems (state, items) {
    state.items = items
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems ({ commit }) {
    const roles = await this.$_api.get({ path: endpointRoles })
    commit('setItems', roles)
    return roles
  }
})
