
import { mapGetters } from 'vuex'
import CommonIcon from 'atoms/common/CommonIcon'
import CommonIconButton from 'atoms/common/CommonIconButton'
import CommonDataControlButton from 'atoms/common/CommonDataControlButton'
import CommonTextControl from 'atoms/common/CommonTextControl'
import UserNotificationItem from 'organisms/UserNotificationItem'

const defaultPage = 1
const defaultLoadLimit = 10
const maxItem = 30 // 表示件数は最大30件(デザイナーより)

export default {
  components: {
    CommonIcon,
    CommonIconButton,
    CommonDataControlButton,
    CommonTextControl,
    UserNotificationItem,
  },
  data () {
    return {
      isOpened: false,
      showAll: false,
      loading: false,
      pageAllMode: defaultPage,
      pageUnreadMode: defaultPage
    }
  },
  fetch () {
    this.$store.dispatch('Mypage/loadUnreadNotifications', {
      conditions: { limit: defaultLoadLimit },
      append: false
    })
    this.$store.dispatch('Mypage/loadNotifications', {
      conditions: { limit: defaultLoadLimit },
      append: false
    })
  },
  computed: {
    ...mapGetters({
      notifications: 'Mypage/getNotifications',
      unreadNotifications: 'Mypage/getUnreadNotifications',
      totalCount: 'Mypage/getCountTotalNotifications',
      unreadCount: 'Mypage/getCountUnreadNotifications',
    }),
    items () {
      return this.showAll
        ? this.notifications
        : this.unreadNotifications
    },
    groupedItems () {
      return Object.entries(this.$_.groupBy(this.items, 'notifiable_type'))
    },
    groupTitle () {
      return (type) => {
        switch (type) {
          case 'post':
            return '申し送り'
          case 'comment':
            return 'コメント'
          case 'building':
            return '物件'
          default:
            return ''
        }
      }
    },
    notificationCount () {
      return this.showAll ? this.totalCount : this.unreadCount
    },
    displayedItemCount () {
      return this.items.length
    },
    showLoadMoreBtn () {
      return this.displayedItemCount < maxItem &&
        this.displayedItemCount < this.notificationCount
    }
  },
  methods: {
    switchShowAllOrNot () {
      this.showAll = !this.showAll
    },
    async loadMore () {
      this.loading = true
      if (this.showAll) {
        this.pageAllMode = this.displayedItemCount <= defaultLoadLimit ? defaultPage + 1 : this.pageAllMode + 1
        const conditions = { page: this.pageAllMode, limit: defaultLoadLimit }
        await this.$store.dispatch('Mypage/loadNotifications', { conditions, append: true })
      } else {
        this.pageUnreadMode = this.displayedItemCount <= defaultLoadLimit ? defaultPage + 1 : this.pageUnreadMode + 1
        const conditions = { page: this.pageUnreadMode, limit: defaultLoadLimit }
        await this.$store.dispatch('Mypage/loadUnreadNotifications', { conditions, append: true })
      }
      this.loading = false
    },
    async readAll () {
      if (this.unreadCount === 0) {
        return
      }
      this.loading = true
      const reloadConditions = { page: defaultPage, limit: defaultLoadLimit }
      await this.$store.dispatch('Mypage/updateNotificationReadAll', { reloadConditions })
      this.loading = false
    }
  },
}
