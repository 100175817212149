
import { captureException } from '@sentry/browser'
import { mapGetters } from 'vuex'

import CommonPageHeader from 'atoms/common/CommonPageHeader'
import CommonNotificationBar from 'atoms/common/CommonNotificationBar'
import Copyright from 'atoms/Copyright'
import LayoutNavBar from '~/components/organisms/layout/LayoutNavBar'
import InspectionManager from '~/components/organisms/InspectionManager'
import InspectionQueueManager from '~/components/organisms/InspectionQueueManager'
import PhotoStudio from '~/components/molecules/PhotoStudio'

const message = 'Qualizが更新されました。右の更新ボタンを押してください。'

export default {
  components: {
    LayoutNavBar,
    CommonPageHeader,
    CommonNotificationBar,
    InspectionManager,
    InspectionQueueManager,
    Copyright,
    PhotoStudio,
  },
  data () {
    return {
      updatDialog: false,
      newsUnreadCount: 0,
    }
  },
  fetch () {
    if (this.updatedDetected) {
      this.showUpdateConfirm()
    }
    this.getNewsUnreadCount()
  },
  head () {
    return {
      title: this.title
    }
  },
  computed: {
    ...mapGetters({
      layout: 'Layout/get',
      registerTo: 'Layout/getRegisterTo',
      getArrowLink: 'Layout/getArrowLink',
      buttons: 'Layout/getButtons',
      isNs: 'Auth/isNs',
      isWorkmen: 'Auth/isWorkmen',
      hideNews: 'Layout/hideNews',
      getNotifications: 'Layout/getNotifications',
      updatedDetected: 'app/getUpdate',
      currentVersion: 'app/getCurrentVersion',
    }),
    breadcrumbs () {
      return this.layout.breadcrumbs
    },
    title () {
      if (this.layout.title) {
        return this.layout.title
      }
      const last = this.$_.last(this.breadcrumbs)
      return last ? last.text : this.$nuxt.context.$config.serviceName
    },
    arrowLink () {
      return this.getArrowLink
    },
    notifications () {
      return this.getNotifications || []
    },
    osVersionCheck () {
      try {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
          const iosVersion = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
          if (iosVersion[0] <= 13) {
            return true
          }
        }
        const ua = navigator.userAgent
        if (ua.indexOf('Android') > 0) {
          const androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8))
          if (androidversion <= 7) {
            return true
          }
        }
      } catch (e) {
        captureException(e)
        return false
      }
      return false
    },
  },
  watch: {
    updatedDetected (cur, old) {
      if (!old && cur) {
        this.showUpdateConfirm()
      }
    },
    $route () {
      this.getNewsUnreadCount()
    }
  },
  methods: {
    linkButtonClick () {
      this.$router.push('/news/')
    },
    showUpdateConfirm () {
      this.$toast.info(message, {
        action: {
          text: '更新',
          onClick: () => {
            window.location.reload()
          },
        },
        duration: 0,
      })
    },
    async getNewsUnreadCount () {
      this.newsUnreadCount = await this.$store.dispatch('News/unreadCount')
    },
  },
}
