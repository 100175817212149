
import dateformat from '~/mixins/dateformat'

export default {
  mixins: [
    dateformat
  ],
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  head () {
    return {
      title: this.title
    }
  },
  computed: {
    icon () {
      switch (this.error.statusCode) {
        case 503:
          return 'Construction16'
        case 500:
        case 404:
        default:
          return 'FaceDizzy32'
      }
    },
    title () {
      switch (this.error.statusCode) {
        case 503:
          return 'ただいまご利用できません。'
        case 404:
          return 'ページが見つかりません'
        case 500:
        default:
          return 'ごめんなさい、うまくいかないようです'
      }
    },
    description () {
      return this.error.message ??
        'Qualizからの応答がありません。あなたの端末がインターネットにつながっているか、確認してみてください。'
    },
    maintenance () {
      return this.error?.maintenance
    },
    maintenanceDateTime () {
      if (this.maintenance?.start && this.maintenance?.end) {
        return this.datetimeformat(this.maintenance?.start) + ' 〜 ' + this.datetimeformat(this.maintenance?.end)
      }
      if (this.maintenance?.start) {
        return this.datetimeformat(this.maintenance?.start) + ' 〜 '
      }
      return null
    },
    reloadButtonEnabled () {
      return true
    },
    goBackHomeButtonEnabled () {
      return this.error.statusCode === 404
    },
    innerContactButtonEnabled () {
      return true
    },
    cardMarginTop () {
      const height = window.innerHeight - 250
      return {
        height: height + 'px',
      }
    },
    isInMaintenance () {
      return this.error.statusCode === 503
    },
    isDev () {
      return this.$root.context.isDev
    }
  },
  methods: {
    _goBackHome () {
      this.$router.push('/')
    },
    _reload () {
      location.reload()
    },
  }
}
