import { captureException } from '@sentry/browser'
import { cacheNames } from 'workbox-core'
import axios from 'axios'

import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
import { ServiceError } from '.'

const endpoint = Object.freeze({
  design: '/v1/building/{buildingId}/files/design/',
  postDesign: '/v1/building/{buildingId}/file/design/',
  postDesignDetail: '/v1/building/{buildingId}/file/design/{buildingFileId}',
})

const initialState = () => ({
  buildingId: null,
  items: [],
  meta: null,
  params: null
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})
export const mutations = Object.assign({}, baseMutations, {
  reset (state, buildingId) {
    state.buildingId = buildingId
    state.items = []
    state.meta = null
    state.params = null
  },
  setResponse (state, { res, params }) {
    state.meta = res.meta
    state.items = res.data
    state.params = params
    state.buildingId = params.buildingId
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})
export const actions = Object.assign({}, baseActions, {
  async fetchAllDesignFilesCount ({ dispatch }, params) {
    const setParams = Object.assign({ conditions: { limit: 1 } }, params)
    const res = await dispatch('fetchDesignFiles', setParams)

    if (res.meta) {
      return res.meta.total
    }

    return 0
  },
  async fetchDesignFiles (_, { buildingId, conditions = {} }) {
    const path = endpoint.design.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path, query: conditions })
  },
  async loadDesignFiles ({ dispatch }, { params, force }) {
    const res = await dispatch('_loadDesignFiles', { params, force })
    if (caches) {
      await prefetchDesignFiles(res, force)
    }

    return res
  },
  async loadDesignFilesWithoutCache ({ dispatch }, { params, force }) {
    return await dispatch('_loadDesignFiles', { params, force })
  },
  async _loadDesignFiles ({ state, commit, dispatch }, { params, force }) {
    if (state.buildingId !== params.buildingId) {
      commit('reset', params.buildingId)
    } else if (state.items && state.length && !force) {
      return state.items
    }
    const res = await dispatch('fetchDesignFiles', params)
    commit('setResponse', { res, params })
    return res.data
  },
  reloadDesignFiles ({ state, dispatch }) {
    return dispatch('loadDesignFiles', {
      params: state.params,
      force: true
    })
  },
  async reloadDesignFilesWithoutCache ({ state, dispatch }) {
    return await dispatch('_loadDesignFiles', {
      params: state.params,
      force: true
    })
  },
  async postDesignFile ({ dispatch }, { buildingId, documentTypeIds, memo, file, eventHandler }) {
    const params = {
      document_type_ids: documentTypeIds,
      file: {
        memo,
        filename: file.name,
        mimetype: file.type
      }
    }
    const path = endpoint.postDesign.replace('{buildingId}', buildingId)
    const res = await this.$_api.post({ path, data: params })

    return await dispatch('NotificationConnection/subscribe', {
      channel: '/files/' + res.file.id,
      callback: (msg) => {
        if (eventHandler && typeof eventHandler === 'function') {
          eventHandler(msg)
        }
      }
    }, { root: true })
      .catch((e) => {
        captureException(e)
      })
      .then(() => {
        const putUrl = res.file.file_put_presigned_url
        return uploadFile(putUrl, file)
      })
      .then(() => {
        return res
      })
      .catch((e) => {
        captureException(e)
        throw e
      })
  },
  async deleteDesignFile (_, { buildingId, buildingFileId }) {
    const path = endpoint.postDesignDetail.replace('{buildingId}', buildingId).replace('{buildingFileId}', buildingFileId)
    return await this.$_api.del({ path })
  }
})

const uploadFile = async (url, file) => {
  const fileRes = await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': file.type,
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
      }
    })

  if (fileRes.status !== 200) { // display error if exist
    throw new ServiceError(fileRes.status, `failed to upload file to [${url}] `)
  }
  return true
}

const prefetchDesignFiles = async (designFiles, force) => {
  const cache = await caches.open(cacheNames.runtime)
  const cachePromises = designFiles.map(df => prefetchDesignFile(df, cache, force))
  return Promise.all(cachePromises)
}

const prefetchDesignFile = (designFile, cache, force) => {
  const file = designFile.file
  const promises = []
  promises.push(prefetchUrl(file.original_file_presigned_url, cache, force))
  if (file.card_image_file_presigned_url) {
    promises.push(prefetchUrl(file.card_image_file_presigned_url, cache, force))
  }
  if (file.icon_image_file_presigned_url) {
    promises.push(prefetchUrl(file.icon_image_file_presigned_url, cache, force))
  }
  return Promise.all(promises)
}

const prefetchUrl = async (url, cache, force) => {
  const hit = await cache.match(url, { ignoreSearch: true })
  if (hit) {
    if (!force) {
      return true
    }
    await cache.delete(url, {
      ignoreSearch: true
    })
  }
  return cache.add(url)
}
