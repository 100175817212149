
import CommonLargeButton from 'atoms/common/CommonLargeButton'
import CommonIcon from 'atoms/common/CommonIcon'
export default {
  components: {
    CommonLargeButton,
    CommonIcon,
  },
  props: {
    type: { type: String, default: null }, // 'notFound' or 'noResults' or 'registration' or 'error'

    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    supplement: { type: String, default: '' },

    registerButtonEnabled: { type: Boolean, default: false },

    iconStyle: { type: Object, default: null },
    titleStyle: { type: Object, default: null },
    descriptionStyle: { type: Object, default: null },
    supplementStyle: { type: Object, default: null },
    actionsStyle: { type: Object, default: null },
  },
  computed: {
    innerIcon () {
      if (this.icon) {
        return this.icon
      }
      switch (this.type) {
        case 'noResults':
          return 'FaceDissatisfied32'
        case 'registration':
          return 'FaceWink32'
        case 'notFound':
        default:
          return 'FaceDizzy32'
      }
    },
    innerIconColor () {
      if (this.iconColor) {
        return this.iconColor
      }

      switch (this.type) {
        case 'registration':
          return 'var(--v-func-green3-base)'
        case 'error':
        case 'internalServerError':
        case 'notFound':
          return 'var(--v-func-red3-base)'
        case 'noResults':
        default:
          return 'var(--v-base-grey7-base)'
      }
    },
    innerTitle () {
      if (this.title) {
        return this.title
      }
      switch (this.type) {
        case 'noResults':
          return '検索結果なし'
        case 'registration':
          return 'さあ、データを登録してみましょう！'
        default:
          return 'ごめんなさい、うまくいかないようです'
      }
    },
    innerDescription () {
      if (this.description) {
        return this.description
      }

      switch (this.type) {
        case 'noResults':
          return '検索条件に該当するデータがありません。条件を変えてもう一度検索してください。'
        case 'registration':
          return 'この項目のデータはまだ登録されていないようです。最初のデータを登録してみましょう。'
        default:
          return ''
      }
    },
    innerRegisterButtonEnabled () {
      if (this.registerButtonEnabled) {
        return true
      }
      return this.type === 'registration'
    },
    innerReloadButtonEnabled () {
      if (this.reloadButtonEnabled) {
        return true
      }
      return this.type === 'error' || this.type === 'internalServerError'
    },
    innerGoBackHomeButtonEnabled () {
      if (this.goBackHomeButtonEnabled) {
        return true
      }
      return this.type === 'notFound'
    },
    innerContactButtonEnabled () {
      if (this.contactButtonEnabled) {
        return true
      }
      return this.type === 'notFound' || this.type === 'error' || this.type === 'internalServerError'
    },
  },
  methods: {
    _setIconStyle () {
      if (this.iconStyle) {
        return this.iconStyle
      }
      return {}
    },
    _setTitleStyle () {
      if (this.titleStyle) {
        return this.titleStyle
      }
      return {}
    },
    _setDescriptionStyle () {
      if (this.descriptionStyle) {
        return this.descriptionStyle
      }
      return {}
    },
    _setSupplementStyle () {
      if (this.supplementStyle) {
        return this.supplementStyle
      }
      return {}
    },
    _setActionsStyle () {
      if (this.actionsStyle) {
        return this.actionsStyle
      }
      return {}
    },
  }
}
