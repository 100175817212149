
export default {
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: null },
    color: { type: String, default: '' },
    elevation: { type: Number, default: 1 },
    to: { type: String, default: '' },
    outlined: { type: Boolean, default: true },
    width: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    pressed: { type: Boolean, default: false },
  },
  computed: {
    $_block () {
      return this.block || this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
