import * as Sentry from '@sentry/browser'
import { UserError, NetworkError, ServiceError } from '.'

const localStorageKey = {
  currentRole: 'auth-current-client-role',
  current: 'auth-current-client',
  clients: 'auth-clients'
}

const endpoint = Object.freeze({
  clients: '/v1/auth/clients/'
})

const initialState = () => ({
  session: null,
  user: null,
  userInfo: null,
  role: null,
  clients: [],
  clientRoles: [],
  clientRole: null,
  clientsTotal: {},
  currentClient: null,
})

const putLocalStorage = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj))
}

export const state = () => initialState()

export const getters = {
  getSession: state => state.session,
  userEmail: state => state.user.email,
  getName: state => state.user ? state.user.name : null,
  getCurrentClientRole: (state) => {
    if (state.clientRole) {
      return state.clientRole
    }
    const strageValue = localStorage.getItem(localStorageKey.currentRole)
    if (!strageValue) {
      return null
    }
    return JSON.parse(strageValue)
  },
  getCurrentClient: (state) => {
    if (state.currentClient) {
      return state.currentClient
    }
    const storageValue = localStorage.getItem(localStorageKey.current)
    if (!storageValue) {
      return null
    }
    return JSON.parse(storageValue)
  },
  getRole: (_, getters) => {
    const clientRole = getters.getCurrentClientRole
    if (clientRole) {
      return clientRole.primaryRole.type
    }
    return null
  },
  getClientName: (state, getters) => {
    if (state.currentClient !== null && state.currentClient.name) {
      return state.currentClient.name
    }

    const rawCurrentClient = localStorage.getItem(localStorageKey.current)
    if (!rawCurrentClient) {
      return null
    }
    const currentClient = JSON.parse(rawCurrentClient)
    if (currentClient.name) {
      return currentClient.name
    }

    let clients = getters.getClients
    if (!clients || clients.length === 0) {
      clients = getters.getLocalStorageClients
      if (clients.length === 0) {
        return null
      }
    }
    const client = clients.find(client => client.id === currentClient.id)
    return client?.name
  },
  getRolesByClientId: state => (clientId) => {
    return state.clientRoles.find(cr => cr.client_id === clientId)?.roles
  },
  user: state => state.user,
  getClientRoles: state => state.clientRoles,
  getClientRole: state => (clientId) => {
    return state.clientRoles.find(clientRole => clientRole.client_id === clientId)
  },
  getClientIds: state => state.clientRoles.map(clientRole => clientRole.client_id),
  getClients: state => state.clients,
  getLocalStorageClients: (_) => {
    const strageValue = localStorage.getItem(localStorageKey.clients)
    if (!strageValue) {
      return []
    }
    const clients = JSON.parse(strageValue)
    if (!Array.isArray(clients)) {
      return []
    }
    return clients
  },
  getClientsTotal: state => state.clientsTotal,
  isMe: state => userId => state.user.id === userId,
  isNs: state => state.user?.ns_inspector_id !== null,
  isAdmin: (_, getters) => { return getters.getRole === 'admin' },
  isAdminOnClientId: (_, getters) => (clientId) => {
    return !!getters.getRolesByClientId(clientId)?.some(r => r.type === 'admin')
  },
  isAssignee: (_, getters) => { return getters.getRole === 'assignee' },
  isWorkmen: (_, getters) => { return getters.getRole === 'workmen' }
}

export const mutations = {
  setUserInfo (state, userInfo) {
    const user = userInfo.user
    state.userInfo = userInfo
    state.clientRoles = userInfo.clientRoles
    state.user = user
    Sentry.setUser(user)
  },
  setSession (state, session) {
    state.session = session
  },
  setClientRoles (state, clientRoles) {
    state.clientRoles = clientRoles
  },
  setCurrentClientRole (state, clientRole) {
    state.role = clientRole.primaryRole.type
    state.clientRole = clientRole
    putLocalStorage(localStorageKey.currentRole, clientRole)
  },
  setCurrentClient (state, client) {
    state.currentClient = client
    putLocalStorage(localStorageKey.current, client)
    this.$_auth.setCurrentClientId(client.id)
  },
  setClients (state, clients) {
    state.clients = clients
    putLocalStorage(localStorageKey.clients, clients)
  },
  setClientsTotal (state, meta) {
    state.clientsTotal = meta
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  clear (state) {
    state = initialState()
    localStorage.clear()
  }
}

export const actions = {
  async signIn ({ dispatch, commit }, { username, password }) {
    commit('setInitialState')
    const res = await this.$_auth.signIn(username, password)
    await dispatch('loadUserInfo')
    return res
  },
  signOut ({ commit }) {
    this.$_auth.signOut()
    commit('clear')
  },
  async loadUserInfo ({ dispatch, commit, getters }) {
    const userInfo = await dispatch('fetchUserInfo')
    commit('setUserInfo', userInfo)
    this.$_fire.setUser(userInfo.user)

    if (userInfo.clientRoles && 1 === userInfo.clientRoles.length) {
      commit('setCurrentClientRole', userInfo.clientRoles[0])
      commit('setCurrentClient', { id: userInfo.clientRoles[0].client_id })
    } else if (!state.clientRole) {
      const clientRole = getters.getCurrentClientRole
      if (clientRole) {
        commit('setCurrentClientRole', clientRole)
      }
      const client = getters.getCurrentClient
      if (client) {
        commit('setCurrentClient', client)
      }
    }
    dispatch('loadClients')
  },
  fetchUserInfo (ctx) {
    return this.$_api
      .get({
        path: '/v1/auth'
      })
  },
  async fetchSession ({ commit }) {
    const session = await this.$_auth.fetchSession()
    commit('setSession', session)
    return session
  },

  async fetchClients (_, conditions) {
    return await this.$_api.get({
      path: endpoint.clients, query: conditions
    })
  },
  async loadClients ({ _, dispatch, commit }, conditions) {
    try {
      const res = await dispatch('fetchClients', conditions)
      commit('setClients', res.data)
      commit('setClientsTotal', res.meta.total)
    } catch (e) {
      if (!e.response) {
        throw new NetworkError()
      }
      if (e.response.status === 404) {
        throw new UserError(e.response.status, e.response.data)
      }
      throw new ServiceError(e)
    }
  },
  setCurrentClientId ({ getters, commit }, clientId) {
    const clientRole = getters.getClientRole(clientId)
    if (!clientRole) {
      return
    }
    this.$_auth.setCurrentClientId(clientId)
    commit('setCurrentClientRole', clientRole)
  },
  setCurrentClient ({ getters, commit }, { id, name = null }) {
    const clientRole = getters.getClientRole(id)
    if (!clientRole) {
      return
    }
    commit('setCurrentClientRole', clientRole)
    commit('setCurrentClient', { id, name })
  },
  clear ({ commit }) {
    commit('setInitialState')
  }
}
