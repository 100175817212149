import Dexie from 'dexie'
import { nextTick } from 'vue'
import { ServiceError, UserError } from '.'

const db = new Dexie('queue')
db.version(1).stores({
  requests: '++id'
})
db.version(2).stores({
  queue: '++id,[action+inspectionId]'
}).upgrade((tx) => {
  return tx.requests.each((row) => {
    const entity = row
    delete entity.id
    if ('inspectionId' in entity.data) {
      entity.inspectionId = entity.data.inspectionId
    }
    tx.queue.add(entity)
  })
})
db.version(3).stores({
  requests: null
})

let _running = false

const initialState = () => ({
  length: 0,
  lengthBeforeRetry: 0,
  running: false,
  error: null,
  displayingErrorMessage: false
})
export const state = () => Object.assign({}, initialState())

export const getters = {
  length: state => state.length,
  lengthBeforeRetry: state => state.lengthBeforeRetry,
  running: state => state.running,
  error: state => state.error,
  getQueueByAction: _ => async ({ action, inspectionId }) => {
    return await db.queue.where({ action, inspectionId }).first()
  },
  displayingErrorMessage: state => state.displayingErrorMessage
}

export const mutations = {
  setLength (state, length) {
    state.length = length
  },
  setLengthBeforeRetry (state, length) {
    state.lengthBeforeRetry = length
  },
  start (state) {
    state.running = true
  },
  stop (state) {
    state.running = false
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  setError (state, error) {
    state.error = error
  },
  setErrorMessageDisplaying (state, displaying) {
    state.displayingErrorMessage = displaying
  }
}

export const actions = {
  async push ({ state, dispatch, commit }, { action, data, forceCache = false }) {
    try {
      const entity = { action, data, forceCache }
      if ('inspectionId' in data) {
        entity.inspectionId = data.inspectionId
      }
      if ('evidence' in data) {
        entity.inspectionId = data.evidence.inspection_id
      }
      const inserted = await db.queue.add(entity)
      nextTick(() => {
        dispatch('run')
      })
      return inserted
    } catch (e) {
      if (!state.displayingErrorMessage) {
        commit('setErrorMessageDisplaying', true)
        this.$toast.error('データの登録ができませんでした。NEXT STAGEまでお問い合わせください。', {
          duration: 0,
        })
      }
      throw new ServiceError(e)
    }
  },
  async cancel ({ dispatch }, { queueId }) {
    try {
      await db.queue.delete(queueId)
      await dispatch('count')
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  },
  async count ({ commit }) {
    try {
      const count = await db.queue.count()
      commit('setLength', count)
      return count
    } catch (e) {
      throw new ServiceError(e)
    }
  },
  async run ({ commit, dispatch }) {
    if (_running) {
      return
    }
    commit('setError', null)
    _running = true
    commit('start')
    const request = await db.queue.orderBy('id').first()
    if (!request) {
      commit('setLength', 0)
      commit('stop')
      _running = false
      return
    }
    try {
      await dispatch(
        request.action,
        {
          ...request.data,
          $queueId: request.id
        },
        { root: true })
      await db.queue.delete(request.id)
      nextTick(() => {
        dispatch('run')
      })
    } catch (e) {
      if (e instanceof UserError && !request.forceCache) {
        commit('setError', e)
        await db.queue.delete(request.id)
      }
      commit('stop')
    } finally {
      _running = false
      await dispatch('count')
    }
  },
  async retry ({ state, commit, dispatch }) {
    const current = await dispatch('count')
    commit('setLengthBeforeRetry', state.length)
    dispatch('run')
    return current
  },
  async clear ({ commit }) {
    try {
      await db.queue.clear()
      commit('setInitialState')
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  },
}
