
export default {
  computed: {
    targetYear () {
      const startYear = 2022
      const currentYear = new Date().getFullYear()
      return startYear >= currentYear ? startYear : startYear + ' - ' + currentYear
    }
  }
}
