import axios from 'axios'
import { ServiceError } from '~/store/'

export const uploadFile = async (url, file) => {
  const fileRes = await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': file.type,
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
      }
    })

  if (fileRes.status !== 200) { // display error if exist
    throw new ServiceError(fileRes.status, `failed to upload file to [${url}] `)
  }
  return true
}
