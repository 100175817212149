import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
const endpoint = '/v1/defects/'

const initialState = () => ({
  items: []
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, { defectIds }) {
    defectIds = defectIds.filter(id => id > 0)

    if (defectIds.length === 0) {
      return []
    }

    const path = endpoint + '?defect_ids=' + defectIds.join()
    return this.$_api.get({ path })
  },
})
