import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  items: '/v1/reports-intermediate/',
  item: '/v1/report-intermediate/',
  itemDetail: '/v1/report-intermediate/{intermediateReportBuildingId}',
  buildings: '/v1/report-intermediate/buildings/',
  settings: '/v1/report-intermediate/settings/',
})

const initialState = () => ({
  items: [],
  buildings: [],
  buildingMeta: {},
  meta: {},
  params: {},
  itemSheets: [],
  itemSheetVersions: [],
  processes: [],
  settings: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  buildings: state => state.buildings,
  getBuilding: state => id => state.buildings.find(b => b.id === id),
  buildingMeta: state => state.buildingMeta,
  itemSheets: state => state.itemSheets,
  getItemSheet: state => id => state.itemSheets.find(is => is.id === id),
  itemSheetVersions: state => state.itemSheetVersions,
  processes: state => state.processes,
  getProcess: state => id => state.processes.find(is => is.id === id),
  settings: state => state.settings,
  getSetting: state => id => state.settings.find(s => s.id === id),
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  setBuildings (state, val) {
    state.buildings = val
  },
  setBuildingMeta (state, meta) {
    state.buildingMeta = meta
  },
  setItemSheets (state, val) {
    state.itemSheets = val
  },
  setItemSheetVersions (state, val) {
    state.itemSheetVersions = val
  },
  setProcesses (state, val) {
    state.processes = val
  },
  setSettings (state, val) {
    state.settings = val
  },
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, params) {
    return this.$_api.get({ path: endpoint.items, query: params })
  },
  fetchBuildings (_, params) {
    return this.$_api.get({ path: endpoint.buildings, query: params })
  },
  async loadBuildings ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.buildings.length) {
      return state.buildings
    }
    const params = options && options.params
    const buildings = await dispatch('fetchBuildings', params)

    commit('setBuildings', buildings.data)
    commit('setBuildingMeta', buildings.meta)
    return buildings.data
  },
  async loadItemSheets ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.itemSheets.length) {
      return state.itemSheets
    }
    const subOptions = options
      ? { id: options.params.itemSheetId }
      : null
    const itemSheets = await dispatch('ItemSheet/fetchItems', subOptions, { root: true })
    commit('setItemSheets', itemSheets.data)
    return itemSheets.data
  },
  async loadItemSheetVersions ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.itemSheetVersions.length) {
      return state.itemSheetVersions
    }
    const itemSheetVersions = await dispatch(
      'ItemSheetVersion/fetchItems',
      {
        itemSheetId: options.params
      },
      { root: true }
    )
    commit('setItemSheetVersions', itemSheetVersions)
    return itemSheetVersions
  },
  async loadProcesses ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.processes.length) {
      return state.processes
    }
    const processes = []
    await Promise.all(options.params.itemSheetProcessIds.map(async (itemSheetProcessId) => {
      const subOptions = { item_sheet_process_id: itemSheetProcessId }
      const process = await dispatch('SheetDetail/fetchSheetProcesses',
        { itemSheetId: options.params.itemSheetId, itemSheetVersionId: options.params.itemSheetVersionId, options: subOptions },
        { root: true }
      )
      processes.push(process.data[0])
    }))

    commit('setProcesses', processes.sort((a, b) => {
      return a.sequence - b.sequence
    }))

    return processes
  },
  fetchSettings (_, params) {
    return this.$_api.get({ path: endpoint.settings, query: params })
  },
  async loadSettings ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.settings.length) {
      return state.settings
    }
    const settings = await dispatch('fetchSettings', { item_sheet_version_id: options.params })
    commit('setSettings', settings.data)
    return settings.data
  },
  submitItem (_, record) {
    return this.$_api.post({ path: endpoint.item, data: record })
  },
  clearBuildings ({ commit }) {
    commit('setBuildings', [])
    commit('setBuildingMeta', {})
  },
  fetchItem (_, id) {
    const path = endpoint.itemDetail.replace('{intermediateReportBuildingId}', id)
    return this.$_api.get({ path })
  },
})
