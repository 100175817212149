import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions
} from './BaseStore'

const endPoint = '/v1/client-item/'
const endpointList = '/v1/client-items/'

const initialState = () => ({
  items: [],
  meta: {},
  params: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getItems (state) {
    return state.items
  }
})

export const mutations = Object.assign({}, baseMutations, {
  init (state) {
    state.items = []
    state.meta = null
  },
  appendItems (state, items) {
    if (!state.items) {
      state.items = items
      return
    }
    state.items = state.items.concat(items)
  },
  updateItem (state, updateItem) {
    if (!state.items || !updateItem) {
      return
    }

    const index = state.items.findIndex(item => item.id === updateItem.id)
    state.items.splice(index, 1, updateItem)
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  appendItem ({ commit }, clientItem) {
    if (!clientItem) {
      return false
    }

    const addItem = [clientItem]
    commit('appendItems', addItem)
  },
  updateItem ({ commit }, clientItem) {
    if (!clientItem) {
      return false
    }

    commit('updateItem', clientItem)
  },
  fetchItems (_, conditions) {
    return this.$_api.get({ path: endpointList, query: conditions })
  },
  async loadAll ({ commit, dispatch, state }) {
    commit('init')
    const limit = 500
    await dispatch('loadItems', { params: { limit } })
    let watchdog = 10
    let page = 1
    while (state.items.length < state.meta.total_items) {
      watchdog--
      if (watchdog < 0) {
        console.error('infinite loop detected') // eslint-disable-line no-console
        return
      }
      page++
      const { data } = await dispatch('fetchItems', { limit, page })
      commit('appendItems', data)
    }
  },
  postItem (_, { clientItem }) {
    return this.$_api.post({ path: endPoint, data: clientItem })
  },
  putItem (_, { clientItemId, clientItem }) {
    const path = endPoint + String(clientItemId)
    return this.$_api.put({ path, data: clientItem })
  }
})
