
import { mapGetters } from 'vuex'

import CommonContextMenu from 'atoms/common/CommonContextMenu'
import CommonContextMenuItem from 'atoms/common/CommonContextMenuItem'
import CommonConfirmDialog from 'atoms/common/CommonConfirmDialog'
import CommonIcon from '~/components/atoms/common/CommonIcon'

import accountMenu from '~/assets/json/accountMenu'
export default {
  components: {
    CommonContextMenu,
    CommonContextMenuItem,
    CommonConfirmDialog,
    CommonIcon
  },
  data () {
    return {
      accountMenu,
      confirmClearingQueueDialog: false
    }
  },
  computed: {
    ...mapGetters({
      name: 'Auth/getName',
      queueLength: 'InspectionQueue/length',
    }),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    hasQueue () {
      return this.queueLength > 0
    }
  },
  methods: {
    checkQueueBeforeSignOut () {
      if (this.hasQueue && !this.confirmClearingQueueDialog) {
        this.confirmClearingQueueDialog = true
        return
      }
      this.signOut()
    },
    async clearQueueBeforesignOut () {
      await this.$store.dispatch('InspectionQueue/clear')
      this.signOut()
    },
    async signOut () {
      await this.$store.dispatch('Auth/signOut')
      window.location.href = '/auth/'
    }
  }
}
