
const roles = [
  { code: 'admin', label: '全社管理' },
  { code: 'assignee', label: '現場担当者' },
  { code: 'designer', label: '設計担当者' },
  { code: 'qm', label: '品質管理者' },
  { code: 'workmen', label: '職人' },
]
const labels = roles.reduce((acc, role) => {
  acc[role.code] = role.label
  return acc
}, {})
export default {
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      labels,
    }
  },
}

