import {
  getters as baseGetters,
  mutations as baseMutations,
  util,
  actions as baseActions,
} from './BaseStore'

const endpointUsers = '/v1/users/'
const endpointCreateUser = '/v1/user/'
const endpointUpdateUser = '/v1/user/'
const endpointGetUser = '/v1/user/'

const initialState = () => ({
  items: [],
  meta: {},
  params: {},
  item: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getByRole: state => (paramRole) => {
    return state.items.filter((user) => {
      return user.roles.filter(role => role.type === paramRole).length
    })
  },
  getItems (state) {
    return state.items
  },
  getMeta (state) {
    return state.meta
  },
  getItem (state) {
    return state.item
  }
})

export const mutations = Object.assign({}, baseMutations, {
  setMeta (state, meta) {
    state.meta = meta
  },
  setItem (state, item) {
    state.item = item
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, payload) {
    return this.$_api.get({ path: endpointUsers, query: payload })
  },
  fetchItem (_, payload) {
    return this.$_api.get({ path: endpointGetUser + payload.userId })
  },
  createItem (_, payload) {
    const params = util.toUnderscoreCaseObject(payload.userdata)
    return this.$_api.post({ path: endpointCreateUser, data: params })
  },
  updateItem ({ commit }, payload) {
    return this.$_api.put({ path: endpointUpdateUser + payload.userdata.id, data: payload.userdata })
  },
  deleteItem (_, userId) {
    return this.$_api.del({ path: endpointUpdateUser + userId })
  }
})
