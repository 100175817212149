import $_ from 'underscore'

const endpoint = Object.freeze({
  inspections: '/v1/mypage/inspections/',
  buildings: '/v1/mypage/buildings/',
  notifications: '/v1/mypage/notifications/',
  readNotification: '/v1/mypage/notification/{notificationId}/read/',
  readAllNotification: '/v1/mypage/notification/read-all/',
  user: '/v1/mypage/user/',
})

const initialState = () => ({
  inspectionsRes: null,
  wipInspectionsRes: null,
  buildingRes: null,
  notificationRes: null,
  unreadNotificationRes: null,
  defects: [],
})

export const state = () => initialState()

export const getters = {
  getInspections: state => state.inspectionsRes ? state.inspectionsRes.data : [],
  getWipInspections: state => state.wipInspectionsRes ? state.wipInspectionsRes.data : [],
  getDefectById: state => id => state.defects.find(d => Number(d.defectId) === id),
  getInspectionsTotal: state => state.inspectionsRes ? state.inspectionsRes.meta.total_items : 0,
  getBuildings: state => state.buildingRes ? state.buildingRes.data : [],
  getBuildingsTotal: state => state.buildingRes ? state.buildingRes.meta.total : 0,
  getNotifications: state => state.notificationRes ? state.notificationRes.data : [],
  getUnreadNotifications: state => state.unreadNotificationRes ? state.unreadNotificationRes.data : [],
  getCountTotalNotifications: state => $_.get(state.notificationRes, ['meta', 'total'], 0),
  getCountUnreadNotifications: state => $_.get(state.unreadNotificationRes, ['meta', 'total'], 0),
}

export const mutations = {
  setInspections (state, response) {
    state.inspectionsRes = response
  },
  setWipInspections (state, response) {
    state.wipInspectionsRes = response
  },
  setBuildings (state, response) {
    state.buildingRes = response
  },
  setNotifications (state, response) {
    state.notificationRes = response
  },
  appendNotifications (state, response) {
    state.notificationRes.data.push(...response.data)
  },
  setUnreadNotifications (state, response) {
    state.unreadNotificationRes = response
  },
  appendUnreadNotifications (state, response) {
    state.unreadNotificationRes.data.push(...response.data)
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  setDefects (state, defects) {
    state.defects = defects
  },
}

export const actions = {
  fetchInspections (_, conditions) {
    return fetchInspections(this, conditions)
  },
  async loadInspections ({ commit, dispatch }) {
    const conditions = {
      is_done: '0',
      limit: 15,
    }
    const res = await fetchInspections(this, conditions)
    commit('setInspections', res)
    dispatch('_loadDefects')
  },
  async loadWipInspections ({ commit }) {
    const conditions = {
      status: 'wip',
    }
    const res = await fetchInspections(this, conditions)
    commit('setWipInspections', res)
  },
  async _loadDefects ({ state, commit, dispatch }) {
    const defectIds = [...new Set(
      state.inspectionsRes.data
        .filter(i => i.building.gkpro_defect_id)
        .map(i => i.building.gkpro_defect_id)
    )]
    if (!defectIds.length) {
      commit('setDefects', [])
      return []
    }
    const defects = await dispatch('Defect/fetchItems', { defectIds }, { root: true })
    commit('setDefects', defects)
    return defects
  },
  async loadBuildings ({ commit }, conditions) {
    const res = await fetchBuildings(this, conditions)
    commit('setBuildings', res)
  },
  async loadNotifications ({ commit }, { conditions, append }) {
    const res = await fetchNotifications(this, conditions)
    if (append) {
      commit('appendNotifications', res)
    } else {
      commit('setNotifications', res)
    }
  },
  async loadUnreadNotifications ({ commit }, { conditions, append }) {
    conditions.unread = 1
    const res = await fetchNotifications(this, conditions)
    if (append) {
      commit('appendUnreadNotifications', res)
    } else {
      commit('setUnreadNotifications', res)
    }
  },
  async updateNotificationRead ({ dispatch }, { notificationId, reloadConditions }) {
    await readNotification(this, notificationId)
    await dispatch('loadNotifications', { conditions: reloadConditions, append: false })
    await dispatch('loadUnreadNotifications', { conditions: reloadConditions, append: false })
  },
  async updateNotificationReadAll ({ commit, dispatch }, { reloadConditions }) {
    await readAllNotification(this)
    commit('setUnreadNotifications', null)
    await dispatch('loadNotifications', { conditions: reloadConditions, append: false })
  },
  async updateMyInformation (_, { entity }) {
    return await this.$_api.put({
      path: endpoint.user,
      data: entity,
    })
  }
}

const fetchInspections = (self, conditions) => {
  return self.$_api.get({
    path: endpoint.inspections,
    query: conditions
  })
}

const fetchBuildings = async (self, conditions) => {
  return await self.$_api.get({ path: endpoint.buildings, query: conditions })
}
const fetchNotifications = async (self, conditions) => {
  return await self.$_api.get({ path: endpoint.notifications, query: conditions })
}
const readNotification = async (self, notificationId) => {
  const path = endpoint.readNotification.replace('{notificationId}', notificationId)
  return await self.$_api.post({ path })
}
const readAllNotification = async (self) => {
  return await self.$_api.post({ path: endpoint.readAllNotification })
}
