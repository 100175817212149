import {
  CarbonIconsVue,
  Calendar24, Construction16, CloseOutline32, Redo32, CalendarHeatMap32, Book32, ArrowLeft16, ArrowLeft24, SearchLocate32, Building16, Report16,
  Home16, ChevronLeft32, ChevronRight32, Download16, ZoomOut16, ZoomIn16, ListChecked16, Launch16, FaceDizzy32,
  FaceWink32, Flag16, Image16, Image32, Upload16, Document16, Document24, Calendar16, Checkmark16, View16, ArrowRight16, ArrowRight24, ArrowDown16,
  CaretDown16, Search16, CalendarHeatMap16, WarningAlt16, Home24, Menu16, Menu24, Analytics24, User24, User32, User16,
  ChevronDown16, ChevronRight16, ChevronRight24, ChevronLeft16, ChevronLeft24, Close24, Close16, Add16, Subtract16, Notification24, Building24, Task24, Folder24,
  Settings24, Settings16,
  ListChecked24, WatsonHealthRotate_18024 as Rotate18024, WatsonHealthRotate_18032 as Rotate18032,
  FlashOff32, Location16, Undo32,
  Undo16, Checkmark32, CheckmarkOutline16, Book16, WarningAlt24, WarningAlt32, Information32,
  CheckmarkOutline32, CheckmarkOutline24, MisuseOutline32, Edit16, Camera16, OverflowMenuVertical24, OverflowMenuVertical16,
  Flag32, Error16, Error24, TrashCan16, Group16, GroupAccess16, AddComment16, Attachment16, FaceDissatisfied16, FaceDissatisfied32, FaceSatisfied32,
  EmailNew32, Bullhorn24,
  Logout16, Logout24,
  Printer16, Report32, Login16, MeterAlt24, Copy16, CopyFile16,
  Locked16, RecentlyViewed16, EarthSoutheastAsiaFilled24, ListBoxes16, Apps16, Help16,
  Renew16, Tree24, Tools24, CropGrowth16, ArrowUp16, ThisSideUp16,
  Email16, Phone16, Table16, Grid16
} from '@carbon/icons-vue'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const icons = {
    CarbonIconsVue,
    Add16,
    AddComment16,
    Analytics24,
    ArrowDown16,
    ArrowLeft16,
    ArrowLeft24,
    ArrowRight16,
    ArrowRight24,
    ArrowUp16,
    Attachment16,
    Book16,
    Book32,
    Building16,
    Building24,
    Bullhorn24,
    Calendar16,
    Calendar24,
    CalendarHeatMap16,
    CalendarHeatMap32,
    Camera16,
    CaretDown16,
    Checkmark16,
    Construction16,
    Checkmark32,
    CheckmarkOutline16,
    CheckmarkOutline24,
    CheckmarkOutline32,
    ChevronDown16,
    ChevronLeft32,
    ChevronRight16,
    ChevronRight24,
    ChevronLeft16,
    ChevronLeft24,
    ChevronRight32,
    Close16,
    Close24,
    CloseOutline32,
    Document16,
    Document24,
    Download16,
    EarthSoutheastAsiaFilled24,
    Edit16,
    EmailNew32,
    Error16,
    Error24,
    FaceDissatisfied16,
    FaceDissatisfied32,
    FaceDizzy32,
    FaceSatisfied32,
    FaceWink32,
    Flag16,
    Flag32,
    FlashOff32,
    Folder24,
    Group16,
    GroupAccess16,
    Home16,
    Home24,
    Image16,
    Image32,
    Information32,
    Launch16,
    ListChecked16,
    ListChecked24,
    Location16,
    Login16,
    Copy16,
    CopyFile16,
    Logout16,
    Logout24,
    Menu16,
    Menu24,
    MeterAlt24,
    MisuseOutline32,
    Notification24,
    OverflowMenuVertical16,
    OverflowMenuVertical24,
    Printer16,
    Redo32,
    Report16,
    Report32,
    Rotate18024,
    Rotate18032,
    Search16,
    SearchLocate32,
    Settings16,
    Settings24,
    Subtract16,
    Task24,
    TrashCan16,
    Undo32,
    Undo16,
    Upload16,
    User16,
    User24,
    User32,
    View16,
    WarningAlt16,
    WarningAlt24,
    WarningAlt32,
    ZoomIn16,
    ZoomOut16,
    Locked16,
    RecentlyViewed16,
    ListBoxes16,
    Apps16,
    Help16,
    Renew16,
    Tree24,
    Tools24,
    CropGrowth16,
    ThisSideUp16,
    Email16,
    Phone16,
    Table16,
    Grid16
  }
  Object.entries(icons).forEach(([name, component]) => {
    nuxtApp.vueApp.component(name, component)
  })
})
