const strict = Object.freeze({
  width: 1920,
  height: 1440,
  aspectRatio: { exact: 4 / 3 },
  facingMode: {
    exact: 'environment'
  }
})
const loose = Object.freeze({
  width: { ideal: 1920 },
  height: { ideal: 1440 },
  aspectRatio: { ideal: 4 / 3 },
  facingMode: {
    ideal: 'environment'
  }
})
const config = Object.freeze({
  width: 1440,
  height: 1080,
  filename: 'qualiz-web-camera',
  aspect: 4 / 3
})
const _default = Object.freeze({
  strict, loose, config
})

export default _default
