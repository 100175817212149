import { defineNuxtPlugin } from '#app'

export const CONST = {
  ORDER_TYPE: {
    NS: 'ns',
    SELF: 'self'
  },
  CHECK_TYPE: {
    AUDIT: 'audit',
    MANAGEMENT: 'management',
  },
  ASSIGNEE_ROLE: {
    ADMIN: {
      V: 'admin',
      LABEL: '全社管理',
    },
    ASSIGNEE: {
      V: 'assignee',
      LABEL: '現場担当者',
    },
    DESIGNER: {
      V: 'designer',
      LABEL: '設計担当者',
    },
    QM: {
      V: 'qm',
      LABEL: '品質管理者',
    },
    WORKMEN: {
      V: 'workmen',
      LABEL: '職人',
    },
  },
  INSPECTION_STATUS: {
    NS: {
      PLANNED: {
        V: 'planned',
        LABEL: '要予約',
        COLOR: 'func-yellow3',
        ICON: 'WarningAlt16',
      },
      BOOKED: {
        V: 'booked',
        LABEL: '予約済',
        COLOR: 'func-purple3',
        ICON: 'CalendarHeatMap16',
      },
      SCHEDULED: {
        V: 'scheduled',
        LABEL: '予約確定',
        COLOR: 'func-blue3',
        ICON: 'CalendarHeatMap16',
      },
      WIP: {
        V: 'wip',
        LABEL: '実施中',
        COLOR: 'func-rose3',
        ICON: 'Construction16',
      },
      UNCHECKED: {
        V: 'unchecked',
        LABEL: '確認待ち',
        COLOR: 'func-yellow3',
        ICON: 'WarningAlt16',
      },
      CHECKING: {
        V: 'checking',
        LABEL: '確認中',
        COLOR: 'func-marine3',
        ICON: 'View16',
      },
      DONE: {
        V: 'done',
        LABEL: '実施済',
        COLOR: 'func-green3',
        ICON: 'Checkmark16',
      },
    },
    SELF: {
      SCHEDULED: {
        V: 'scheduled',
        LABEL: '予定済',
        COLOR: 'func-blue3',
        ICON: 'CalendarHeatMap16',
      },
      WIP: {
        V: 'wip',
        LABEL: '実施中',
        COLOR: 'func-rose3',
        ICON: 'Construction16',
      },
      DONE: {
        V: 'done',
        LABEL: '実施済',
        COLOR: 'func-green3',
        ICON: 'Checkmark16',
      },
    },
    MANAGEMENT: {
      SCHEDULED: {
        V: 'scheduled',
        LABEL: '予定済',
        COLOR: 'func-blue3',
        ICON: 'CalendarHeatMap16',
      },
      WIP: {
        V: 'wip',
        LABEL: '実施中',
        COLOR: 'func-rose3',
        ICON: 'Construction16',
      },
      DONE: {
        V: 'done',
        LABEL: '完了',
        COLOR: 'func-green3',
        ICON: 'Checkmark16',
      },
    },
  },
  PROCESS_STATUS: {
    NOTHING: {
      V: 'nothing',
      LABEL: '未計画',
      COLOR: 'func-yellow3',
      ICON: 'WarningAlt16',
    },
    PLANNED: {
      V: 'planned',
      LABEL: '予定済',
      COLOR: 'func-blue3',
      ICON: 'CalendarHeatMap16',
    },
    UNBOOKED: {
      V: 'unbooked',
      LABEL: '要予約',
      COLOR: 'func-yellow3',
      ICON: 'WarningAlt16',
    },
    BOOKED: {
      V: 'booked',
      LABEL: '予約済',
      COLOR: 'func-purple3',
      ICON: 'CalendarHeatMap16',
    },
    SCHEDULED: {
      V: 'scheduled',
      LABEL: '予約確定',
      COLOR: 'func-blue3',
      ICON: 'CalendarHeatMap16',
    },
    WIP: {
      V: 'wip',
      LABEL: '実施中',
      COLOR: 'func-rose3',
      ICON: 'Construction16',
    },
    DONE: {
      V: 'done',
      LABEL: '実施済',
      COLOR: 'func-green3',
      ICON: 'Checkmark16',
    },
    NG: {
      V: 'ng',
      LABEL: '指摘あり',
      COLOR: 'func-red3',
      ICON: 'Error16',
    },
  },
  CHART_LABELS: {
    BY_ITEM_TYPES: [
      {
        ID: 1,
        RADAR_CHART_LABEL: '法令',
        COLUMN_CHART_LABEL: '法令基準\n(建築基準法)',
      },
      {
        ID: 2,
        RADAR_CHART_LABEL: '瑕疵',
        COLUMN_CHART_LABEL: '法令基準\n(瑕疵担保履行法)',
      },
      {
        ID: 3,
        RADAR_CHART_LABEL: '仕様書',
        COLUMN_CHART_LABEL: '仕様書\n(フラット35,共有仕様書)'
      },
      {
        ID: 4,
        RADAR_CHART_LABEL: '自社',
        COLUMN_CHART_LABEL: '自社基準',
      },
      {
        ID: 5,
        RADAR_CHART_LABEL: 'メーカー',
        COLUMN_CHART_LABEL: 'メーカー基準',
      },
      {
        ID: 7,
        RADAR_CHART_LABEL: 'その他',
        COLUMN_CHART_LABEL: 'その他\n(現状確認)',
      },
    ],
    BY_ITEM_PERFORMANCES: [
      {
        ID: 1,
        RADAR_CHART_LABEL: '耐震',
        COLUMN_CHART_LABEL: '耐震性能',
      },
      {
        ID: 2,
        RADAR_CHART_LABEL: '防火',
        COLUMN_CHART_LABEL: '防火性能',
      },
      {
        ID: 3,
        RADAR_CHART_LABEL: '耐久',
        COLUMN_CHART_LABEL: '耐久性能',
      },
      {
        ID: 4,
        RADAR_CHART_LABEL: '維持',
        COLUMN_CHART_LABEL: '維持管理性能',
      },
      {
        ID: 5,
        RADAR_CHART_LABEL: ['断熱･', '省エネ'],
        COLUMN_CHART_LABEL: '断熱･省エネ\n性能',
      },
      {
        ID: 6,
        RADAR_CHART_LABEL: '空気',
        COLUMN_CHART_LABEL: '空気環境',
      },
      {
        ID: 7,
        RADAR_CHART_LABEL: '快適',
        COLUMN_CHART_LABEL: '快適性能\n(採光等)',
      },
      {
        ID: 8,
        RADAR_CHART_LABEL: '防音',
        COLUMN_CHART_LABEL: '防音性能',
      },
      {
        ID: 9,
        RADAR_CHART_LABEL: ['バリア', 'フリー'],
        COLUMN_CHART_LABEL: 'バリアフリー\n性能',
      },
      {
        ID: 10,
        RADAR_CHART_LABEL: '防犯',
        COLUMN_CHART_LABEL: '防犯性能',
      },
    ],
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('CONST', CONST)
})
