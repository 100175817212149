
import CommonIcon from 'atoms/common/CommonIcon'
import CommonPrimaryButton from 'atoms/common/CommonPrimaryButton'
import CommonButton from 'atoms/common/CommonButton'
export default {
  components: {
    CommonIcon,
    CommonPrimaryButton,
    CommonButton
  },
  props: {
    title: { type: String, default: '' },
    arrowLink: { type: String, default: null },
    breadcrumbs: { type: Array, default: () => [] },
    buttons: { type: Array, default: null }
  },
}
