// vuetify/lib/util/colors を参考に色を設定
// 色を追加する場合は "custom〜" で記述する

// Brand Color
const orange = Object.freeze({
  custom1: '#FFF4EE',
  custom2: '#FDA271',
  custom3: '#EA5504',
  custom4: '#F57C01'
})

// Base Color
const grey = Object.freeze({
  custom1: '#F6F6F6',
  custom2: '#D8D8D8',
  custom3: '#BFBFBF',
  custom4: '#A5A5A5',
  custom5: '#8C8C8C',
  custom6: '#727272',
  custom7: '#595959',
  custom8: '#3F3F3F',
  custom9: '#262626',
  custom10: '#B7B7B7'
})

const shades = Object.freeze({
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent'
})

// Functional Color
const red = Object.freeze({
  custom1: '#FDEDED',
  custom2: '#F38787',
  custom3: '#E92121',
  custom4: '#AC1D1D',
  custom5: '#F54337',
  custom6: '#FFCDD2'
})
const yellow = Object.freeze({
  custom1: '#FBF3DD',
  custom2: '#FFDB7D',
  custom3: '#FFBB0D',
  custom4: '#B77A1F'
})
const green = Object.freeze({
  custom1: '#E9F9EE',
  custom2: '#80D398',
  custom3: '#0F9033',
  custom4: '#226634',
  custom5: '#3CCC51',
  custom6: '#AED581',
  custom7: '#C4E1A4',
  custom8: '#4DB5AB'
})
const marine = Object.freeze({
  custom1: '#E2F6F5',
  custom2: '#86D7D2',
  custom3: '#098E86',
  custom4: '#1F6B67'
})
const turquoise = Object.freeze({
  custom1: '#DDF7FF',
  custom2: '#76D2EF',
  custom3: '#359ABA',
  custom4: '#326D80'
})
const blue = Object.freeze({
  custom1: '#F0F4FE',
  custom2: '#849AE4',
  custom3: '#2D50CB',
  custom4: '#324999',
  custom5: '#1B46EE',
  custom6: '#64B5F7'
})
const purple = Object.freeze({
  custom1: '#EDDFFF',
  custom2: '#C091FB',
  custom3: '#792ED8',
  custom4: '#56219A'
})
const rose = Object.freeze({
  custom1: '#FEEDFA',
  custom2: '#ED84D4',
  custom3: '#CE25A9',
  custom4: '#A52689'
})

const _default = Object.freeze({
  orange,
  grey,
  shades,
  red,
  yellow,
  green,
  marine,
  turquoise,
  blue,
  purple,
  rose
})

export default _default
