import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  detail: '/v1/mypage/news/{newsId}/',
  read: '/v1/mypage/news/{newsId}/read/',
})

const initialState = () => ({
  item: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItem (_, params) {
    const detailPath = endpoint.detail
      .replace('{newsId}', params.newsId)
    return await this.$_api.get({ path: detailPath })
  },

  async read (_, id) {
    const path = endpoint.read.replace('{newsId}', id)
    return await this.$_api.post({ path })
  }
})
