
import { mapGetters } from 'vuex'
import AppLinkDialog from 'organisms/AppLinkDialog'

export default {
  components: {
    AppLinkDialog,
  },
  data () {
    return {
      loading: false,
      appLinkDialog: false,
    }
  },
  fetch () {
    this.loadWipInspections()
  },
  computed: {
    ...mapGetters({
      getWipInspections: 'Mypage/getWipInspections',
      isNs: 'Auth/isNs',
    }),
    latestWipInspection () {
      const inspections = this.getWipInspections
        .slice()
        .filter(inspection => inspection.status === 'wip')
        .sort((prev, current) => (prev.updated_at > current.updated_at) ? -1 : 1)

      if (!inspections.length) {
        return null
      }

      return inspections[0]
    },
    shouldShow () {
      if (this.$route.path === '/inspection/force/') {
        return false
      }

      return !!this.latestWipInspection
    },
    taskName () {
      const checkType = this.latestWipInspection?.item_sheet_inspection.check_type

      if (checkType === this.$CONST.CHECK_TYPE.AUDIT) {
        return '監査'
      }

      if (checkType === this.$CONST.CHECK_TYPE.MANAGEMENT) {
        return '管理'
      }

      return ''
    },
    message () {
      return `実施中の${this.taskName}があります。`
    },
    linkButtonMessage () {
      return `${this.taskName}を再開`
    },
  },
  methods: {
    async toInspectionForce () {
      await this.$store.dispatch('Auth/setCurrentClient', this.latestWipInspection.client)
      this.appLinkDialog = true
    },
    loadWipInspections () {
      this.$store.dispatch('Mypage/loadWipInspections')
    },
  },
}
