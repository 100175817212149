import { captureException } from '@sentry/browser'
import { _ as $_ } from 'vue-underscore'
import axios from 'axios'
import {
  getters as baseGetters,
  mutations as baseMutations,
} from './BaseStore'
import { UserError, ServiceError } from '.'

const endpoint = Object.freeze({
  basic: '/v1/building/',
  summary: '/v1/building/{buildingId}/summary',
  construction: '/v1/building/{buildingId}/construction',
  constructionStatus: '/v1/building/{buildingId}/construction/status',
  constructionItemSheetProcesses: '/v1/building/{buildingId}/construction/item-sheet-processes',
  constructionProcessesStatus: '/v1/building/{buildingId}/construction/processes/status',
  progresses: '/v1/building/{buildingId}/progresses',
  processes: '/v1/building/{buildingId}/processes',
  detail: '/v1/building/{buildingId}/detail',
  schedule: '/v1/building/{buildingId}/schedule',
  process: '/v1/building/{buildingId}/process/',
  assignees: '/v1/building/{buildingId}/building-assignees',
  assignee: '/v1/building/{buildingId}/building-assignee/',
  assigneeMain: '/v1/building/{buildingId}/building-assignee/{buildingAssigneeId}/main',
  complete: '/v1/building/{buildingId}/complete-construction/',
  post: '/v1/building/{buildingId}/post/',
  posts: '/v1/building/{buildingId}/posts/',
  postDetail: '/v1/building/{buildingId}/post/{postId}',
  postAttachment: '/v1/building/{buildingId}/post/{postId}/attachment/',
  postAttachmentDetail: '/v1/building/{buildingId}/post/{postId}/attachment/{attachmentId}',
  comment: '/v1/building/{buildingId}/post/{postId}/comment/',
  comments: '/v1/building/{buildingId}/post/{postId}/comments/',
  commentDetail: '/v1/building/{buildingId}/post/{postId}/comment/{commentId}',
  commentAttachment: '/v1/building/{buildingId}/post/{postId}/comment/{commentId}/attachment/',
  commentAttachmentDetail: '/v1/building/{buildingId}/post/{postId}/comment/{commentId}/attachment/{attachmentId}',
  defects: '/v1/defects/',
})

const initialState = () => ({
  id: null,
  summary: null,
  construction: null,
  constructionStatus: {},
  basic: null,
  progresses: [],
  processes: null,
  detail: null,
  assignees: [],
  posts: {},
  post: {},
  inspections: [],
  processHistories: [],
  defect: null,
  intermediateReportable: null,
  constructionStatusLoading: true,
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getBasic: state => state.basic,
  getSummary: state => state.summary,
  getConstruction: state => state.construction,
  getConstructionStatus: state => state.constructionStatus,
  getConstructionStatusLoading: state => state.constructionStatusLoading,
  getDefectId: state => state.basic.gkpro_defect_id,
  getProgresses: state => state.progresses,
  getProcesses: state => state.processes,
  getDetail: state => state.detail,
  getAssignees: state => state.assignees,
  getPost: state => state.post,
  getPosts: state => state.posts,
  getPostById: state => id => state.posts.data.find(post => post.id === id),
  getInspections: state => state.inspections,
  getInspection: state => (itemSheetInspectionId) => {
    return $_.findWhere(state.inspections, { item_sheet_inspection_id: itemSheetInspectionId })
  },
  getbuildingDetailUnfinishedInspections: state => state.inspections.filter(i => i.status !== 'done' && i.status !== 'returned'),
  getProcessHistories: state => state.processHistories,
  getDefect: state => state.defect,
  getIntermediateReportable: state => state.intermediateReportable
})

export const mutations = Object.assign({}, baseMutations, {
  reset (state, id) {
    Object.assign(state, initialState())
    state.id = id
  },
  setId (state, id) {
    state.id = id
  },
  setSummary (state, summary) {
    state.summary = summary
  },
  setSummaryAssignees (state, assignees) {
    state.summary.assignees = assignees
  },
  setConstruction (state, construction) {
    state.construction = construction
  },
  setConstructionStatus (state, construction) {
    state.constructionStatus = construction
  },
  setConstructionStatusLoading (state, constructionLoading) {
    state.constructionStatusLoading = constructionLoading
  },
  setAssignees (state, assignees) {
    state.assignees = assignees
  },
  setBasic (state, basic) {
    state.basic = basic
  },
  setProgresses (state, progresses) {
    state.progresses = progresses
  },
  setProcesses (state, processes) {
    state.processes = processes
  },
  setDetail (state, detail) {
    state.detail = detail
  },
  setPosts (state, posts) {
    state.posts = posts
  },
  setPost (state, post) {
    state.post = post
  },
  appendComments (state, { postId, comments }) {
    if (state.post.id === postId) {
      state.post.comments.push(...comments)
    } else {
      const post = state.posts.data.find(post => post.id === postId)
      post.comments.push(...comments)
    }
  },
  resetComments (state, { postId, comments }) {
    if (state.post.id === postId) {
      state.post.comments = comments
    } else {
      const post = state.posts.data.find(post => post.id === postId)
      post.comments = comments
    }
  },
  incrementOrDecrementCommentsCount (state, { postId, increase }) {
    if (state.post.id === postId) {
      state.post.comments_count += increase ? 1 : -1
    } else {
      const post = state.posts.data.find(post => post.id === postId)
      post.comments_count += increase ? 1 : -1
    }
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  setInspections (state, inspections) {
    state.inspections = inspections
  },
  setProcessHistories (state, histories) {
    state.processHistories = histories
  },
  setDefect (state, defect) {
    state.defect = defect
  },
  setIntermediateReportable (state, flag) {
    state.intermediateReportable = flag
  },
})

export const actions = {
  // basic
  async fetchBasic (_, buildingId) {
    const path = endpoint.basic + buildingId
    return await this.$_api.get({ path })
  },
  async loadBasic ({ state, dispatch, commit }, { params, force }) {
    if (params.buildingId !== state.id) {
      commit('setBasic', null)
    } else if (state.basic && !force) {
      return state.basic
    }

    const res = await dispatch('fetchBasic', params.buildingId)
    commit('setBasic', res)
    return res
  },
  async updateBasic (_, { buildingId, data }) {
    const path = endpoint.basic + buildingId
    return await this.$_api.put({ path, data })
  },

  // summary
  async fetchSummary (_, buildingId) {
    const path = endpoint.summary.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadSummary ({ state, dispatch, commit }, { params, force }) {
    const initials = initialState()
    if (params.buildingId !== state.id) {
      commit('setId', initials.id)
      commit('setSummary', initials.summary)
      commit('setDetail', initials.detail)
    } else if (state.summary && !force) {
      return state.summary
    }

    const res = await dispatch('fetchSummary', params.buildingId)
    commit('setId', res.id)
    commit('setSummary', res)
    commit('setDetail', res.building_detail ?? initials.detail)
    return res
  },
  async reloadSummary ({ state, dispatch, commit }) {
    if (!state.summary) {
      throw new UserError()
    }
    const res = await dispatch('fetchSummary', state.id)
    const initials = initialState()
    commit('setId', res.id)
    commit('setSummary', res)
    commit('setDetail', res.building_detail ?? initials.detail)
    return res
  },

  // construction
  async fetchConstruction (_, buildingId) {
    const path = endpoint.construction.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadConstruction ({ state, dispatch, commit }, { params, force }) {
    if (params.buildingId !== state.summary?.id) {
      commit('setConstruction', null)
    } else if (!force && params.buildingId === state.construction?.building_id) {
      return state.construction
    }

    const res = await dispatch('fetchConstruction', params.buildingId)
    commit('setConstruction', res)
    return res
  },
  // constructionStatus
  async fetchConstructionStatus (_, buildingId) {
    const path = endpoint.constructionStatus.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadConstructionStatus ({ state, dispatch, commit }, { params, force }) {
    if (params.buildingId !== state.summary?.id) {
      commit('setConstructionStatus', null)
    } else if (!force && params.buildingId === state.constructionStatus?.building_id) {
      return state.constructionStatus
    }

    commit('setConstructionStatusLoading', true)
    const res = await dispatch('fetchConstructionStatus', params.buildingId)
    commit('setConstructionStatus', res)
    commit('setConstructionStatusLoading', false)
    return res
  },

  async fetchConstructionItemSheetProcesses ({ state, dispatch, commit }, params) {
    const { buildingId } = params
    const path = endpoint.constructionItemSheetProcesses.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },

  async fetchConstructionProcessesStatus ({ state, dispatch, commit }, params) {
    const { buildingId } = params
    const path = endpoint.constructionProcessesStatus.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },

  // processes
  async fetchProcesses (_, { buildingId }) {
    const path = endpoint.processes.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadProcesses ({ state, dispatch, commit }, { params, force }) {
    if (state.processes && !force) {
      return state.processes
    }
    const processes = await dispatch('fetchProcesses', params)
    commit('setProcesses', processes)
    return processes
  },

  // progresses
  async fetchProgresses (_, { buildingId }) {
    const path = endpoint.progresses.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadProgresses ({ state, dispatch, commit }, { params, force }) {
    if (params.buildingId !== state.id) {
      commit('setProgresses', null)
    } else if (state.detail && !force) {
      return state.detail
    }

    const res = await dispatch('fetchProgresses', { buildingId: params.buildingId })
    commit('setProgresses', res)
    return res
  },

  // detail
  async fetchDetail (_, { buildingId }) {
    const path = endpoint.detail.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  async loadDetail ({ state, dispatch, commit }, { params, force }) {
    if (params.buildingId !== state.id) {
      commit('setDetail', null)
    } else if (state.detail && !force) {
      return state.detail
    }

    const res = await dispatch('fetchDetail', { buildingId: params.buildingId })
    commit('setDetail', res)
    return res
  },
  async updateDetail ({ state }, { buildingId, data }) {
    const path = endpoint.detail.replace('{buildingId}', buildingId)
    return await this.$_api.put({ path, data })
  },
  updateInspectionPlan ({ state }, { buildingId, entity }) {
    if (!entity) {
      entity = {
        construction_start: state.detail.construction_start,
        construction_raising: state.detail.construction_raising,
        construction_fixtures: state.detail.construction_fixtures,
        construction_completion: state.detail.construction_completion,
      }
    }
    const path = endpoint.schedule.replace('{buildingId}', buildingId)
    return this.$_api.post({ path, data: entity })
  },
  putProcess (_, { buildingId, entity }) {
    const path = endpoint.process.replace('{buildingId}', buildingId)
    return this.$_api.put({ path, data: entity })
  },
  putProcesses (_, { buildingId, entities }) {
    const path = endpoint.processes.replace('{buildingId}', buildingId)
    return this.$_api.put({ path, data: entities })
  },

  async fetchAssignees (_, { buildingId, conditions }) {
    const path = endpoint.assignees.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path, query: conditions })
  },
  async loadAssignees ({ state, dispatch, commit }, { params, force }) {
    if (state.id && params.buildingId !== state.id) {
      commit('setAssignees', [])
    } else if (state.assignees && state.assignees.length && !force) {
      return state.assignees
    }

    const assignees = await dispatch('fetchAssignees', { buildingId: params.buildingId, conditions: { name: params.name } })
    commit('setAssignees', assignees)
    return assignees
  },
  reloadAssignees ({ dispatch }, buildingId, consditions) {
    return dispatch('loadAssignees', {
      params: { buildingId, consditions },
      force: true
    })
  },
  async swapMainAssignee ({ dispatch, commit }, { buildingId, buildingAssigneeId }) {
    const path = endpoint.assigneeMain
      .replace('{buildingId}', buildingId)
      .replace('{buildingAssigneeId}', buildingAssigneeId)
    await this.$_api.put({ path })
    const assignees = await dispatch('fetchAssignees', { buildingId })
    commit('setSummaryAssignees', assignees)
    return assignees
  },
  async loadItem ({ state, commit, dispatch }, { params, force }) {
    if (force || (state.id && state.id !== params.buildingId)) {
      commit('reset', params.buildingId)
    }
    await dispatch('loadBasic', { params, force })
    return await Promise.all([
      dispatch('loadProgresses', { params, force }),
      dispatch('loadDetail', { params, force }),
      dispatch('loadAssignees', { params, force }),
      dispatch('_loadInspections', { params, force }),
      dispatch('loadProcesses', { params, force }),
      dispatch('_loadDefect', { force }),
      dispatch('_loadIntermediateReportable', { params, force }),
    ])
  },

  removeAssignee (_, { buildingId, assigneeId }) {
    const path = endpoint.assignee
      .replace('{buildingId}', buildingId) + assigneeId
    return this.$_api.del({ path })
  },
  async submitAssignee (_, { buildingId, params }) {
    const path = endpoint.assignee
      .replace('{buildingId}', buildingId)
    const data = {
      user_id: params.userId,
      role: params.role
    }
    await this.$_api.post({ path, data })
  },
  putDetailAttr ({ state, commit }, { attr, value }) {
    const update = {}
    update[attr] = value
    const detail = Object.assign({}, state.detail, update)
    commit('setDetail', detail)
  },

  async completeWorking ({ state }) {
    const path = endpoint.complete.replace('{buildingId}', state.id)
    return await this.$_api.post({ path })
  },
  // 申し送り（storeを分けた方が良い？）
  fetchPost (_, { buildingId, postId }) {
    const path = endpoint.postDetail.replace('{buildingId}', buildingId).replace('{postId}', postId)
    return this.$_api.get({ path })
  },
  async loadPost ({ dispatch, commit }, { buildingId, postId }) {
    const post = await dispatch('fetchPost', { buildingId, postId })
    commit('setPost', post)
    return post
  },
  async fetchPosts (_, { buildingId, conditions }) {
    const path = endpoint.posts.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path, query: conditions })
  },
  async loadPosts ({ dispatch, commit }, { buildingId, conditions }) {
    const posts = await dispatch('fetchPosts', { buildingId, conditions })
    commit('setPosts', posts)
    return posts
  },
  fetchComments ({ state }, { buildingId, postId, offset, limit }) {
    const path = endpoint.comments.replace('{buildingId}', buildingId).replace('{postId}', postId)
    const conditions = { offset, limit }
    return this.$_api.get({ path, query: conditions })
  },
  async loadMoreComments ({ dispatch, commit }, { buildingId, postId, offset }) {
    const comments = await dispatch('fetchComments', { buildingId, postId, offset, limit: 3 })
    commit('appendComments', { postId, comments })
    return comments
  },
  async reloadComments ({ state, dispatch, commit }, { buildingId, postId, increase = false }) {
    let limit = 0
    if (state.post.id === postId) {
      limit = state.post.comments.length + (increase ? 1 : 0)
    } else {
      const post = state.posts.data.find(post => post.id === postId)
      limit = post.postable_type === 'inspection' ? 3 : post.comments.length + (increase ? 1 : 0)
    }
    const comments = await dispatch('fetchComments', { buildingId, postId, offset: 0, limit })
    commit('resetComments', { postId, comments })
    return comments
  },
  async postBuildingPost ({ state, dispatch, commit }, { buildingId, entity, files, eventHandler }) {
    // 本文の投稿
    const path = endpoint.post.replace('{buildingId}', buildingId)
    const post = await this.$_api.post({ path, data: entity })
    // 添付ファイルの登録
    await files.reduce(async (acc, file) => {
      await acc
      const record = { filename: file.name, mimetype: file.type }
      const attachmentPath = endpoint.postAttachment.replace('{buildingId}', buildingId).replace('{postId}', post.id)
      const res = await this.$_api.post({ path: attachmentPath, data: record })

      return await dispatch('NotificationConnection/subscribe', {
        channel: '/files/' + res.id,
        callback: (msg) => {
          if (eventHandler && typeof eventHandler === 'function') {
            eventHandler(msg)
          }
        }
      }, { root: true })
        .catch((e) => {
          captureException(e)
        })
        .then(() => {
          const putUrl = res.file_put_presigned_url
          return uploadFile(putUrl, file)
        })
        .then(() => {
          return res
        })
        .catch((e) => {
          captureException(e)
          throw e
        })
    }, {})

    return post
  },
  async updateBuildingPost ({ dispatch }, { buildingId, postId, entity, files, removeAttachmentIds, eventHandler }) {
    // コメント本文の編集
    const path = endpoint.postDetail.replace('{buildingId}', buildingId).replace('{postId}', postId)
    await this.$_api.put({ path, data: entity })
    // 添付ファイルの登録
    await files.reduce(async (acc, file) => {
      await acc
      const record = { filename: file.name, mimetype: file.type }
      const attachmentPath = endpoint.postAttachment.replace('{buildingId}', buildingId).replace('{postId}', postId)
      const res = await this.$_api.post({ path: attachmentPath, data: record })

      return await dispatch('NotificationConnection/subscribe', {
        channel: '/files/' + res.id,
        callback: (msg) => {
          if (eventHandler && typeof eventHandler === 'function') {
            eventHandler(msg)
          }
        }
      }, { root: true })
        .catch((e) => {
          captureException(e)
        })
        .then(() => {
          const putUrl = res.file_put_presigned_url
          return uploadFile(putUrl, file)
        })
        .then(() => {
          return res
        })
        .catch((e) => {
          captureException(e)
          throw e
        })
    }, {})

    // 添付ファイルの削除
    await Promise.all(removeAttachmentIds.map(async (attachmentId) => {
      await dispatch('deleteBuildingPostFile', { buildingId, postId, attachmentId })
    }))
  },
  deleteBuildingPostFile (_, { buildingId, postId, attachmentId }) {
    // 添付ファイル1件の削除
    const path = endpoint.postAttachmentDetail.replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{attachmentId}', attachmentId)
    return this.$_api.del({ path })
  },
  deleteBuildingPost (_, { buildingId, postId }) {
    const path = endpoint.postDetail.replace('{buildingId}', buildingId).replace('{postId}', postId)
    return this.$_api.del({ path })
  },
  async postComment ({ dispatch, commit }, { buildingId, postId, contents, files }) {
    // コメント本文の投稿
    const path = endpoint.comment.replace('{buildingId}', buildingId).replace('{postId}', postId)
    const comment = await this.$_api.post({ path, data: { contents } })
    // 添付ファイルの登録
    await files.reduce(async (acc, file) => {
      await acc
      const record = { filename: file.name, mimetype: file.type }
      const attachmentPath = endpoint.commentAttachment.replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{commentId}', comment.id)
      const res = await this.$_api.post({ path: attachmentPath, data: record })
      const putUrl = res.file_put_presigned_url
      // put file to S3 via put pre-signed url
      uploadFile(putUrl, file)
    }, {})
    await dispatch('reloadComments', { buildingId, postId, increase: true })
    commit('incrementOrDecrementCommentsCount', { postId, increase: true })
    return true
  },
  async updateComment ({ dispatch }, { buildingId, postId, commentId, contents, files, removeAttachmentIds }) {
    // コメント本文の編集
    const path = endpoint.commentDetail
      .replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{commentId}', commentId)
    const comment = await this.$_api.put({ path, data: { contents } })
    // 添付ファイルの登録
    await files.reduce(async (acc, file) => {
      await acc
      const record = { filename: file.name, mimetype: file.type }
      const attachmentPath = endpoint.commentAttachment
        .replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{commentId}', comment.id)
      const res = await this.$_api.post({ path: attachmentPath, data: record })
      const putUrl = res.file_put_presigned_url
      // put file to S3 via put pre-signed url
      uploadFile(putUrl, file)
    }, {})
    // 添付ファイルの削除
    await Promise.all(removeAttachmentIds.map(async (attachmentId) => {
      const delPath = endpoint.commentAttachmentDetail
        .replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{commentId}', comment.id).replace('{attachmentId}', attachmentId)
      await this.$_api.del({ path: delPath })
    }))
    await dispatch('reloadComments', { buildingId, postId })
    return comment
  },
  async deleteComment ({ dispatch, commit }, { buildingId, postId, commentId }) {
    const path = endpoint.commentDetail
      .replace('{buildingId}', buildingId).replace('{postId}', postId).replace('{commentId}', commentId)
    await this.$_api.del({ path })
    await dispatch('reloadComments', { buildingId, postId })
    return commit('incrementOrDecrementCommentsCount', { postId, increase: false })
  },
  // inspections
  async _loadInspections ({ state, commit, dispatch }, { params, force }) {
    const { buildingId } = params
    if (state.inspections.length > 0 && !force) {
      return state.inspections
    }
    const res = await dispatch('Inspections/fetchItems', {
      building_id: buildingId,
      limit: 1000,
    }, { root: true })

    const inspections = res.data
    commit('setInspections', inspections)
    return inspections
  },
  // defect
  async _loadDefect ({ state, dispatch, commit }, { force }) {
    if (state.defect && !force) {
      return state.defect
    }
    if (!state.basic?.gkpro_defect_id) {
      return null
    }
    const defectId = state.basic.gkpro_defect_id
    const defects = await dispatch('Defect/fetchItems', { defectIds: [defectId] }, { root: true })
    const defect = defects.find(defect => Number(defect.defectId) === defectId)
    commit('setDefect', defect)
    return defect
  },
  // intermediate-report：中間報告書の出力可否
  async _loadIntermediateReportable ({ rootGetters, state, dispatch, commit }, { params, force }) {
    // NS監査士、職人の場合は出力不可
    if (rootGetters['Auth/isNs'] || rootGetters['Auth/isWorkmen']) {
      commit('setIntermediateReportable', false)
      return false
    }

    if (state.intermediateReportable !== null && !force) {
      return state.intermediateReportable
    }
    const res = await dispatch('ReportIntermediate/loadBuildings', {
      params: { building_id: params.buildingId },
      force: true,
    }, { root: true })
    commit('setIntermediateReportable', res.length > 0)
    return res.length > 0
  },
}
const uploadFile = async (url, file) => {
  const fileRes = await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': file.type,
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
      }
    })

  if (fileRes.status !== 200) { // display error if exist
    throw new ServiceError(fileRes.status, `failed to upload file to [${url}] `)
  }
  return true
}
