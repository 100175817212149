
export default {
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: null },
    color: { type: String, default: '' },
    elevation: { type: Number, default: 1 },
    to: { type: String, default: '' },
    href: { type: String, default: '' },
    outlined: { type: Boolean, default: true },
    block: { type: Boolean, default: false },
    width: { type: Number, default: 152 },
    disabled: { type: Boolean, default: false },
    hoverDisabled: { type: Boolean, default: false }
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
