import {
  getters as baseGetters,
} from './BaseStore'

const items = [
  { id: 1, name: '法令（建築基準法）' },
  { id: 2, name: '法令（瑕疵担保履行法）' },
  { id: 3, name: '仕様書（フラット35、共通仕様書）' },
  { id: 4, name: '自社品質基準' },
  { id: 5, name: 'メーカー基準' },
  { id: 6, name: '現場環境' },
  { id: 7, name: 'その他(現状確認)' },
  { id: 8, name: '設計図書不適合' },
]

export const state = () => ({
  items,
})

export const getters = Object.assign({}, baseGetters)
