import { captureException } from '@sentry/browser'

import $_ from 'underscore'
import axios from 'axios'
import Dexie from 'dexie'

import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
  util,
} from './BaseStore'
import { UserError, NetworkError, ServiceError } from '.'
import cameraData from '~/plugins/camera-data'
import { cacheNames } from '~/mixins/cache'

export class CacheDisabledError extends Error {}

export class InspectionStatusError extends Error {
  constructor (...params) {
    super(...params)
    this.name = 'InspectionStatusError'
    this.message = `${params}`
  }
}

const localdb = new Dexie('InspectionDetail')
localdb.version(1).stores({
  inspections: 'id, item, itemResults, categoryResults, itemEvidences, categoryEvidences, files'
})

const endpoint = Object.freeze({
  detail: '/v1/inspection/',
  resultItem: '/v1/inspection/{inspectionId}/result-item/',
  resultItems: '/v1/inspection/{inspectionId}/result-items/',
  resultItemsEvidences: '/v1/inspection/{inspectionId}/result-item-evidences/',
  resultItemsEvidence: '/v1/inspection/{inspectionId}/result-item/{resultItemId}/result-item-evidence/',
  files: '/v1/inspection/{inspectionId}/files/',
  start: '/v1/inspection/{inspectionId}/start/',
  startEvidence: '/v1/inspection/{inspectionId}/start-evidence/',
  checkFile: '/v1/inspection/{inspectionId}/upload-check/',
  finish: '/v1/inspection/{inspectionId}/finish/',
  return: '/v1/inspection/{inspectionId}/return/',
  returnEvidence: '/v1/inspection/{inspectionId}/return-evidence/',
  post: '/v1/inspection-post/{inspectionId}',
  postAttachment: '/v1/inspection-post/{postId}/attachment/',
  checkList: '/v1/inspection/{inspectionId}/check-list',
  fileDetail: '/v1/inspection/{inspectionId}/file/{inspectionFileId}/'
})

const cameraDataFileName = cameraData.config.filename + '.jpg'
const statusOptions = ['ok', 'ng', 'na', 'incomplete', 'unconfirmed']
const placeOptions = ['single', 'multiple', 'overall', undefined, null]

export const state = () => ({
  items: {},
  item: null,
  itemsResults: [],
  itemsEvidences: [],
  fileurls: {},
  currentId: null,
  files: [],
  isFinishedPerCategory: []
})

export const getters = Object.assign({}, baseGetters, {
  getItem: state => state.item,
  getByInspectionId: state => (inspectionId) => {
    return state.items[inspectionId]
  },

  isAudit: state => state.item.item_sheet_inspection.check_type === 'audit',
  isNs: state => state.item.order_type === 'ns',
  isSelf: state => state.item.order_type === 'self',

  // category

  getCategories: state => state.item
    ? state.item.inspection_categories
    : [],
  getCategory: state => (categoryIndex) => {
    return state.item.inspection_categories[categoryIndex]
  },
  getCategoryName: state => (categoryIndex) => {
    return state.item.inspection_categories[categoryIndex].item_category
      ? state.item.inspection_categories[categoryIndex].item_category.name
      : null
  },
  getItemCategory: state => (categoryIndex) => {
    return state.item.inspection_categories[categoryIndex].item_category
  },
  getItemSheetCategoryId: state => (categoryIndex) => {
    return state.item.inspection_categories[categoryIndex].item_sheet_category_id
  },
  hasNextCategory: state => (categoryIndex) => {
    return state.item.inspection_categories.length - 1 > categoryIndex
  },

  // item

  getCategoryItems: state => (categoryIndex) => {
    return state.item.inspection_categories[categoryIndex].inspection_items
  },
  getCategoryItemStandard: state => (categoryIndex, itemIndex) => {
    const item = state.item
      .inspection_categories[categoryIndex]
      .inspection_items[itemIndex]
    return item.standard_item
  },

  getSheetItem: state => (categoryIndex, itemIndex) => {
    return $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
  },

  getSheetItemAnnotation: state => (categoryIndex, itemIndex) => {
    const item = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    if (!item || !item.item_sheet_annotation) {
      return null
    }
    return item.item_sheet_annotation
  },

  getInspectionItem: state => (categoryIndex, itemIndex) => {
    const sheetItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    return 'standardItem' === sheetItem.type
      ? sheetItem.standard_item
      : sheetItem.client_item
  },

  getItemExample: state => (categoryIndex, itemIndex) => {
    const standardItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item',
      'standard_item'
    ])
    if (!standardItem) {
      return []
    }
    const itemAppendix = state.files.filter(file => file.type === 'item_appendix')
    return itemAppendix.filter(file => file.attribute.item_appendixes.some(
      ia => ia.standard_item_id === standardItem.id &&
      ia.type === 'example'
    ))
  },

  isLawItem: state => (categoryIndex, itemIndex) => {
    const item = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item',
      'standard_item'
    ])
    if (!item || !item.item_types) {
      return false
    }
    return item.item_types.some(type => 1 === type.type || 2 === type.type)
  },
  itemTypes: state => (categoryIndex, itemIndex) => {
    const itemTypes = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item',
      'standard_item',
      'item_types',
    ])
    return itemTypes ? itemTypes.map(itemType => itemType.type) : []
  },

  getItemType: state => (categoryIndex) => {
    const inspectionItem = $_.first(state.item
      .inspection_categories[categoryIndex]
      .inspection_items)
    return inspectionItem.item_sheet_item.type
  },

  // results
  getCategoryItemsResults: state => (categoryIndex) => {
    if (!state.item || !state.item.inspection_categories || !state.item.inspection_categories[categoryIndex]) {
      return []
    }
    const sheetItemIds = state.item
      .inspection_categories[categoryIndex]
      .inspection_items
      .map(si => si.item_sheet_item.id)
    const itemsResultsFiltered = state.itemsResults.filter(itemResult => sheetItemIds.includes(itemResult.item_sheet_item_id))
    const _resultIds = []
    return itemsResultsFiltered.reverse().filter((itemResult) => {
      const check = _resultIds.includes(itemResult.item_sheet_item_id)
      if (!check) { _resultIds.push(itemResult.item_sheet_item_id) }
      return !check
    })
  },
  getItemResultsBySheetItemId: state => (sheetItemId) => {
    return state.itemsResults.filter((resultItem) => {
      return resultItem.item_sheet_item_id === sheetItemId
    })
  },
  getItemResults: (state, getters) => (categoryIndex, itemIndex) => {
    const sheetItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    if (!sheetItem) {
      console.error(`found bug. not found item at ${categoryIndex}-${itemIndex}`) // eslint-disable-line no-console
      return []
    }
    return getters.getItemResultsBySheetItemId(sheetItem.id)
  },
  getCurrentItemResult: state => (categoryIndex, itemIndex) => {
    const sheetItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    if (!sheetItem) {
      console.error(`found bug. not found item at ${categoryIndex}-${itemIndex}`) // eslint-disable-line no-console
      return []
    }
    return state.itemsResults.find((resultItem) => {
      return resultItem.item_sheet_item_id === sheetItem.id
    })
  },
  getFirstResultItem: state => (categoryIndex, itemIndex) => {
    const sheetItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    if (!sheetItem) {
      console.error(`found bug. not found item at ${categoryIndex}-${itemIndex}`) // eslint-disable-line no-console
      return []
    }
    return state.itemsResults.find((resultItem) => {
      return resultItem.item_sheet_item_id === sheetItem.id && resultItem.type === 'first'
    })
  },
  getIsFinishedPerCategory: state => state.isFinishedPerCategory,

  // item evidences
  getCategoryItemsEvidences: state => (categoryIndex) => {
    if (!state.item || !state.item.inspection_categories || !state.item.inspection_categories[categoryIndex]) {
      return []
    }
    const sheetItemIds = state.item
      .inspection_categories[categoryIndex]
      .inspection_items
      .map(si => si.item_sheet_item.id)
    const itemsEvidencesFiltered = state.itemsEvidences.filter(itemsEvidence => sheetItemIds.includes(itemsEvidence.item_sheet_item_id))
    return itemsEvidencesFiltered.reverse()
  },
  getEvidencesItems: state => (categoryIndex, itemIndex) => {
    const sheetItem = $_.get(state.item, [
      'inspection_categories',
      categoryIndex,
      'inspection_items',
      itemIndex,
      'item_sheet_item'
    ])
    if (!sheetItem) {
      return
    }
    return state.itemsEvidences.filter((itemEvidence) => {
      return itemEvidence.item_sheet_item_id === sheetItem.id
    })
  },
  getFileUrl: state => filepath => state.fileurls[filepath],
  getFileUrls: state => state.fileurls,
  getStandardItemIds: state => (categoryIndex, itemIndex = null) => {
    const _parameters = [
      'inspection_categories',
      categoryIndex,
      'inspection_items'
    ]
    if (itemIndex !== null) {
      _parameters.push(itemIndex)
    }
    const sheetItems = $_.get(state.item, _parameters)
    const type = itemIndex !== null
      ? sheetItems.item_sheet_item.type
      : sheetItems[0].item_sheet_item.type

    if (!sheetItems || type === 'clientItem') {
      return []
    }
    return itemIndex !== null
      ? [sheetItems.item_sheet_item.item_id]
      : sheetItems.map(item => item.item_sheet_item.item_id)
  },
  getItemAppendixFilesByStandardItemId: state => (itemIds) => {
    const files = []
    for (const itemId of itemIds) {
      const file = state.files.filter(file => file.type === 'item_appendix' &&
        file.attribute.item_appendixes.some(ia => ia.standard_item_id === itemId))
      if (file.length > 0) {
        files.push(...file)
      }
    }
    return files
  },
  getRegulationFiles: (_, getters) => (categoryIndex, itemIndex = null) => {
    const standardItemIds = getters.getStandardItemIds(categoryIndex, itemIndex)
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemIds)
    return itemAppendixFiles.filter(file => file.attribute.item_appendixes.some(
      ia => ia.type === 'regulation' && standardItemIds.includes(ia.standard_item_id)))
  },
  getLearningMovieFiles: (_, getters) => (categoryIndex, itemIndex = null) => {
    const standardItemIds = getters.getStandardItemIds(categoryIndex, itemIndex)
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemIds)
    return itemAppendixFiles.filter((file) => {
      return file.attribute.item_appendixes.some((ia) => {
        return standardItemIds.includes(ia.standard_item_id) &&
          (ia.type === 'learning-movie' || ia.type === 'learning-movie-sp')
      })
    })
  },
  getBadExampleFiles: (state, getters) => (categoryIndex, itemIndex) => {
    const standardItemIds = getters.getStandardItemIds(categoryIndex, itemIndex)
    const files = []
    for (const itemId of standardItemIds) {
      const file = state.files.filter(file => file.type === 'item_bad_example' &&
        file.attribute.item_bad_example.some(ibe => ibe.standard_items.some(
          si => si.id === itemId
        )))
      if (file.length > 0) {
        files.push(...file)
      }
    }
    return files
  },
  getExcellentExampleFiles: (state, getters) => (categoryIndex, itemIndex = null) => {
    const standardItemIds = getters.getStandardItemIds(categoryIndex, itemIndex)
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemIds)
    return itemAppendixFiles.filter(file => file.attribute.item_appendixes.some(
      ia => ia.type === 'excellent' && standardItemIds.includes(ia.standard_item_id)))
  },
  getExcellentExampleFilesByCategoryIndex: (state, getters) => (categoryIndex) => {
    const category = state.item.inspection_categories[categoryIndex].item_category
    if (!category) { return [] }
    return state.files.filter(file =>
      file.type === 'item_category_appendix' &&
      file.attribute.item_category_appendixes.some(
        ica => ica.item_category_id === category.id
      )
    )
  }
})

/**
 * mutation
 */
export const mutations = Object.assign({}, baseMutations, {
  updateStatus (state, status) {
    if (!state.item) {
      return
    }
    state.item.status = status
  },
  appendInspectionFile (state, file) {
    state.item.inspection_files.push(file)
  },
  putInspectionFile (state, { queueId, record }) {
    const index = queueId
      ? state.item.inspection_files.findIndex(f => f._queueId === queueId)
      : state.item.inspection_files.findIndex(f => f.id === record.id)
    if (index < 0) {
      return state.item.inspection_files.push(record)
    }
    state.item.inspection_files.splice(index, 1, record)
  },
  removeInspectionFile (state, inspectionFile) {
    const index = state.item.inspection_files
      .findIndex((f) => {
        if (inspectionFile.id) {
          return f.id === inspectionFile.id
        }
        if (inspectionFile.queueId) {
          return f._queueId === inspectionFile.queueId
        }
        return !!f.draftId && f.draftId === inspectionFile.draftId
      })
    if (index < 0) {
      return
    }
    state.item.inspection_files.splice(index, 1)
  },

  // items

  setItemsResults (state, results) {
    state.itemsResults = results
  },
  appendItemResult (state, record) {
    state.itemsResults.push(record)
  },
  putItemResult (state, record) {
    const index = state.itemsResults
      .findIndex(ir => ir.item_sheet_item_id === record.item_sheet_item_id)
    if (index < 0) {
      state.itemsResults.push(record)
    } else {
      if ('_queueId' in record) {
        state.itemsResults.splice(index, 1, { ...state.itemsResults[index], ...record })
        return
      }
      state.itemsResults.splice(index, 1, record)
    }
  },
  removeItemResult (state, { itemSheetItemId }) {
    const index = state.itemsResults
      .findIndex((r) => {
        return r.item_sheet_item_id === itemSheetItemId
      })
    if (index < 0) {
      return
    }
    state.itemsEvidences.splice(index, 1)
  },
  // item evidences
  setItemsEvidences (state, evidences) {
    state.itemsEvidences = evidences
  },
  appendItemEvidence (state, record) {
    state.itemsEvidences.push(record)
  },
  removeItemEvidence (state, evidence) {
    const index = state.itemsEvidences
      .findIndex((e) => {
        if (evidence.id) {
          return e.id === evidence.id
        }
        if (evidence.queueId) {
          return e._queueId === evidence.queueId
        }
        return !!e.draftId && e.draftId === evidence.draftId
      })
    if (index < 0) {
      return
    }
    state.itemsEvidences.splice(index, 1)
  },
  putItemEvidence (state, { queueId, record }) {
    const index = queueId
      ? state.itemsEvidences.findIndex(ie => ie._queueId === queueId)
      : state.itemsEvidences.findIndex(ie => ie.id === record.id)
    if (index < 0) {
      state.itemsEvidences.push(record)
      return
    }
    state.itemsEvidences.splice(index, 1, record)
  },
  // category

  appendIsFinishedPerCategory (state, { categoryIndex, isFinished }) {
    state.isFinishedPerCategory.splice(categoryIndex, 1, isFinished)
  },
  clearIsFinishedPerCategory (state) {
    state.isFinishedPerCategory = []
  },
  setFiles (state, results) {
    state.files = results
  },
})

export const actions = Object.assign({}, baseActions, {
  async fetchItem (_, inspectionId) {
    const path = endpoint.detail + inspectionId
    return await this.$_api.get({ path })
  },
  async loadItem ({ state, dispatch, commit }, { params, force }) {
    const inspectionId = Number.parseInt(params)
    if (state.item) {
      if (!force && state.item.id === inspectionId) {
        return state.item
      }
    }

    commit('clearIsFinishedPerCategory')
    let item = null
    try {
      item = await dispatch('fetchItem', inspectionId)
      commit('setItem', item)
    } catch (e) {
      const cached = await localdb.inspections.get(inspectionId)
      if (cached) {
        item = cached.item
      } else {
        throw e
      }
    }
    commit('setItem', item)

    await dispatch('loadItemResults',
      { inspectionId, force: true })
    dispatch('loadFiles', { inspectionId })
    await dispatch('loadItemsEvidences',
      { inspectionId, force: true })

    dispatch('loadCache', { inspectionId })

    return item
  },

  async store ({ dispatch }, { inspectionId }) {
    try {
      const responses = await Promise.all([
        dispatch('fetchItem', inspectionId),
        dispatch('fetchItemsResults', inspectionId),
        dispatch('fetchItemsEvidences', inspectionId),
        dispatch('loadFiles', { inspectionId, force: true })
      ])
      const data = {
        id: inspectionId,
        item: responses[0],
        itemResults: responses[1],
        itemEvidences: responses[2],
        files: responses[3]
      }
      await prefetchItemSheetFiles(data.files, true)

      await localdb.inspections.delete(inspectionId)
      return await localdb.inspections.add(data)
    } catch (e) {
      if (e instanceof CacheDisabledError) {
        console.error('cache disabled') // eslint-disable-line no-console
      }
      throw e
    }
  },
  async cacheData ({ state }, { inspectionId }) {
    await localdb.inspections.put({
      id: inspectionId,
      ...state
    })
  },
  async loadCache ({ dispatch, commit }, { inspectionId }) {
    const queueCount = await dispatch('InspectionQueue/count', null, { root: true })

    if (queueCount > 0) {
      const cached = await localdb.inspections.get(inspectionId)
      if (cached) {
        // item-results
        const cachedItemResults = cached.itemsResults.filter(ir => '_queueId' in ir)
        cachedItemResults.forEach(ir => commit('putItemResult', ir))
        // item-evidences
        const cachedItemEvidences = cached.itemsEvidences.filter(ie => '_queueId' in ie)
        cachedItemEvidences.forEach((ie) => {
          const entity = 'id' in ie ? { record: ie } : { record: ie, queueId: ie._queueId }
          commit('putItemEvidence', entity)
        })
      }
    }
    dispatch('cacheData', { inspectionId })
  },

  /**
   * results
   */
  // categories
  asyncPutCategoryStatus ({ getters, dispatch }, { categoryIndex, status }) {
    if (status === 'possible') {
      return
    }
    // itemSheetCategoryIdからitemSheetItemsを取得
    const itemSheetInspectionItems = getters.getCategoryItems(categoryIndex)

    // itemSheetItemsごとにループ更新処理を行う
    itemSheetInspectionItems.forEach((_, i) => {
      dispatch('asyncSubmitItemResult', {
        categoryIndex,
        itemIndex: i,
        status,
        place: null
      })
    })
  },

  // category evidences
  async asyncSubmitCategoryEvidence ({ getters, dispatch }, { categoryIndex, file, memo }) {
    const itemSheetInspectionItems = getters.getCategoryItems(categoryIndex)
    await Promise.all(itemSheetInspectionItems.map((_, i) =>
      dispatch('asyncSubmitItemEvidence', {
        categoryIndex,
        itemIndex: i,
        file,
        memo,
      })
    ))
  },

  /**
   * item
   */

  fetchItemsResults (_, inspectionId) {
    const path = endpoint.resultItems.replace('{inspectionId}', inspectionId)
    return this.$_api.get({ path })
  },
  async loadItemResults ({ state, commit, dispatch }, { inspectionId, force }) {
    const itemResult = $_.first(state.itemsResults)
    if ((!!itemResult && itemResult.inspection_id === inspectionId) && !force) {
      return
    }
    let results = null
    try {
      results = await dispatch('fetchItemsResults', inspectionId)
    } catch (e) {
      const cached = await localdb.inspections.get(inspectionId)
      if (cached) {
        results = cached.itemResults
      } else {
        throw e
      }
    }
    commit('setItemsResults', results)
    return results
  },

  async asyncSubmitItemResult ({ getters, dispatch, commit }, { categoryIndex, itemIndex, status, place }) {
    const sheetItem = getters.getSheetItem(categoryIndex, itemIndex)
    const inspection = getters.getItem
    // validate params
    if (!statusOptions.includes(status) || !placeOptions.includes(place)) {
      throw new UserError()
    }
    // check updatable inspection
    checkUpdatableInspection(inspection)

    const queueId = await dispatch(
      'InspectionQueue/push',
      {
        action: 'InspectionDetail/submitItemResult',
        data: {
          inspectionId: inspection.id,
          sheetItemId: sheetItem.id,
          status,
          place
        },
        forceCache: true
      },
      { root: true }
    )
    commit('putItemResult', {
      inspection_id: inspection.id,
      item_sheet_item_id: sheetItem.id,
      status,
      place,
      type: 'first',
      _queueId: queueId,
    })
    dispatch('cacheData', { inspectionId: inspection.id })
  },

  async submitItemResult ({ getters, commit, dispatch }, params) {
    const { inspectionId, sheetItemId, status, place } = params
    const path = endpoint.resultItem.replace('{inspectionId}', inspectionId)
    const data = {
      inspection_id: inspectionId,
      item_sheet_item_id: sheetItemId,
      status,
      place
    }
    const res = await this.$_api.post({ path, data })
    commit('putItemResult', res)
    dispatch('cacheData', { inspectionId })
    return res
  },

  /**
   *  item evidences
   */

  async fetchItemsEvidences (_, inspectionId) {
    const path = endpoint.resultItemsEvidences.replace('{inspectionId}', inspectionId)
    return await this.$_api.get({ path })
  },
  async loadItemsEvidences ({ state, commit, dispatch }, { inspectionId, force }) {
    if (state.itemsResults.length && !force) {
      return
    }
    let results = null
    try {
      results = await dispatch('fetchItemsEvidences', inspectionId)
    } catch (e) {
      const cached = await localdb.inspections.get(inspectionId)
      if (cached) {
        results = cached.itemEvidences
      } else {
        throw e
      }
    }
    commit('setItemsEvidences', results)
    return results
  },

  localAppendItemEvidence ({ commit, dispatch }, { inspectionId, sheetItemId, file, resultItemId, queueId }) {
    const localEntity = util.makeNewDraftRecord({
      inspection_id: inspectionId,
      item_sheet_item_id: sheetItemId,
      _localfile: file,
      _queueId: queueId,
      inspection_result_item_id: resultItemId,
    })
    commit('appendItemEvidence', localEntity)
    dispatch('cacheData', { inspectionId })
  },

  async asyncSubmitItemEvidence ({ getters, dispatch }, { categoryIndex, itemIndex, file, memo, }) {
    // check updatable inspection
    checkUpdatableInspection(getters.getItem)

    const inspectionId = getters.getItem.id
    const sheetItem = getters.getSheetItem(categoryIndex, itemIndex)
    const queueId = await dispatch(
      'InspectionQueue/push',
      {
        action: 'InspectionDetail/submitItemEvidence',
        data: {
          inspectionId,
          sheetItemId: sheetItem.id,
          file,
          memo
        },
        forceCache: true
      },
      { root: true }
    )
    const firstResultItem = getters.getFirstResultItem(categoryIndex, itemIndex)
    dispatch('localAppendItemEvidence', {
      inspectionId,
      sheetItemId: sheetItem.id,
      file,
      resultItemId: firstResultItem.id,
      queueId
    })
  },

  async submitItemEvidence ({ getters, commit, dispatch }, params) {
    const { inspectionId, sheetItemId, file, memo, $queueId } = params

    await dispatch('loadItemResults', { inspectionId, force: false })
    const resultItem = $_.first(getters.getItemResultsBySheetItemId(sheetItemId))
    if (!resultItem || !resultItem.id) {
      // queue順序不整合. 通常は発生しない
      console.error('resultItem not found.') // eslint-disable-line no-console
      captureException(new Error('resultItem not found.'))
      commit('removeItemEvidence', {
        queueId: $queueId,
      })
      commit('removeItemResult', {
        itemSheetItemId: sheetItemId,
      })
      dispatch('cacheData', { inspectionId })
      return
    }

    const path = endpoint.resultItemsEvidence
      .replace('{inspectionId}', inspectionId)
      .replace('{resultItemId}', resultItem.id)
    const data = makeItemEvidenceRecord(inspectionId, sheetItemId, file, memo)

    const res = await this.$_api.post({ path, data })
    const putUrl = res.file.file_put_presigned_url

    // put file to S3 via put pre-signed url
    await uploadFile(putUrl, file)
    const evidence = Object.assign({
      _localfile: file,
    }, res.inspection_result_item_evidence)
    commit('putItemEvidence', {
      queueId: $queueId,
      record: evidence
    })
    dispatch('cacheData', { inspectionId })
  },

  async asyncDeleteItemEvidence ({ getters, dispatch, commit }, { categoryIndex, itemIndex, evidence }) {
    // check updatable inspection
    checkUpdatableInspection(getters.getItem)

    const inspectionId = getters.getItem.id
    if ('_queueId' in evidence) {
      dispatch(
        'InspectionQueue/cancel',
        { queueId: evidence._queueId },
        { root: true },
      )
    }
    if (!evidence.id) {
      commit('removeItemEvidence', evidence)
      dispatch('cacheData', { inspectionId })
      return
    } else if ('_method' in evidence && evidence._method === 'delete') {
      delete evidence._queueId
      delete evidence._method
      commit('putItemEvidence', {
        record: evidence
      })
      dispatch('cacheData', { inspectionId })
      return
    }
    const sheetItem = getters.getSheetItem(categoryIndex, itemIndex)

    const $queueId = await dispatch(
      'InspectionQueue/push',
      {
        action: 'InspectionDetail/deleteItemEvidence',
        data: { sheetItemId: sheetItem.id, evidence }
      },
      { root: true }
    )
    evidence._queueId = $queueId
    evidence._method = 'delete'
    commit('putItemEvidence', {
      record: evidence
    })
    dispatch('cacheData', { inspectionId })

    return $queueId
  },

  async deleteItemEvidence ({ getters, dispatch, commit }, { sheetItemId, evidence }) {
    const resultItem = $_.first(getters.getItemResultsBySheetItemId(sheetItemId))
    const inspectionId = resultItem.inspection_id
    if (!resultItem || !resultItem.id) {
      // queue順序不整合. 通常は発生しない
      console.error('resultItem not found.') // eslint-disable-line no-console
      captureException(new Error('resultItem not found.'))
      commit('appendItemEvidence', evidence)
      commit('removeItemResult', {
        itemSheetItemId: sheetItemId,
      })
      dispatch('cacheData', { inspectionId })
      return
    }

    const path = endpoint.resultItemsEvidence.replace('{inspectionId}', inspectionId)
      .replace('{resultItemId}', resultItem.id) + evidence.id
    await this.$_api.del({ path })

    commit('removeItemEvidence', evidence)
    dispatch('cacheData', { inspectionId })
  },

  /**
   *  inspection
   */

  async asyncStartInspectionAndPutEvidences ({ getters, dispatch, commit }, { files, location }) {
    const inspectionId = getters.getItem.id
    // 監査のステータス更新：開始
    await dispatch(
      'InspectionQueue/push',
      {
        action: 'InspectionDetail/startInspectionStatus',
        data: {
          inspectionId,
          latitude: location ? String(location.latitude) : null,
          longitude: location ? String(location.longitude) : null
        },
        forceCache: true
      },
      { root: true }
    )
    this.$_fire.logStartInspection(inspectionId, getters.getItem.order_type)
    commit('updateStatus', 'wip')
    dispatch('cacheData', { inspectionId })
    // 監査開始の証跡登録
    await dispatch('asyncStartEvidences', { files })
  },
  async asyncStartEvidences ({ getters, dispatch }, { files }) {
    // check updatable inspection
    checkUpdatableInspection(getters.getItem)

    const inspectionId = getters.getItem.id
    await Promise.all(files.map(async (file) => {
      const queueId = await dispatch(
        'InspectionQueue/push',
        {
          action: 'InspectionDetail/startEvidence',
          data: { inspectionId, file },
          forceCache: true
        },
        { root: true }
      )

      dispatch('localAppendStartFile', { inspectionId, file, queueId })
    }))
  },
  localAppendStartFile ({ commit, dispatch }, { inspectionId, file, queueId }) {
    const localEntity = util.makeNewDraftRecord({
      inspection_id: inspectionId,
      type: 'start',
      _localfile: file,
      _queueId: queueId,
    })
    commit('appendInspectionFile', localEntity)
    dispatch('cacheData', { inspectionId })
  },
  async startEvidence ({ dispatch, commit }, { inspectionId, file, $queueId }) {
    const path = endpoint.startEvidence.replace('{inspectionId}', inspectionId)
    const filename = file.name ?? cameraDataFileName
    const data = { filename, mimetype: file.type }
    const res = await this.$_api.post({ path, data })
    // put file to S3 via put pre-signed url
    const putUrl = res.file.file_put_presigned_url
    await uploadFile(putUrl, file)

    const startFile = Object.assign({
      _localfile: file
    }, res.inspection_start_evidence)
    commit('putInspectionFile', {
      queueId: $queueId,
      record: startFile
    })

    dispatch('cacheData', { inspectionId })
  },
  async startInspectionStatus (_, { inspectionId, latitude, longitude }) {
    const path = endpoint.start.replace('{inspectionId}', inspectionId)
    const data = {
      device_datetime: new Date(),
      latitude,
      longitude,
    }

    return await this.$_api.post({ path, data })
  },

  async finishInspectionStatus ({ getters, commit }, { inspectionId }) {
    const data = { device_datetime: new Date() }
    const path = endpoint.finish.replace('{inspectionId}', inspectionId)
    try {
      const res = await this.$_api.post({ path, data })
      this.$_fire.logFinishInspection(inspectionId, getters.getItem.order_type)
      await localdb.inspections.delete(inspectionId)
      const status = getters.getItem.order_type === 'ns' ? 'unchecked' : 'done'
      commit('updateStatus', status)
      return res
    } catch (e) {
      if (e instanceof UserError) {
        if (e.body.message === 'InspectionAlreadyDone') {
          return this.$toast.error('完了済みの監査です。')
        }
      }
      throw e
    }
  },
  async asyncUploadCheckFiles ({ getters, dispatch }, { files }) {
    // check updatable inspection
    checkUpdatableInspection(getters.getItem)

    const inspectionId = getters.getItem.id
    return await Promise.all(files.map(async (file) => {
      const queueId = await dispatch(
        'InspectionQueue/push',
        {
          action: 'InspectionDetail/uploadCheckFile',
          data: { inspectionId, file }
        },
        { root: true }
      )

      dispatch('localAppendCheckFile', { inspectionId, file, queueId })
    }))
  },
  localAppendCheckFile ({ commit, dispatch }, { inspectionId, file, queueId }) {
    const localEntity = util.makeNewDraftRecord({
      inspection_id: inspectionId,
      type: 'check',
      _localfile: file,
      _queueId: queueId,
    })
    commit('appendInspectionFile', localEntity)
    dispatch('cacheData', { inspectionId })
  },
  async uploadCheckFile ({ commit, dispatch }, { inspectionId, file, $queueId }) {
    const path = endpoint.checkFile.replace('{inspectionId}', inspectionId)
    const filename = file.name ?? cameraDataFileName
    const data = { filename, mimetype: file.type }
    const res = await this.$_api.post({ path, data })
    // put file to S3 via put pre-signed url
    const putUrl = res.file.file_put_presigned_url
    await uploadFile(putUrl, file)

    const checkFile = Object.assign({
      _localfile: file
    }, res.inspection_start_evidence)
    commit('putInspectionFile', {
      queueId: $queueId,
      record: checkFile
    })

    dispatch('cacheData', { inspectionId })
  },

  async asyncDeleteInspectionFile ({ getters, dispatch, commit }, { inspectionFile }) {
    // check updatable inspection
    checkUpdatableInspection(getters.getItem)

    const inspectionId = getters.getItem.id
    if ('_queueId' in inspectionFile) {
      dispatch(
        'InspectionQueue/cancel',
        { queueId: inspectionFile._queueId },
        { root: true },
      )
    }
    if (!inspectionFile.id) {
      commit('removeInspectionFile', inspectionFile)
      return dispatch('cacheData', { inspectionId })
    } else if ('_method' in inspectionFile && inspectionFile._method === 'delete') {
      delete inspectionFile._queueId
      delete inspectionFile._method
      commit('putInspectionFile', {
        record: inspectionFile
      })
      return dispatch('cacheData', { inspectionId })
    }

    const queueId = await dispatch(
      'InspectionQueue/push',
      {
        action: 'InspectionDetail/deleteInspectionFile',
        data: { inspectionId, inspectionFileId: inspectionFile.id },
        forceCache: true
      },
      { root: true }
    )
    dispatch('localDeleteInspectionFile', { inspectionId, inspectionFile, queueId })
    return queueId
  },
  localDeleteInspectionFile ({ commit, dispatch }, { inspectionId, inspectionFile, queueId }) {
    inspectionFile._queueId = queueId
    inspectionFile._method = 'delete'
    commit('putInspectionFile', {
      record: inspectionFile
    })
    return dispatch('cacheData', { inspectionId })
  },
  async deleteInspectionFile ({ getters, dispatch, commit }, { inspectionId, inspectionFileId }) {
    const path = endpoint.fileDetail.replace('{inspectionId}', inspectionId).replace('{inspectionFileId}', inspectionFileId)
    await this.$_api.del({ path })

    const inspectionItem = await dispatch('fetchItem', inspectionId)
    commit('setItem', inspectionItem)
    dispatch('cacheData', { inspectionId })
  },

  fetchFiles (_, inspectionId) {
    const path = endpoint.files.replace('{inspectionId}', inspectionId)
    return this.$_api.get({ path })
  },
  async loadFiles ({ state, dispatch, commit }, { inspectionId, force }) {
    if (state.files.length && !force) {
      return
    }
    const itemfiles = await dispatch('fetchFiles', inspectionId)
    commit('setFiles', itemfiles)
    return itemfiles
  },

  async postInspectionPost ({ getters }, { title, contents, files }) {
    const inspectionId = getters.getItem.id
    // 監査スレッドへの投稿
    const record = {
      visibility: 'public',
      title,
      contents,
    }
    const path = endpoint.post.replace('{inspectionId}', inspectionId)
    const post = await this.$_api.post({ path, data: record })
    // 添付ファイルの登録
    await Promise.all(files.map(async (file) => {
      const filename = cameraData.config.filename + '.jpg'
      const record = { filename, mimetype: file.type }
      const path = endpoint.postAttachment.replace('{postId}', post.id)
      const res = await this.$_api.post({ path, data: record })
      const putUrl = res.file.file_put_presigned_url
      // put file to S3 via put pre-signed url
      uploadFile(putUrl, file)
    }))
  },

  /**
   *  checkList
   */
  fetchCheckList (_, inspectionId) {
    const path = endpoint.checkList
      .replace('{inspectionId}', inspectionId)
    return this.$_api.get({ path })
  },
})

// private util
const makeItemEvidenceRecord = (inspectionId, itemSheetItemId, file, memo) => {
  const filename = file.name ?? cameraDataFileName
  return util.makeNewDraftRecord({
    inspection_id: inspectionId,
    item_sheet_item_id: itemSheetItemId,
    filename,
    mimetype: file.type,
    memo,
  })
}

const uploadFile = async (url, file) => {
  try {
    return await axios.put(url,
      file,
      {
        headers: {
          'Content-Type': file.type,
          'Cache-Control': 'no-cache',
          'Access-Control-Allow-Origin': '*'
        }
      })
  } catch (e) {
    console.error(`failed to upload file to [${url}]`) // eslint-disable-line no-console
    if (!e.response) {
      throw new NetworkError()
    }
    throw new ServiceError(`respond status:[${e.response.status}]`)
  }
}

const prefetchItemSheetFiles = async (itemSheetfiles, force) => {
  if (typeof caches === 'undefined') {
    throw new CacheDisabledError()
  }
  const cacheName = cacheNames.assets

  const cache = await caches.open(cacheName)
  const cachePromises = itemSheetfiles.map(isf => prefetchItemSheetFile(isf, cache, force))
  return Promise.all(cachePromises)
}

const prefetchItemSheetFile = (itemSheetFile, cache, force) => {
  const file = itemSheetFile.file
  if (file.mimetype.startsWith('video/')) {
    return true
  }
  const promises = []
  promises.push(prefetchUrl(file.original_file_presigned_url, cache, force))
  if (file.card_image_file_presigned_url) {
    promises.push(prefetchUrl(file.card_image_file_presigned_url, cache, force))
  }
  if (file.icon_image_file_presigned_url) {
    promises.push(prefetchUrl(file.icon_image_file_presigned_url, cache, force))
  }

  return Promise.all(promises)
}

const prefetchUrl = async (url, cache, force) => {
  const hit = await cache.match(url, { ignoreSearch: true })
  if (hit) {
    if (!force) {
      return true
    }
    await cache.delete(url, {
      ignoreSearch: true
    })
  }
  return cache.add(url)
}

const checkUpdatableInspection = (inspection) => {
  if (inspection.item_sheet_inspection.check_type !== 'audit') {
    return true
  }

  if (inspection.order_type === 'self') {
    if (!['wip', 'scheduled', 'done'].includes(inspection.status)) {
      throw new InspectionStatusError('自社監査の予定が未確定か、完了済みではありません。')
    }
    return true
  }
  if (inspection.item_sheet_inspection.audit_times === 0) {
    if (!['scheduled', 'wip', 'unchecked'].includes(inspection.status)) {
      throw new InspectionStatusError('監査ステータスが予約確定済になっていません')
    }
    return true
  }
  if (!['wip', 'unchecked'].includes(inspection.status)) {
    throw new InspectionStatusError('NS監査のステータスが実施中または未チェックではありません。')
  }
  return true
}
