import {
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/construction-feedback/',
  basic: '/v1/construction-feedback/',
  comments: '/v1/construction-feedback/{constructionFeedbackClientId}/item/{constructionFeedbackClientItemId}/comments/',
  evidences: '/v1/construction-feedback/{constructionFeedbackClientId}/item/{constructionFeedbackClientItemId}/evidences/',
  items: '/v1/construction-feedback/{constructionFeedbackClientId}/items/',
})

export const actions = Object.assign({}, baseActions, {
  fetch (_, params) {
    return this.$_api.get({ path: endpoint.list, query: params })
  },
  delete (_, { id }) {
    return this.$_api.del({ path: endpoint.basic + id })
  },
  fetchItem (_, params) {
    const path = endpoint.basic + params.id
    return this.$_api.get({ path, query: params })
  },
  save (_, { constructionFeedbackClientId }) {
    const path = endpoint.basic + constructionFeedbackClientId
    return this.$_api.post({ path })
  },
  fetchClientItems (_, params) {
    const path = endpoint.items
      .replace('{constructionFeedbackClientId}', params.id)
    return this.$_api.get({ path, query: params })
  },
  updateComments (_, { constructionFeedbackClientId, constructionFeedbackClientItemId, entity }) {
    const path = endpoint.comments
      .replace('{constructionFeedbackClientId}', constructionFeedbackClientId)
      .replace('{constructionFeedbackClientItemId}', constructionFeedbackClientItemId)
    return this.$_api.put({ path, data: entity })
  },
  fetchEvidences (_, { constructionFeedbackClientId, constructionFeedbackClientItemId, params }) {
    const path = endpoint.evidences
      .replace('{constructionFeedbackClientId}', constructionFeedbackClientId)
      .replace('{constructionFeedbackClientItemId}', constructionFeedbackClientItemId)
    return this.$_api.get({ path, query: params })
  },
  saveEvidences (_, { constructionFeedbackClientId, constructionFeedbackClientItemId, data }) {
    const path = endpoint.evidences
      .replace('{constructionFeedbackClientId}', constructionFeedbackClientId)
      .replace('{constructionFeedbackClientItemId}', constructionFeedbackClientItemId)
    return this.$_api.put({ path, data })
  },
})
