import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endPoint = '/v1/item-sheet/'
const endpointItemSheets = '/v1/item-sheets/'

const initialState = () => ({
  item: {},
  items: [],
  meta: {},
  params: {},
  currentId: null,
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getAllByType: state => (type) => {
    return state.items.filter(item => item.type === type)
  },
  getMeta (state) {
    return state.meta
  }
})
export const mutations = Object.assign({}, baseMutations, {
  updateProcessIds (state, { itemSheetId, processIds }) {
    const target = state.items.find(item => item.id === itemSheetId)
    target.processIds = processIds
  },
  setMeta (state, meta) {
    state.meta = meta
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  appendProcesses ({ commit, getters }, { itemSheetId, processIds }) {
    // todo: call update api
    const target = getters.getById(itemSheetId)
    let newProcesses = processIds
    if (target.processIds) {
      newProcesses = target.processIds.concat(processIds)
    }
    commit('updateProcessIds', {
      itemSheetId: target.id,
      processIds: newProcesses
    })
  },
  fetchItem (_, { itemSheetId, itemSheetVersionId }) {
    let path = endPoint + `${itemSheetId}`

    if (itemSheetVersionId) {
      path += `/version/${itemSheetVersionId}/`
    }
    return this.$_api.get({ path })
  },
  fetchItems (_, payload) {
    return this.$_api.get({ path: endpointItemSheets, query: payload })
  },
})
