//
// 監査実施画面のアクセス限定
//
export default async function ({ route, store, query, redirect }) {
  // 対象：/inspection/force 配下
  if (route.name?.startsWith('inspection-force')) {
    const inspectionId = Number.parseInt(query.id)
    if (!inspectionId) {
      return redirect('/inspection/')
    }

    const item = await store.dispatch('InspectionDetail/loadItem', {
      params: inspectionId
    })
    const user = store.getters['Auth/user']

    // NS監査の場合：監査担当者のみ
    if (item.order_type === 'ns') {
      if (item.inspector_id !== user.id) {
        redirect('/inspection/')
      }
    // 自社管理の場合：監査担当ロールの物件担当者のみ
    } else if (item.item_sheet_inspection.check_type === 'management') {
      if (!item.assignee_ids.includes(user.id)) {
        redirect('/inspection/')
      }
    // 自社監査の場合：item_sheet_inspectionに設定されているrole。設定されていない場合は現場担当者
    } else if (item.item_sheet_inspection.check_type === 'audit') {
      if (
        (!item.assignee_ids.includes(user.id) && item.assignee_ids.length > 0) ||
        (!item.default_assignee_ids.includes(user.id) && item.assignee_ids.length === 0)
      ) {
        redirect('/inspection/')
      }
    }
  }
}
