
export default {
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: null },
    color: { type: String, default: 'primary' },
    lineColor: { type: String, default: null },
    to: { type: String, default: '' },
    href: { type: String, default: null },
    nuxt: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    blank: { type: Boolean, default: false },
  },
  computed: {
    target () {
      return this.blank ? '_blank' : null
    },
    rel () {
      return this.blank ? 'noopener noreferrer' : null
    },
  },
  methods: {
    click (e) {
      this.$emit('click', e)
    }
  }
}
