import $_ from 'underscore'
import { captureException } from '@sentry/browser'
import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
import { uploadFile } from '~/mixins/uploadFile'

const endPoint = {
  list: '/v1/improper-works/',
  detail: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/',
  correct: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/correct/',
  correctItem: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/correct/{resultItemId}/',
  evidence: '/v1/improper-work/result/{inspectionResultItemId}/evidence/',
  approval: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/approval/',
  delete: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/undo/{inspectionResultItemId}/',
  user: '/v1/improper-work/{inspectionId}/{itemSheetItemId}/user/{userId}/',
}

const initialState = () => ({
  items: [],
  item: null,
  meta: {},
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  isLawItem: state => () => {
    const item = $_.get(state.item, [
      'item_sheet_item',
      'item'
    ])
    if (!item || !item.item_types) {
      return false
    }
    return item.item_types.some(type => 1 === type.type || 2 === type.type)
  },
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, conditions) {
    // 設計以外を選択したとき
    if (conditions.process_id === 'without') {
      delete conditions.process_id
      conditions.without_design = 1
    }
    return this.$_api.get({ path: endPoint.list, query: conditions })
  },
  submitEvidence (_, { resultItemId, file }) {
    const path = endPoint.evidence
      .replace('{inspectionResultItemId}', resultItemId)

    const entity = {
      filename: file.name,
      mimetype: file.type,
      memo: ''
    }
    return this.$_api.post({ path, data: entity })
  },
  async submitUpdated ({ dispatch }, { inspectionId, itemSheetItemId, entity, files, eventHandler }) {
    const path = endPoint.correct
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)

    const resultItem = await this.$_api.post({ path, data: entity })
    const resultItemId = resultItem.id

    for await (const file of files) {
      const res = await dispatch('submitEvidence', { resultItemId, file })
      const fileId = res.id
      await dispatch('subscribeToFileResizing', { fileId, eventHandler })
      const putUrl = res.file_put_presigned_url
      uploadFile(putUrl, file)
    }
  },
  async submitCorrectUpdated ({ dispatch }, { inspectionId, itemSheetItemId, resultItemId, entity, files, eventHandler }) {
    entity.evidence_ids = files.flatMap(file => file.id ? [file.id] : [])
    const path = endPoint.correctItem
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)
      .replace('{resultItemId}', resultItemId)
    await this.$_api.put({ path, data: entity })

    for await (const file of files) {
      if (file.id) {
        continue
      }
      const res = await dispatch('submitEvidence', { resultItemId, file })
      const fileId = res.id
      await dispatch('subscribeToFileResizing', { fileId, eventHandler })
      const putUrl = res.file_put_presigned_url
      uploadFile(putUrl, file)
    }
  },
  async subscribeToFileResizing ({ dispatch }, { fileId, eventHandler }) {
    const params = {
      channel: '/files/' + fileId,
      callback: (msg) => {
        if (eventHandler && typeof eventHandler === 'function') {
          eventHandler(msg)
        }
      }
    }
    try {
      await dispatch('NotificationConnection/subscribe', params, { root: true })
    } catch (e) {
      captureException(e)
    }
  },
  fetchItem (_, { inspectionId, itemSheetItemId }) {
    const path = endPoint.detail
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)
    return this.$_api.get({ path })
  },
  updateItem (_, { inspectionId, itemSheetItemId, entity }) {
    const path = endPoint.detail
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)

    return this.$_api.put({ path, data: entity })
  },
  async submitApproval (_, { inspectionId, itemSheetItemId, entity }) {
    const path = endPoint.approval
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)

    return await this.$_api.post({ path, data: entity })
  },
  async delete (_, { inspectionId, itemSheetItemId, inspectionResultItemId }) {
    const path = endPoint.delete
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)
      .replace('{inspectionResultItemId}', inspectionResultItemId)

    return await this.$_api.del({ path })
  },
  async assignUser (_, { inspectionId, itemSheetItemId, userId }) {
    const path = endPoint.user
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)
      .replace('{userId}', userId)

    return await this.$_api.put({ path })
  },
  async resignUser (_, { inspectionId, itemSheetItemId, userId }) {
    const path = endPoint.user
      .replace('{inspectionId}', inspectionId)
      .replace('{itemSheetItemId}', itemSheetItemId)
      .replace('{userId}', userId)

    return await this.$_api.del({ path })
  },
})
