import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
import { uploadFile } from '~/mixins/uploadFile'

const endpoint = {
  fileList: '/v1/users/imports/',
  submitFile: '/v1/users/import/',
  run: '/v1/users/import/{userImportId}/run',
  detail: '/v1/users/import/{userImportId}',
}

const initialState = () => ({
  items: []
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, conditions) {
    return this.$_api.get({ path: endpoint.fileList, query: conditions })
  },
  async submitFile (_, { file }) {
    const params = {
      filename: file.name,
      mimetype: file.type
    }
    const res = await this.$_api.post({ path: endpoint.submitFile, data: params })
    uploadFile(res.file.file_put_presigned_url, file)
  },
  submitRun (_, { userImportId }) {
    const path = endpoint.run
      .replace('{userImportId}', userImportId)
    return this.$_api.post({ path })
  },
  fetchDetail (_, { userImportId }) {
    const path = endpoint.detail
      .replace('{userImportId}', userImportId)
    return this.$_api.get({ path })
  },
})
