import moment from 'moment'
import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'
const endpoint = '/v1/holiday-settings/'

const initialState = () => ({
  items: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, conditions) {
    return this.$_api.get({ path: endpoint, query: conditions })
  },
  async loadHolidaysNextTwoYear ({ commit, dispatch }) {
    commit('setInitialState')
    const beginningOfMonth = moment().startOf('month')
    const res = await dispatch(
      'fetchItems', {
        start: beginningOfMonth.format('YYYY-MM-DD'),
        end: beginningOfMonth.add(2, 'years').format('YYYY-MM-DD'),
      }
    )
    const holidaysNextTwoYear = res.data
    commit('setItems', holidaysNextTwoYear)
    return holidaysNextTwoYear
  },
})
