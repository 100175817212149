import { initialState } from './state'

export default {
  clear (state) {
    Object.assign(state, initialState())
  },
  setKey (state, val) {
    state.key = val
  },
  clearKey (state) {
    state.key = null
  },
  init (state, { samples, requiredPhotos, maxPhotos, isStart, photoNote, tags }) {
    state.samples = samples
    state.requiredPhotos = requiredPhotos
    state.maxPhotos = maxPhotos
    state.isStart = isStart
    state.files = []
    state.active = false
    state.photoNote = photoNote
    state.tags = tags
  },
  setFiles (state, files) {
    state.files = files
  },
  open (state) {
    state.active = true
  },
  close (state) {
    state.active = false
  },
}
