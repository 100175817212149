export default {
  open ({ state, commit }, { samples = [], requiredPhotos = null, maxPhotos = null, isStart = false, photoNote = null, tags = [] }) {
    if (state.active) {
      return
    }
    commit('init', { samples, requiredPhotos, maxPhotos, isStart, photoNote, tags })

    const key = Date.now() + Math.random()
    commit('setKey', key)

    commit('open')
    return key
  },
  done ({ state, commit }, { files }) {
    if (!state.active || files.length === 0) {
      return
    }
    commit('setFiles', files)
    commit('close')
  },
  cancel ({ commit }) {
    commit('clear')
  },
}
