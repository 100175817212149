import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpointBuildingItemSheetCourse = '/v1/building/{buildingId}/item-sheet-course'
const endpointCourseList = '/v1/building/{buildingId}/item-sheet-courses'

const initialState = () => ({
  items: [],
  meta: {},
  params: {}
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getConstruction: (state) => {
    const item = state.items
      .find(item => !!item.construction_sheet_id)
    if (!item) {
      return null
    }
    return makeSheetInfo(item, 'construction')
  },
  getMaintenances: (state) => {
    const items = state.items
      .filter(item => !!item.maintenance_sheet_id)
    if (!items.length) {
      return null
    }
    return items.map(item => makeSheetInfo(item, 'maintenance'))
  },
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  submit (_, { buildingId, entity }) {
    const path = endpointBuildingItemSheetCourse
      .replace('{buildingId}', buildingId)

    return this.$_api.post({ path, data: entity })
  },
  fetchItems (_, buildingId) {
    const path = endpointCourseList.replace('{buildingId}', buildingId)
    return this.$_api.get({ path })
  },
})

const makeSheetInfo = (item, type) => {
  return {
    itemSheetId: item[type + '_sheet_id'],
    versionId: item[type + '_sheet_version_id'],
    name: item[type + '_sheet_name'],
    version: item[type + '_sheet_version'],
  }
}
