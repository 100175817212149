import cameraData from '~/plugins/camera-data'

export default {
  methods: {
    resizeImage (dataurl, fileName) {
      return new Promise(
        function (resolve) {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const img = new Image()
          img.onload = () => {
            const width = img.width
            const height = img.height
            const ratio = width / height
            const maxWidth = cameraData.config.width
            const maxHeight = cameraData.config.height
            let newWidth = width
            let newHeight = height

            // 画像のサイズが両方とも設定サイズ未満の場合はそのまま返す
            if (width > maxWidth || height > maxHeight) {
              if (width > height) {
                newWidth = maxWidth
                newHeight = maxWidth / ratio
              } else {
                newHeight = maxHeight
                newWidth = maxHeight * ratio
              }
            }
            canvas.width = newWidth
            canvas.height = newHeight
            ctx.drawImage(img, 0, 0, newWidth, newHeight)

            const file = canvas2file(canvas, fileName)
            resolve(file)
          }
          img.src = dataurl
        })
    },
  }
}

const canvas2file = (canvas, name) => {
  const type = 'image/jpeg'
  const dataurl = canvas.toDataURL(type)

  const bin = atob(dataurl.split(',')[1])
  const buffer = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }

  const file = new File([buffer.buffer], name, { type })
  file.dataurl = dataurl
  return file
}
