import {
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  settings: '/v1/construction-feedback/settings',
  setting: '/v1/construction-feedback/setting/',
})

export const actions = Object.assign({}, baseActions, {
  fetch (_, params) {
    return this.$_api.get({ path: endpoint.settings, query: params })
  },
  create (_, { data }) {
    return this.$_api.post({ path: endpoint.setting, data })
  },
  edit (_, { id, data }) {
    return this.$_api.put({ path: endpoint.setting + id, data })
  },
  delete (_, { id }) {
    return this.$_api.del({ path: endpoint.setting + id })
  },
})
