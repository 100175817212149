export const getters = {
  getById: state => id => state.items.find(item => item.id === id),
  getName: state => (id) => {
    const item = state.items.find(item => item.id === id)
    return item ? item.name : null
  },
  getAll: state => state.items,
  getMeta: state => state.meta,
  getItem: state => state.item,
  getOptions: state => state.items.map((item) => {
    return { text: item.name, value: item.id }
  }),
  getCurrentId: state => state.currentId
}

export const mutations = {
  setItems (state, items) {
    state.items = items
  },
  setMeta (state, meta) {
    state.meta = meta
  },
  setParams (state, params) {
    state.params = params
  },
  setItem (state, item) {
    state.item = item
  },
  pushItem (state, item) {
    state.items.push(item)
  },
  clearItems (state) {
    state.items = []
  },
  clearMeta (state) {
    state.meta = []
  },
  updateItem (state, { entity }) {
    const target = state.items.find(item => item.id === entity.id)
    if (target) {
      Object.assign(target, entity)
    }
  },
  upsertItem (state, { entity }) {
    const target = state.items.find(item => item.id === entity.id)
    if (target) {
      Object.assign(target, entity)
    } else {
      state.items.push(entity)
    }
  },
  setCurrent (state, item) {
    state.currentId = item.id
  },
}

export const actions = {
  fetchItems ({ commit, getters }) {
    console.error('should not be called') // eslint-disable-line no-console
    // TODO: fetch data by api
    const items = getters.getAll
    commit('setItems', items)
  },
  submit ({
    commit,
    getters
  }, { entity }) {
    console.error('should not be called') // eslint-disable-line no-console
    // todo: call api
    const items = getters.getAll
    entity.id = getNextId(items)
    // todo: it should fetch all fresh items and replace entirely
    commit('pushItem', entity)
    return entity
  },
  update ({
    commit,
  }, { entity }) {
    console.error('should not be called') // eslint-disable-line no-console
    // todo: call api
    // todo: it should fetch all fresh items and replace entirely
    commit('updateItem', { entity })
  },
  fetchItem ({ getters }, id) {
    // todo: it should fetch by api
    console.error('should not be called') // eslint-disable-line no-console
    const targetId = Number.parseInt(id)
    const current = getters.getById(targetId)
    const target = Object.assign({}, current)
    return target
  },
  /**
   * const { force, params }  = options
   * force: データの有無に関わらず、fetchItemsを呼び出します。
   * params: fetchItems 呼び出し時に使用するパラメーターです。
   */
  async loadItems ({ state, dispatch, commit }, options) {
    const force = options && options.force
    if (!force && state.items && state.items.length) {
      return
    }
    const params = options && options.params
    const res = await dispatch('fetchItems', params)
    if (Array.isArray(res)) {
      commit('setItems', res)
      return res
    }

    commit('setItems', res.data)
    commit('setMeta', res.meta)
    if (params) {
      commit('setParams', params)
    }
    return res.data
  },
  async loadItem ({ state, dispatch, commit }, { params, force }) {
    if (!force && state.item) {
      return
    }
    const item = await dispatch('fetchItem', params)
    commit('setItem', item)
    return item
  },
}

export const util = {
  // キャメルケースからスネークケースに変換（文字列）
  toUnderscoreCase (str) {
    return str.split(/(?=[A-Z])/).join('_').toLowerCase()
  },
  // キャメルケースからスネークケースに変換（オブジェクト）
  toUnderscoreCaseObject (obj) {
    const result = {}
    Object.keys(obj).forEach((key) => {
      result[util.toUnderscoreCase(key)] = obj[key]
    })
    return result
  },
  makeNewDraftRecord (props) {
    // todo: consider deeply
    return Object.assign({
      draftId: String(new Date().getTime())
    }, props)
  }
}

export const demoUtil = {
  assignId (items) {
    let id = 1
    items.forEach((item) => {
      item.id = id++
    })
  },
  getNextId (items) {
    const ids = items.map(item => item.id)
      .filter(v => Number.isInteger(v))
    return Math.max(...ids) + 1
  }
}

const getNextId = (items) => {
  const ids = items.map(item => item.id)
    .filter(v => Number.isInteger(v))
  return Math.max(...ids) + 1
}
