
import LayoutNavBar from '~/components/organisms/layout/LayoutNavBar'

export default {
  components: {
    LayoutNavBar,
  },
  data () {
    return {}
  },
  head () {
    return {
      title: '項目書印刷',
      meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=1' }
      ]
    }
  },
}
