
export default {
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: null },
    color: { type: String, default: '' },
    to: { type: String, default: '' },
    href: { type: String, default: '' },
    outlined: { type: Boolean, default: true },
    width: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    customStyle: { type: Object, default: () => {} },
  },
  computed: {
    btnClass () {
      return Object.assign({}, this.customStyle, { disabled: this.disabled })
    },
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
