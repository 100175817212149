import {
  actions as baseActions
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/dictionary/building-types/'
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_,) {
    return this.$_api.get({ path: endpoint.list })
  },
})
