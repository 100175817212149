import { render, staticRenderFns } from "./CommonIconButton.vue?vue&type=template&id=00e3f87b&scoped=true&lang=pug"
import script from "./CommonIconButton.vue?vue&type=script&lang=js"
export * from "./CommonIconButton.vue?vue&type=script&lang=js"
import style0 from "./CommonIconButton.vue?vue&type=style&index=0&id=00e3f87b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e3f87b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/codebuild/output/src2904404819/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonIcon.vue').default})
