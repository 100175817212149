
import CommonLargeButton from 'atoms/common/CommonLargeButton'

export default {
  components: {
    CommonLargeButton,
  },
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: null },
    to: { type: String, default: '' },
    href: { type: String, default: '' },
    width: { type: Number, default: 152 },
    disabled: { type: Boolean, default: false },
    hoverDisabled: { type: Boolean, default: false }
  },
  methods: {
    click (e) {
      if (this.disabled) {
        return
      }
      this.$emit('click', e)
    }
  }
}
