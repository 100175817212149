export const initialState = () => ({
  samples: [],
  requiredPhotos: null,
  maxPhotos: null,
  isStart: false,
  files: null,
  key: null,
  active: false,
  photoNote: null,
  tags: [],
})

export default () => Object.assign({}, initialState())
