import { actions as baseActions } from '~/store/BaseStore'

const endpoint = Object.freeze({
  building: '/v1/scoring/building/',
  buildingDetail: '/v1/scoring/building/{buildingId}/',
  buildingItemSheetDetail: '/v1/scoring/building/item-sheet/{itemSheetId}/',
  buildingAreaDetail: '/v1/scoring/building/area/{areaId}/',
  buildingAverage: '/v1/scoring/building/average/',
  quality: '/v1/scoring/quality',
  averageQuality: '/v1/scoring/quality/average/',
  management: '/v1/scoring/management',
  averageManagement: '/v1/scoring/management/average',
  task: '/v1/scoring/task',
  averageTask: '/v1/scoring/task/average/',
  overview: '/v1/scoring/overview',
  average: '/v1/scoring/average',
  defects: '/v1/scoring/defects',
  defectRateHistory: '/v1/scoring/defect-rate/history',
  revisions: '/v1/scoring/revisions',
  revisionRateHistory: '/v1/scoring/revision-rate/history',
  itemRates: '/v1/scoring/item-rates',
  itemsFirstInspectionFiles: '/v1/scoring/items/{itemType}/{itemId}/first-inspection-files',
  itemsBuildingResults: '/v1/scoring/items/{itemType}/{itemId}/building-results',
  buildingRates: '/v1/scoring/building-rates',
  buildingsItemResults: '/v1/scoring/buildings/{buildingId}/item-results',
  rateHistory: '/v1/scoring/rate/history',
})

export default Object.assign({}, baseActions, {
  fetchBuildingScore (_, params) {
    return this.$_api.get({ path: endpoint.building, query: params })
  },
  fetchBuildingScoreDetail (_, { id, params }) {
    const path = endpoint.buildingDetail.replace('{buildingId}', id)
    return this.$_api.get({ path, query: params })
  },
  fetchBuildingScoreItemSheet (_, { id, params }) {
    const path = endpoint.buildingItemSheetDetail.replace('{itemSheetId}', id)
    return this.$_api.get({ path, query: params })
  },
  fetchBuildingScoreArea (_, { id, params }) {
    const path = endpoint.buildingAreaDetail.replace('{areaId}', id)
    return this.$_api.get({ path, query: params })
  },
  fetchBuildingAverageScore (_, params) {
    return this.$_api.get({ path: endpoint.buildingAverage, query: params })
  },
  fetchQualityScore (_, params) {
    return this.$_api.get({ path: endpoint.quality, query: params })
  },
  fetchAverageQualityScore (_, params) {
    return this.$_api.get({ path: endpoint.averageQuality, query: params })
  },
  fetchManagementScore (_, params) {
    return this.$_api.get({ path: endpoint.management, query: params })
  },
  fetchAverageManegement (_, params) {
    return this.$_api.get({ path: endpoint.averageManagement, query: params })
  },
  fetchTaskScore (_, params) {
    return this.$_api.get({ path: endpoint.task, query: params })
  },
  fetchAverageTaskScore (_, params) {
    return this.$_api.get({ path: endpoint.averageTask, query: params })
  },
  fetchOverview (_, params) {
    const query = {
      area_id: params.area_id,
      item_sheet_id: params.item_sheet_id,
      include_in_progress: params.include_in_progress,
      start_month: params.start_month,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.overview, query })
  },
  fetchAverage (_, params) {
    const query = {
      include_in_progress: params.include_in_progress,
      start_month: params.start_month,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.average, query })
  },
  fetchDefects (_, params) {
    const query = {
      area_id: params.area_id,
      item_sheet_id: params.item_sheet_id,
      include_in_progress: params.include_in_progress,
      start_month: params.start_month,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.defects, query })
  },
  fetchDefectRateHistory (_, params) {
    const query = {
      area_id: params.area_id,
      item_sheet_id: params.item_sheet_id,
      include_in_progress: params.include_in_progress,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.defectRateHistory, query })
  },
  fetchRevisions (_, params) {
    const query = {
      area_id: params.area_id,
      item_sheet_id: params.item_sheet_id,
      include_in_progress: params.include_in_progress,
      start_month: params.start_month,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.revisions, query })
  },
  fetchRevisionRateHistory (_, params) {
    const query = {
      area_id: params.area_id,
      item_sheet_id: params.item_sheet_id,
      include_in_progress: params.include_in_progress,
      end_month: params.end_month,
    }
    return this.$_api.get({ path: endpoint.revisionRateHistory, query })
  },
  fetchItemRates (_, params) {
    return this.$_api.get({ path: endpoint.itemRates, query: params })
  },
  fetchItemsFirstInspectionFiles (_, { itemType, itemId, params }) {
    const path = endpoint.itemsFirstInspectionFiles.replace('{itemType}', itemType).replace('{itemId}', itemId)
    return this.$_api.get({ path, query: params })
  },
  fetchItemsBuildingResults (_, { itemType, itemId, params }) {
    const path = endpoint.itemsBuildingResults.replace('{itemType}', itemType).replace('{itemId}', itemId)
    return this.$_api.get({ path, query: params })
  },
  fetchBuildingRates (_, params) {
    return this.$_api.get({ path: endpoint.buildingRates, query: params })
  },
  fetchBuildingsItemResults (_, { buildingId, params }) {
    const path = endpoint.buildingsItemResults.replace('{buildingId}', buildingId)
    return this.$_api.get({ path, query: params })
  },
  fetchRateHistory (_, params) {
    return this.$_api.get({ path: endpoint.rateHistory, query: params })
  },
})
