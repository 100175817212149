/**
 * 施工詳細state 管理
 * asyncDataイベントでloadItemを呼び出してください。
 * 項目書の基本情報だけがロードされます。
 * fetchイベントなどでasyncLoadProcessDetailを呼び出してください。
 * 工程の詳細情報がロードされます。
 * loadProcessDetail は同期処理で全部読み込む必要がある時に利用してください。
 */

import { _ as $_ } from 'vue-underscore'
import { captureException } from '@sentry/browser'
import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  itemSheet: '/v1/building/{buildingId}/construction/item-sheet/',
  itemsEvidences: '/v1/building/{buildingId}/process/{itemSheetProcessId}/result-item-evidences/',
  resultItems: '/v1/building/{buildingId}/process/{itemSheetProcessId}/result-items/',
  inspections: '/v1/building/{buildingId}/inspections/',
  basic: '/v1/building/{buildingId}/process/{itemSheetProcessId}/basic',
  detail: '/v1/building/{buildingId}/process/{itemSheetProcessId}/detail',
})

const initialState = () => ({
  buildingId: null,
  processIndex: null,
  itemSheetProcessId: null,
  item: null,
  processDetail: null,
  inspections: [],
  itemResults: [],
  itemEvidences: [],
  files: null,
  defect: null,
  processLoading: false,
  basic: null,
  detail: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getBasic: state => state.basic,
  getBasicItemSheetProcess: (_, getters) => getters.getBasic.item_sheet_process,
  getBasicInspections: (_, getters) => getters.getBasic.inspections,
  getBasicDesignFiles: (_, getters) => getters.getBasic.design_files,
  getBasicAssignees: (_, getters) => getters.getBasic.assignees,
  getBasicDefect: (_, getters) => getters.getBasic.defect,
  getDetail: state => state.detail,
  getBuildingId: state => state.buildingId,
  getItemSheetProcessId: state => state.itemSheetProcessId,
  getSheetName: state => state.item.name,
  getProcesses: state => $_.get(state, ['item', 'processes']),
  getConstructionMethod: state => state.item.construction_method,
  getProcess: (_, getters) => processIndex => $_.get(getters.getProcesses, [processIndex]),
  getProcessIndex: (_, getters) => processId => getters.getProcesses.findIndex((itemSheetProcess) => {
    return itemSheetProcess.process_id === processId
  }),
  findItemSheetProcessId: (state, getters) => (params) => {
    if (Number.isInteger(params.processIndex)) {
      return $_.get(state, ['item', 'processes', params.processIndex, 'id'])
    }
    const processId = Number.parseInt(params.processId)
    if (!processId) {
      return null
    }
    const processIndex = getters.getProcessIndex(processId)
    if (processIndex < 0) {
      return null
    }
    return $_.get(state, ['item', 'processes', processIndex, 'id'])
  },
  getProcessIndexByItemSheetProcessId: (_, getters) => itemSheetProcessId => getters.getProcesses.findIndex((itemSheetProcess) => {
    return itemSheetProcess.item_sheet_inspections.find(itemSheetInspection =>
      itemSheetInspection.item_sheet_process_id === itemSheetProcessId
    )
  }),
  getProcessLoading: state => state.processLoading,
  getProcessDetail: state => state.processDetail,
  getSheetProcessByProcessId: state => processId => state.item.item_sheet_processes.find(p => p.process_id === processId),
  getInspections: state => state.inspections,
  getInspection: state => (itemSheetInspectionId) => {
    return $_.findWhere(state.inspections, { item_sheet_inspection_id: itemSheetInspectionId })
  },
  getItemSheet: state => state.item,
  getItemResultsBySheetItemId: state => (sheetItemId) => {
    return state
      .itemResults
      .filter(itemResult => itemResult.item_sheet_item_id === sheetItemId)
  },
  getItemResults: state => (itemSheetItemId, inspectionId) => {
    return $_.where(state.itemResults, {
      inspection_id: inspectionId,
      item_sheet_item_id: itemSheetItemId
    })
  },
  getItemEvidences: state => (itemSheetItemId, inspectionId) => {
    return $_.where(state.itemEvidences, {
      inspection_id: inspectionId,
      item_sheet_item_id: itemSheetItemId
    })
  },
  getFiles: state => state.files,
  getItemAppendixFilesByStandardItemId: state => (itemId) => {
    if (!state.files) {
      return null
    }
    return state.files.filter(file => file.type === 'item_appendix' &&
      file.attribute.item_appendixes.some(ia => ia.standard_item_id === itemId))
  },
  getRegulationFiles: (_, getters) => (standardItemId) => {
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemId)
    if (!itemAppendixFiles) {
      return null
    }
    return itemAppendixFiles.filter(file => file.attribute.item_appendixes.some(
      ia => ia.type === 'regulation' && standardItemId === ia.standard_item_id))
  },
  getBadExampleFiles: state => (standardItemId) => {
    if (!state.files) {
      return null
    }
    return state.files.filter(file => file.type === 'item_bad_example' &&
      file.attribute.item_bad_examples.some(ibe => ibe.standard_item_ids.some(
        id => id === standardItemId
      ))
    )
  },
  getExcellentExampleFiles: (_, getters) => (standardItemId) => {
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemId)
    if (!itemAppendixFiles) {
      return null
    }
    return itemAppendixFiles.filter(file => file.attribute.item_appendixes.some(
      ia => ia.type === 'excellent' && standardItemId === ia.standard_item_id))
  },
  getExcellentExampleFilesByCategoryId: state => (categoryId) => {
    if (!state.files) {
      return null
    }
    return state.files.filter(file =>
      file.type === 'item_category_appendix' &&
      file.attribute.item_category_appendixes.some(
        ica => ica.item_category_id === categoryId
      )
    )
  },
  getLearningMovieFiles: (_, getters) => (standardItemId) => {
    const itemAppendixFiles = getters.getItemAppendixFilesByStandardItemId(standardItemId)
    if (!itemAppendixFiles) {
      return null
    }
    return itemAppendixFiles.filter(file => file.attribute.item_appendixes.some(
      ia => ['learning-movie', 'learning-movie-sp'].includes(ia.type)))
  },

  getDefect: state => state.defect,
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  },
  setBuildingId (state, buildingId) {
    state.buildingId = buildingId
  },
  setProcessIndex (state, processIndex) {
    state.processIndex = processIndex
  },
  setItemSheetProcessId (state, itemSheetProcessId) {
    state.itemSheetProcessId = itemSheetProcessId
  },
  setProcessLoading (state, val) {
    state.processLoading = val
  },
  setProcessDetail (state, processDetail) {
    state.processDetail = processDetail
  },
  setInspections (state, inspections) {
    state.inspections = inspections
  },
  setItemResults (state, itemResults) {
    state.itemResults = itemResults
  },
  setItemEvidences (state, evidences) {
    state.itemEvidences = evidences
  },
  setConstructionItemEvidences (state, evidences) {
    state.itemEvidences = evidences
  },
  setFiles (state, results) {
    state.files = results
  },
  setDefect (state, defect) {
    state.defect = defect
  },
  setBasic (state, data) {
    state.basic = data
  },
  resetBasicDesignFiles (state, data) {
    state.basic.design_files = data
  },
  resetBasicInspections (state, data) {
    state.basic.inspections = data
  },
  setDetail (state, data) {
    state.detail = data
  },
})

export const actions = Object.assign({}, baseActions, {
  async fetchBasic (_, { buildingId, itemSheetProcessId }) {
    const path = endpoint.basic.replace('{buildingId}', buildingId).replace('{itemSheetProcessId}', itemSheetProcessId)
    return await this.$_api.get({ path })
  },
  async loadBasic ({ state, dispatch, commit }, { params, force }) {
    const { buildingId, itemSheetProcessId } = params
    if (buildingId !== state.basic?.id || itemSheetProcessId !== state.basic?.item_sheet_process.id) {
      commit('setBasic', null)
    } else if (state.basic && !force) {
      return state.basic
    }
    const res = await dispatch('fetchBasic', { buildingId, itemSheetProcessId })
    commit('setBasic', res)
    return res
  },
  async reloadBasicDesignFiles ({ state, dispatch, commit }, { buildingId }) {
    if (!state.basic || state.basic.id !== buildingId) {
      return null
    }

    const res = await dispatch('BuildingFile/fetchDesignFiles', { buildingId }, { root: true })
    commit('resetBasicDesignFiles', res.data)
    return res.data
  },
  async reloadBasicInspections ({ state, dispatch, commit }, { buildingId }) {
    if (!state.basic || state.basic.id !== buildingId) {
      return null
    }

    const conditions = {
      item_sheet_process_id: state.basic.item_sheet_process.id,
    }
    const res = await dispatch('fetchInspections', { buildingId, conditions })
    commit('resetBasicInspections', res.data)
    return res.data
  },
  async fetchDetail (_, { buildingId, itemSheetProcessId }) {
    const path = endpoint.detail.replace('{buildingId}', buildingId).replace('{itemSheetProcessId}', itemSheetProcessId)
    return await this.$_api.get({ path })
  },
  async loadDetail ({ state, dispatch, commit }, { params, force }) {
    commit('setProcessLoading', true)
    const { buildingId, itemSheetProcessId } = params
    if ((buildingId !== state.basic?.id || itemSheetProcessId !== state.basic?.item_sheet_process.id) && !force) {
      commit('setDetail', [])
      return []
    }

    const res = await dispatch('fetchDetail', { buildingId, itemSheetProcessId })
    commit('setDetail', res)
    commit('setProcessLoading', false)

    return res
  },
  async fetchItemSheet (_, buildingId) {
    const path = endpoint.itemSheet.replace('{buildingId}', buildingId)
    return await this.$_api.get({ path })
  },
  fetchInspections (_, { buildingId, conditions }) {
    const path = endpoint.inspections.replace('{buildingId}', buildingId)
    return this.$_api.get({ path, query: conditions })
  },
  async loadItem ({ state, commit, dispatch }, { params, force }) {
    const { buildingId } = params
    if (state.buildingId === buildingId && !force && state.item) {
      return state.item
    }
    if (state.buildingId !== buildingId) {
      commit('setInitialState')
    }
    commit('setBuildingId', buildingId)

    return await dispatch('loadItemSheet', { buildingId, force })
  },

  async loadProcessDetail ({ state, commit, getters, dispatch }, { params, force }) {
    await dispatch('loadItem', { params, force })

    const { buildingId } = params
    let { itemSheetProcessId } = params
    if (isNaN(itemSheetProcessId)) {
      itemSheetProcessId = getters.findItemSheetProcessId(params)
      if (!itemSheetProcessId) {
        return null
      }
    }
    if (!force && state.buildingId === buildingId &&
        state.itemSheetProcessId === itemSheetProcessId &&
        !force && state.processDetail) {
      return null
    }
    commit('setItemSheetProcessId', itemSheetProcessId)
    return dispatch('asyncLoadProcessDetail', {
      params: {
        itemSheetProcessId
      },
      force
    })
  },
  async asyncLoadProcessDetail ({ commit, dispatch }, { params, force }) {
    const { itemSheetProcessId, buildingId } = params
    commit('setProcessLoading', true)
    await Promise.all([
      dispatch('loadItemSheetProcess', { itemSheetProcessId }),
      dispatch('loadInspections', { buildingId, itemSheetProcessId }),
      dispatch('_loadFiles', { itemSheetProcessId }),
    ])
    commit('setProcessLoading', false)
  },

  async loadItemSheet ({ state, dispatch, commit }, { buildingId, force }) {
    if (!force && state.buildingId === buildingId && state.item) {
      return state.item
    }
    const itemSheet = await dispatch('fetchItemSheet', buildingId)
    commit('setItem', itemSheet)
    return itemSheet
  },

  async loadItemSheetProcess ({ state, dispatch, commit }, { itemSheetProcessId }) {
    const itemSheetId = state.item.item_sheet_id
    const itemSheetVersionId = state.item.id

    const res = await dispatch('SheetDetail/fetchSheetProcesses', {
      itemSheetId,
      itemSheetVersionId,
      options: {
        item_sheet_process_id: itemSheetProcessId,
      }
    }, { root: true })

    const processDetail = res.data[0]
    commit('setProcessDetail', processDetail)
  },

  async loadInspections (ctx, { buildingId, itemSheetProcessId }) {
    const { state, commit, dispatch } = ctx
    buildingId = buildingId ?? state.buildingId
    if (!buildingId) {
      const errorMessage = `[found bug] building id is missing. item sheet process id is ${itemSheetProcessId}`
      captureException(new Error(errorMessage))
      console.error(errorMessage) // eslint-disable-line no-console
      return
    }
    const conditions = {
      item_sheet_process_id: itemSheetProcessId,
    }
    const res = await dispatch('fetchInspections', { buildingId, conditions })

    const inspections = res.data
    commit('setInspections', inspections)
    if (res.meta.total_items > inspections.length) {
      console.error('[found bug] inspection found more then 1000') // eslint-disable-line no-console
    }

    const inspectionIds = inspections.map(i => i.id)
    if (itemSheetProcessId) {
      return Promise.all([
        _loadConstructionItemResults(ctx, buildingId, itemSheetProcessId),
        _loadConstructionItemResultsEvidences(ctx, buildingId, itemSheetProcessId),
      ])
    } else {
      return Promise.all([
        _loadInspectionItemResults(ctx, inspectionIds),
        _loadInspectionItemResultsEvidences(ctx, inspectionIds),
      ])
    }
  },

  async loadInspectionsWithoutResults (ctx, { params, force }) {
    const { buildingId, processIndex } = params
    if (!force && state.inspections.length) {
      if (state.buildingId === buildingId && state.processIndex === processIndex) {
        return null
      }
    }
    const { getters, commit, dispatch } = ctx
    const itemSheetProcessId = getters.getProcess(processIndex).id
    // todo: should retreive by item_sheet_process
    const conditions = {
      item_sheet_process_id: itemSheetProcessId,
    }
    const res = await dispatch('fetchInspections', {
      buildingId: state.buildingId,
      conditions
    })

    const inspections = res.data
    commit('setInspections', inspections)
    if (res.meta.total_items > inspections.length) {
      console.error('[found bug] inspection found more then 1000') // eslint-disable-line no-console
    }
    return inspections
  },

  async _loadFiles ({ state, dispatch, commit }, { itemSheetProcessId }) {
    const itemSheetId = state.item.item_sheet_id
    const itemSheetVersionId = state.item.id
    const results = await dispatch('SheetDetail/fetchFiles', {
      itemSheetId,
      itemSheetVersionId,
      conditions: {
        item_sheet_process_id: itemSheetProcessId,
      }
    }, { root: true })
    commit('setFiles', results)
    return results
  },
  // defect
  async _loadDefect ({ state, rootGetters, dispatch, commit }, { force }) {
    if (state.defect && !force) {
      return state.defect
    }
    const defectId = rootGetters['BuildingDetail/getDefectId']
    if (defectId !== null) {
      const defects = await dispatch('Defect/fetchItems', { defectIds: [defectId] }, { root: true })
      const defect = defects.find(defect => Number(defect.defectId) === defectId)
      commit('setDefect', defect)
      return defect
    }
  },
  fetchConstructionItemResults (_, { buildingId, itemSheetProcessId }) {
    const path = endpoint.resultItems
      .replace('{buildingId}', buildingId)
      .replace('{itemSheetProcessId}', itemSheetProcessId)
    return this.$_api.get({ path })
  },
  fetchConstructionItemsEvidences (_, { buildingId, itemSheetProcessId }) {
    const path = endpoint.itemsEvidences
      .replace('{buildingId}', buildingId)
      .replace('{itemSheetProcessId}', itemSheetProcessId)
    return this.$_api.get({ path })
  },
})

const _loadConstructionItemResults = async ({ dispatch, commit }, buildingId, itemSheetProcessId) => {
  commit('setItemResults', [])
  const responses = await dispatch(
    'fetchConstructionItemResults', {
      buildingId,
      itemSheetProcessId
    }
  )
  commit('setItemResults', responses)
}

const _loadConstructionItemResultsEvidences = async ({ dispatch, commit }, buildingId, itemSheetProcessId) => {
  commit('setConstructionItemEvidences', [])
  const responses = await dispatch(
    'fetchConstructionItemsEvidences', {
      buildingId,
      itemSheetProcessId
    }
  )
  commit('setConstructionItemEvidences', responses)
}

const _loadInspectionItemResults = async ({ dispatch, commit }, inspectionIds) => {
  commit('setItemResults', [])
  const promises = inspectionIds.map(inspectionId => dispatch(
    'InspectionDetail/fetchItemsResults',
    inspectionId,
    { root: true }
  ))
  const responses = await Promise.all(promises)
  const itemResults = responses.reduce((itemResults, response) => {
    return itemResults.concat(response)
  }, [])
  commit('setItemResults', itemResults)
}

const _loadInspectionItemResultsEvidences = async ({ dispatch, commit }, inspectionIds) => {
  commit('setItemEvidences', [])
  const promises = inspectionIds.map(inspectionId => dispatch(
    'InspectionDetail/fetchItemsEvidences',
    inspectionId,
    { root: true }
  ))
  const responses = await Promise.all(promises)
  const itemEvidences = responses.reduce((itemEvidences, response) => {
    return itemEvidences.concat(response)
  }, [])
  commit('setItemEvidences', itemEvidences)
}
