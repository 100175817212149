
import { mapGetters } from 'vuex'

import PhotoStudioEditor from 'molecules/PhotoStudioEditor'
import PhotoStudioViewer from 'molecules/PhotoStudioViewer'
export default {
  components: {
    PhotoStudioEditor,
    PhotoStudioViewer,
  },
  data () {
    return {
      mode: null,
      blobs: null,
      images: [],
      confirmDeleteKeepingBlobsDialog: false,
      dialogTitle: '撮影した写真を破棄します。よろしいですか？'
    }
  },
  computed: {
    ...mapGetters({
      samples: 'photoStudio/samples',
      requiredPhotos: 'photoStudio/requiredPhotos',
      maxPhotos: 'photoStudio/maxPhotos',
      isStart: 'photoStudio/isStart',
      photoNote: 'photoStudio/photoNote',
      tags: 'photoStudio/tags',
    }),
    active: {
      get () {
        return this.$store.getters['photoStudio/active']
      },
      set () {
        this.$store.commit('photoStudio/close')
      }
    }
  },
  watch: {
    active (val) {
      if (val) {
        this.show()
      } else {
        this.clear()
      }
    }
  },
  methods: {
    show () {
      this.mode = 'editorMode'
    },
    clear () {
      this.mode = null
      this.$refs.editor.finalize()
    },
    checkKeepingBlobs (keepingblobs) {
      if (keepingblobs.length > 0) {
        this.confirmDeleteKeepingBlobsDialog = true
      } else {
        this._cancel()
      }
    },
    _cancel () {
      this.clear()
      this.$store.dispatch('photoStudio/cancel')
    },
    _done ($event) {
      this.clear()
      this.$store.dispatch('photoStudio/done', $event)
    },
    _gotoViewer (blobs) {
      this.blobs = blobs
      this.mode = 'viewerMode'
    },
    _goBackEditor () {
      this.mode = 'editorMode'
    },
    _photoClick (images) {
      this.images = images
      this.$refs.imagePreview.open()
    },
  }
}
