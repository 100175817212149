
import { mapGetters } from 'vuex'
import { captureException } from '@sentry/browser'

import CameraShutterIcon from '~/assets/img/common/cameraShutterIcon.svg'

import filePresignedUrl from '~/mixins/filePresignedUrl'
import resizeImage from '~/mixins/resizeImage'
import cameraData from '~/plugins/camera-data'
import { CameraError } from '~/store/Stream.js'

const WAIT_CAMERA = 5000

export default {
  mixins: [
    filePresignedUrl,
    resizeImage,
  ],
  props: {
    samples: { type: Array, default: () => [] },
    requiredPhotos: { type: Number, default: null },
    maxPhotos: { type: Number, default: null },
    isStart: { type: Boolean, default: false },
    photoNote: { type: String, default: null },
    show: { type: Boolean, default: false },
  },
  data () {
    return {
      tabs: 'progress',

      cameraShutterIcon: CameraShutterIcon + '#cameraShutterIcon',

      isRendering: false,
      tookPhoto: false,
      renderTimer: 0,

      blobs: [],
      photoIndex: 0,
      uploading: false,
      tempFile: null,
      previewUrl: null,

      devTriggered: 0,
      debug: false,
      debugData: [],
      invalidStream: false,
      invalidWatchTimer: null,
      canplay: false,

      dlog: [],
    }
  },

  fetch () {
    this.captureDebug()
  },
  computed: {
    ...mapGetters({
      stream: 'Stream/getStream',
      recorder: 'Stream/getRecorder',
      isValidStrem: 'Stream/isValid',
    }),
    _ready2next () {
      if (this.tookPhoto) {
        return false
      } else {
        return this.photoIndex >= this.requiredPhotos
      }
    },
    tempFileUrl () {
      if (!this.tempFile) {
        return
      }
      return this.tempFile.dataurl
    },
    photoAspectRatio () {
      return cameraData.config.aspect
    },
  },
  watch: {
    show (val) {
      if (val) {
        this.initialize()
      }
    },
  },
  beforeDestroy () {
    this.finalize()
  },
  mounted () {
    this.$nextTick(function () {
      this.initEventHandler()
      if (this.show) {
        this.initialize()
      }
    })
  },
  methods: {
    initEventHandler () {
      this.$refs.streamContainer.addEventListener('canplay', function (e) {
        this.canplay = true
        this.invalidStream = false
      }.bind(this))
      this.$refs.streamContainer.addEventListener('error', function (e) {
        this.invalidStream = true
        const cameraError = new CameraError(new Error(e))
        captureException(cameraError)
        console.error(cameraError) // eslint-disable-line no-console
      })

      ;['error', 'ended', 'pause', 'play', 'canplay'].forEach(function (eventType) {
        this.$refs.streamContainer.addEventListener(eventType, function (e) {
          this.dlog.push(`video event: ${e.type}`)
        }.bind(this))
      }.bind(this))
    },

    // ________________________
    //  canvas
    captureVideo () {
      const originalWidth = this.$refs.streamContainer.videoWidth
      const originalHeight = this.$refs.streamContainer.videoHeight
      const shortSide = originalWidth / originalHeight < this.photoAspectRatio
        ? 'w'
        : 'h'
      const cropWidth = shortSide === 'w'
        ? originalWidth
        : parseInt(originalHeight * this.photoAspectRatio)
      const cropHeight = shortSide !== 'w'
        ? originalHeight
        : parseInt(originalWidth / this.photoAspectRatio)

      const cropX = (originalWidth - cropWidth) / 2
      const cropY = (originalHeight - cropHeight) / 2

      this.$refs.canvasContainer.width = cropWidth
      this.$refs.canvasContainer.height = cropHeight

      this.$refs.canvasContainer
        .getContext('2d')
        .drawImage(
          this.$refs.streamContainer,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0, 0,
          cropWidth,
          cropHeight,
        )
    },

    // ________________________
    //  camera
    async _keepImage () {
      const type = 'image/jpeg'
      const dataurl = this.$refs.canvasContainer.toDataURL(type)
      const filename = cameraData.config.filename + '.jpg'
      const img = await this.resizeImage(dataurl, filename)
      this.blobs.push({ url: window.URL.createObjectURL(img), data: img })
    },
    // ________________________
    //  dialog
    initialize () {
      this.photoIndex = 0
      this.blobs = []
      this.tookPhoto = false
      this.invalidStream = false
      this.canplay = false

      this.$_startCamera()
    },
    async $_startCamera () {
      try {
        const stream = await this.$store.dispatch(
          'Stream/initializeStream'
        )
        if (this.invalidWatchTimer) {
          clearTimeout(this.invalidWatchTimer)
        }
        this.invalidWatchTimer = setTimeout(function () {
          if (!this.canplay) {
            this.handleCameraError(new Error('Video streaming ran out of time.'))
          }
        }.bind(this), WAIT_CAMERA)

        this.$refs.streamContainer.srcObject = stream
      } catch (e) {
        this.dlog.push(`${e.name}: ${e.message}`)
        this.invalidStream = true
      }
    },
    finalize () {
      this.$store.dispatch('Stream/terminateStream')
      this.photoIndex = 0
      this.blobs = []
      this.devTriggered = 0
    },

    // ________________________
    //  UI

    async _shutter () {
      if (!this.isValidStrem) {
        this.invalidStream = true
        return
      }
      try {
        await this.$store.dispatch('Stream/pauseStream')
      } catch (e) {
        this.invalidStream = true
        return
      }

      this.captureVideo()
      this.previewUrl = this.$_canvas2dataurl()
      if (this.previewUrl === 'data:,') {
        this.invalidStream = true
        return
      }
      this.showPreview()
    },
    _useFile () {
      this.$refs.fileInput.$refs.input.click()
    },
    async _resumeCamera () {
      this.tookPhoto = false
      this.tempFile = null
      try {
        const success = await this.$store.dispatch('Stream/resumeStream')
        if (!success) {
          this.invalidStream = true
        }
      } catch (e) {
        this.invalidStream = true
      }
    },
    async _decision () {
      if (this.tempFile) {
        await this.keepFile()
      } else {
        await this._keepImage()
      }
      this.photoIndex++
      if (this.maxPhotos && this.maxPhotos <= this.photoIndex) {
        this._gotoViewer()
      }
      this._resumeCamera()
    },
    _cancel () {
      this.$emit('cancel', this.blobs)
    },
    _gotoViewer () {
      this.$emit('gotoViewer', this.blobs)
      this.finalize()
    },
    fileSelected () {
      if (!this.tempFile) {
        return
      }
      this.$store.dispatch('Stream/pauseStream')
      this.tempFile.dataurl = window.URL.createObjectURL(this.tempFile)
      this.previewUrl = this.tempFile.dataurl
      this.showPreview()
    },
    async keepFile () {
      const img = await this.resizeImage(this.tempFile.dataurl, this.tempFile.name)
      const blob = {
        url: this.tempFile.dataurl,
        data: img
      }
      this.blobs.push(blob)
      this.tempFile = null
    },
    showPreview () {
      this.devTriggered = 0
      this.tookPhoto = true
    },

    $_canvas2dataurl () {
      const type = 'image/jpeg'
      return this.$refs.canvasContainer.toDataURL(type)
    },

    // debug
    devTrigger () {
      this.devTriggered++
      if (this.devTriggered < 31) {
        return
      }
      this.devTriggered = 0
      this.debug = !this.debug
      this.captureDebug()
    },
    captureDebug () {
      if (!this.debug) {
        return
      }

      setTimeout(function () {
        this.captureDebug()
      }.bind(this), 1000)

      this.debugData = []

      this.debugData.push({
        text: 'date',
        value: new Date()
      })
      this.debugData.push({
        text: 'isLooseCamera',
        value: `${this.$store.getters['Stream/isLooseCamera']}`
      })

      if (!this.$refs.streamContainer) {
        return
      }

      const streamContainer = this.$refs.streamContainer
      this.debugData.push({
        text: 'video size',
        value: `${streamContainer.videoWidth} / ${streamContainer.videoHeight}`
      })
      this.debugData.push({
        text: 'client size',
        value: `${streamContainer.clientWidth} / ${streamContainer.clientHeight}`
      })

      ;['ended', 'paused', 'readyState'].forEach((prop) => {
        this.debugData.push({
          text: `video.${prop}`,
          value: streamContainer[prop]
        })
      })
      this.debugData.push({
        text: 'can play',
        value: `${this.canplay}`
      })

      if (streamContainer.error) {
        this.debugData.push({
          text: 'video.error ',
          value: `${streamContainer.error.code};${streamContainer.error.message}`
        })
      }

      this.captureDebugRecorder()

      this.debugData.push({
        text: 'invalid stream',
        value: this.invalidStream
      })

      if (this.stream) {
        const track = this.stream.getVideoTracks()[0]

        this.debugData.push({
          text: 'track',
          value: track.id
        })
        ;['kind', 'label', 'muted', 'readyState'].forEach((prop) => {
          this.debugData.push({
            text: prop,
            value: track[prop]
          })
        })
      }

      this.dlog.forEach(function (log) {
        this.debugData.push({
          text: 'log',
          value: log
        })
      }.bind(this))
    },

    captureDebugRecorder () {
      const recorder = this.$store.getters['Stream/getRecorder']
      if (!recorder) {
        this.debugData.push({
          text: 'recorder not valid',
          value: `${recorder}`
        })
        return
      }

      this.debugData.push({
        text: 'recorder mimetype',
        value: recorder.mimeType
      })

      this.debugData.push({
        text: 'recorder state',
        value: recorder.state
      })

      if (!this.$refs.streamContainer) {
        return
      }
      this.debugData.push({
        text: 'canPlayType',
        value: this.$refs.streamContainer.canPlayType(recorder.mimeType) ? 'true' : 'false'
      })
    },
    handleCameraError (e) {
      this.dlog.push(`${e.name}: ${e.message}`)
      const cameraError = new CameraError(e)
      captureException(cameraError)
      console.error(cameraError) // eslint-disable-line no-console
      this.invalidStream = true
    }
  }
}
