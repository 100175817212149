import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
  util,
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/buildings/',
  basic: '/v1/building/',
  detail: '/v1/building/{buildingId}/detail',
  duplicate: '/v1/building/{buildingId}/duplicate'
})

const initialState = () => ({
  items: [],
  meta: {},
  params: {},
  currentItem: null,
  currentDetail: null,
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getCurrent: (state) => {
    return {
      building: state.currentItem,
      buildingDetail: state.currentDetail
    }
  },
  getMeta (state) {
    return state.meta
  }
})

export const mutations = Object.assign({}, baseMutations, {
  setCurrent (state, { building, buildingDetail }) {
    state.currentItem = building
    state.currentDetail = buildingDetail
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, conditions) {
    return this.$_api.get({ path: endpoint.list, query: conditions })
  },
  async submit (_, building) {
    const entity = util.toUnderscoreCaseObject(building.entity)

    const createdBuilding = await this.$_api
      .post({ path: endpoint.basic, data: entity })

    return createdBuilding
  },
  async fetchItem (_, buildingId) {
    let path = endpoint.basic + buildingId
    const building = await this.$_api.get({ path })

    path = endpoint.detail
      .replace('{buildingId}', buildingId)
    const buildingDetail = await this.$_api.get({ path })

    return { building, buildingDetail }
  },
  async loadItem ({ state, dispatch, commit }, { buildingId, force }) {
    if (!force && state.currentItem) {
      return
    }
    const res = await dispatch('fetchItem', buildingId)
    commit('setCurrent', res)
    return res
  },
  async fetchBuilding (_, buildingId) {
    return await this.$_api.get({ path: endpoint.basic + buildingId })
  },
  async duplicate (_, { buildingId, params }) {
    const endPoint = endpoint.duplicate.replace('{buildingId}', buildingId)
    return await this.$_api.post({ path: endPoint, data: params })
  },
})
