
import CommonIcon from 'atoms/common/CommonIcon'

export default {
  components: {
    CommonIcon,
  },
  props: {
    icon: { type: String, default: null },
    to: { type: String, default: null },
    nuxt: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
}
