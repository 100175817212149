import { captureException } from '@sentry/browser'

import currentVersion from '~/assets/VERSION.txt'

export default function (context) {
  versionCheck(context)
}

const versionCheck = async (ctx) => {
  const latestVersion = await fetchLatestVersion()
  if (!latestVersion) {
    return
  }
  if (latestVersion === currentVersion) {
    return
  }
  ctx.store.dispatch('app/updateDetected')
}

const fetchLatestVersion = async () => {
  try {
    const res = await fetch('/VERSION.txt', { cache: 'no-store' })
    if (!res.ok) {
      const e = new Error(`failed to fetch ${res.url}`)
      console.error(e) // eslint-disable-line no-console
      captureException(e)
      return null
    }
    const version = await res.text()
    return version
  } catch (e) {
    return null
  }
}
