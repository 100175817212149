import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = '/v1/dictionary/prefectures/'

const initialState = () => ({
  items: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  },
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems (_) {
    return await this.$_api
      .get({ path: endpoint })
  },
})
