import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = '/v1/processes/'

const initialState = () => ({
  items: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getNextOrder: (state) => {
    let nextOrder = 1
    if (state.items.length) {
      nextOrder = Math.max(...state.items.map(item => item.order)) + 1
    }
    return nextOrder
  },
  isDesign: state => (id) => {
    const item = state.items.find(item => item.id === id)
    return item ? item.sequence === 0 : false
  },
})

export const mutations = Object.assign({}, baseMutations, {
  sortProcesses (state) {
    state.items.sort((a, b) => {
      return a.order - b.order
    })
  },
  reOrderItems (state, { items }) {
    let order = 1
    items.forEach((item) => {
      const target = state.items.find(i => i.id === item.id)
      target.order = order++
    })

    state.items.sort((a, b) => {
      return a.order - b.order
    })
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async fetchItems () {
    const items = await this.$_api.get({ path: endpoint })
    return items
  },
})
