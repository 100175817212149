export default {
  active: state => state.active,
  samples: state => state.samples,
  requiredPhotos: state => state.requiredPhotos,
  maxPhotos: state => state.maxPhotos,
  isStart: state => state.isStart,
  isOwnAction: state => key => state.key === key,
  getResults: (state, getters) => key => key && !state.active && getters.isOwnAction(key) ? state.files : null,
  photoNote: state => state.photoNote,
  tags: state => state.tags,
}
