import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  list: '/v1/mypage/user-contacts/',
  update: '/v1/mypage/user-contact/',
})

const initialState = () => ({
  items: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_) {
    return this.$_api.get({ path: endpoint.list })
  },
  updateItem (_, payload) {
    return this.$_api.put({ path: endpoint.update, data: payload })
  },
})
