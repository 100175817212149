
import { mapGetters } from 'vuex'
import CommonNotificationBar from 'atoms/common/CommonNotificationBar'
import CommonModalDialog from 'atoms/common/CommonModalDialog'
import CommonFullWidthButton from 'atoms/common/CommonFullWidthButton'
import CommonIcon from 'atoms/common/CommonIcon'
import CommonModalDialogConfirm from 'atoms/common/CommonModalDialogConfirm'

export default {
  components: {
    CommonNotificationBar,
    CommonModalDialog,
    CommonFullWidthButton,
    CommonIcon,
    CommonModalDialogConfirm,
  },
  data () {
    return {
      dialogRetryConfirm: false,
      dialogRetry: false,
      retried: false,
      running: false,
    }
  },
  fetch () {
    this.$store.dispatch('InspectionQueue/count')
  },
  computed: {
    ...mapGetters({
      queueLength: 'InspectionQueue/length',
      queueLengthBeforeRetry: 'InspectionQueue/lengthBeforeRetry',
      retrying: 'InspectionQueue/running',
    }),
    progressRate () {
      return Math.floor(((this.queueLengthBeforeRetry - this.queueLength) / this.queueLengthBeforeRetry) * 100)
    },
    showProgressDialog () {
      return this.running
    }
  },
  watch: {
    retrying (val) {
      if (val) {
        return
      }
      if (this.running) {
        this.retried = true
      }
      this.running = false
    },
  },
  methods: {
    async _retryQueue () {
      this.running = true
      await this.$store.dispatch('InspectionQueue/retry')
    },
  },
}
