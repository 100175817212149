import moment from 'moment'

const defaultDateFormat = 'YYYY/MM/DD'
const defaultDatetimeFormat = 'YYYY/MM/DD HH:mm'
const defaultDatetimeFormatJp = 'YYYY年MM月DD日 HH:mm'
const defaultMonthDayWeekdayFormat = 'MMMMDo（dd）'
const defaultTimeFormat = 'H:mm'

export const dateformat = (value, format) => {
  if (!value) {
    return ''
  }
  const datetime = moment(value).local()
  if (!format) {
    return datetime.format(defaultDateFormat)
  }
  return datetime.format(format)
}

export const datetimeformat = (value) => {
  if (!value) {
    return null
  }
  return moment(value).local().format(defaultDatetimeFormat)
}

const datetimeformatJp = (value) => {
  if (!value) {
    return null
  }
  return moment(value).local().format(defaultDatetimeFormatJp)
}

export const monthDayWeekdayFormat = (value) => {
  if (!value) {
    return null
  }
  return moment(value).locale('ja').format(defaultMonthDayWeekdayFormat)
}

export const timeFormat = (value) => {
  if (!value) {
    return null
  }
  return moment(value).locale('ja').format(defaultTimeFormat)
}

export const biggerThan = (prev, current) => {
  return moment(prev).isBefore(current, 'month')
}

export default {
  methods: {
    dateformat,
    datetimeformat,
    datetimeformatJp,
    monthDayWeekdayFormat,
    timeFormat,
    biggerThan,
  }
}
