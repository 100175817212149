
import datetimeformat from '~/mixins/dateformat'

export default {
  mixins: [
    datetimeformat
  ],
  props: {
    // see https://qualiz.esa.io/posts/53#%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%20CommonImagePreview.vue
    imageList: { type: Array, default: () => [] },
    value: { type: Boolean, default: false },
    initIndex: { type: Number, default: 0, }
  },
  data () {
    const index = this.initIndex
    return {
      index,
      dialog: false,
    }
  },
  computed: {
    images: {
      get () {
        return this.imageList ? this.imageList : []
      },
    },
    imageListSize () {
      return this.imageList?.length
    },
    prevBtnEnabled () {
      return this.index !== 0
    },
    nextBtnEnabled () {
      return this.index < this.imageListSize - 1
    },
    tags () {
      return this.images[this.index] ? this.images[this.index].tags : []
    },
    comment () {
      return this.images[this.index] ? this.images[this.index].comment : ''
    },
    date () {
      const d = this.imageList?.[this.index]?.date
      if (!d) {
        return ''
      }
      return `アップロード日：${this.datetimeformat(d)}`
    },
    source () {
      return this.images[this.index] ? this.images[this.index].src : ''
    },
    originalFileUrl () {
      return this.images[this.index]?.originalFileUrl
    },
  },
  watch: {
    dialog (updated, prev) {
      if (!updated && prev) {
        this.$emit('close')
      }
    },
    value (val) {
      if (val) {
        if (!this.dialog) {
          this.open()
        }
        return
      }
      if (this.dialog) {
        this.$_close()
      }
    },
  },
  methods: {
    open () {
      this.dialog = true
      this.index = this.initIndex
    },
    openByIndex (i) {
      this.dialog = true
      this.index = i
    },
    $_close () {
      this.dialog = false
      this.$emit('input', false)
      this.$emit('update:value', false) // for vue3
    },
    $_prev () {
      if (!this.prevBtnEnabled) {
        return
      }
      this.index--
    },
    $_next () {
      if (!this.nextBtnEnabled) {
        return
      }
      this.index++
    },
  }
}
