import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const apiName = 'service'
const endpoint = '/v1/dashboard/count-completed-tasks'

const initialState = () => ({
  today: {},
  past: [],
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getToday: state => state.today,
  getPast: state => state.past,
})

export const mutations = Object.assign({}, baseMutations, {
  setData (state, data) {
    state.today = data.today
    state.past = data.past
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  async load ({ commit }, conditions) {
    commit('setData', await this.$_api.get({ apiName, path: endpoint, query: conditions }))
  },
})
