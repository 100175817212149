import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endpoint = Object.freeze({
  inspections: '/v1/inspections/',
  inspectionScheduleConstruction: '/v1/inspection-schedules/{buildingId}/construction/',
  booking: '/v1/inspection/{inspectionId}/booking/',
})

const initialState = () => ({
  items: [],
  meta: {},
  params: {},
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getTotalPages: state => state.meta.total_pages,
  getTotalItems: state => state.meta.total_items,
  getCurrentPage: state => state.meta.current_page,
  byMonth: state => (target) => {
    // TODO: make date range uging yearMonth
    // console.log(target)
    return state.items.filter((_) => {
      return true
    })
  },
  byRange: state => (start, end) => {
    return state.items.filter((val) => {
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      return min <= val.booked && val.booked <= max
    })
  },
  getByBuildingProcess: state => (buildingId, processId) => {
    if (!state.items) {
      return null
    }
    return state.items.filter((item) => {
      return item.building.id === buildingId && item.process.id === processId
    })
  },
})

export const mutations = Object.assign({}, baseMutations, {
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItems (_, params) {
    return this.$_api.get({ path: endpoint.inspections, query: params })
  },
  booking (_, { inspectionId, entity }) {
    const path = endpoint.booking.replace(/\{inspectionId\}/, inspectionId)
    return this.$_api.put({ path, data: entity })
  },
})
