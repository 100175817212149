
import CommonLargeButtonPrimary from 'atoms/common/CommonLargeButtonPrimary'
import CommonLargeButton from 'atoms/common/CommonLargeButton'

export default {
  components: {
    CommonLargeButtonPrimary,
    CommonLargeButton,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    _negative () {
      this.$emit('input', false)
      this.$emit('cancel')
    },
    _positive () {
      this.$emit('input', false)
      this.$emit('ok')
    }
  }
}
