
import CommonButton from 'atoms/common/CommonButton'
import CommonIcon from 'atoms/common/CommonIcon'

export default {
  components: {
    CommonButton,
    CommonIcon,
  },
  props: {
    label: { type: String, default: '登録' },
    to: { type: String, default: null },
    large: { type: Boolean, default: true },
    width: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    click (e) {
      this.$emit('click', e)
    }
  },
}
