const initialState = () => ({
  breadcrumbs: [],
  registerTo: null,
  title: null,
  arrowLink: null,
  buttons: null,
  hideNews: false,
  notifications: [],
})

export const state = () => initialState()

export const getters = {
  get: state => state,
  getBreadcrumbs: state => state.breadcrumbs,
  getRegisterTo: state => state.registerTo,
  getArrowLink: state => state.arrowLink,
  getButtons: state => state.buttons,
  hideNews: state => state.hideNews,
  getNotifications: state => state.notifications,
}

export const mutations = {

  set (state, { breadcrumbs, registerTo, title, arrowLink, buttons, hideNews, notifications }) {
    state.breadcrumbs = breadcrumbs
    state.registerTo = registerTo
    state.title = title
    state.arrowLink = arrowLink
    state.buttons = buttons
    state.hideNews = hideNews
    state.notifications = notifications
  },
  setBreadcrumbs (state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  setButtons (state, buttons) {
    state.buttons = buttons
  },
  setNews (state, hideNews) {
    state.hideNews = hideNews
  },
  setNotifications (state, notifications) {
    state.notifications = notifications
  },
  setTitle (state, title) {
    state.title = title
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  },
}
