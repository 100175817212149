
import { mapGetters } from 'vuex'
import UserNotificationContainer from 'organisms/UserNotificationContainer'
import AccountMenu from 'organisms/AccountMenu'
import allMenu from '~/assets/json/navigationMenu'

import CommonRoleLabel from '~/components/atoms/common/CommonRoleLabel'
import CommonIcon from '~/components/atoms/common/CommonIcon'

export default {
  components: {
    UserNotificationContainer,
    AccountMenu,
    CommonRoleLabel,
    CommonIcon
  },
  data () {
    return {
      drawer: null,
      drawerWidth: 272,
      mini: true,
      isExpandsMenus: [],
      isExpandsSubMenus: {},
      activeMenuId: null,
      allMenu,
    }
  },
  computed: {
    ...mapGetters({
      name: 'Auth/getName',
      role: 'Auth/getRole',
      clientName: 'Auth/getClientName',
      isNs: 'Auth/isNs',
    }),
    clientNameSama () {
      return this.clientName ? this.clientName + ' 様' : null
    },
    topPage () {
      switch (this.role) {
        case 'admin':
          return '/dashboard/'
        case 'assignee':
        case 'designer':
        case 'qm':
        case 'workmen':
        default:
          return '/'
      }
    },
    currentRole () {
      if (this.role) {
        return this.role
      }
      if (this.isNs) {
        return 'auditor'
      }
      return 'default'
    },
    menu () {
      return this.allMenu[this.currentRole]
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  mounted () {
    this.mini = !this.isMobile
    this.isExpandsMenus = new Array(this.menu.length)

    this.menu.forEach((menu) => {
      if (menu.sub) {
        menu.sub.forEach((subMenu) => {
          if (subMenu.sub) {
            this.isExpandsSubMenus[subMenu.key] = false
          }
        })
      }
    })
  },
  methods: {
    $_openMenu () {
      this.mini = false
      if (this.activeMenuId !== -1) {
        this.isExpandsMenus[this.activeMenuId] = true
      }
    },
    $_closeMenu () {
      this.activeMenuId = this.isExpandsMenus.findIndex(value => value === true)
      for (const key in this.isExpandsSubMenus) {
        this.isExpandsSubMenus[key] = false
      }
      this.isExpandsMenus.fill(false)
      this.mini = true
    },
    clickMenuGroup () {
      for (const key in this.isExpandsSubMenus) {
        this.isExpandsSubMenus[key] = false
      }
    },
    clickSubMenuGroup (key) {
      this.isExpandsSubMenus[key] = !this.isExpandsSubMenus[key]
    },
  }
}
