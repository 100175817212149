
import CommonBreadcrumbs from 'atoms/common/CommonBreadcrumbs'
export default {
  components: {
    CommonBreadcrumbs,
  },
  props: {
    title: { type: String, default: null },
    arrowLink: { type: String, default: null },
    breadcrumbs: { type: [Array], default () { return [] } },
    isRegisterButton: { type: Boolean, default: true },
    registerTo: { type: String, default: null },
    buttons: { type: Array, default: null }
  },
  computed: {
    headerButtons () {
      const buttons = []
      if (this.registerTo) {
        buttons.push({
          title: '新規作成',
          icon: 'Add16',
          primary: true,
          to: this.registerTo
        })
      }
      if (this.buttons) {
        buttons.push(...this.buttons)
      }
      return buttons
    }
  },
  methods: {
    click (button) {
      if (button.click) {
        button.click()
      }
    }
  }
}
