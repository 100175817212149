import {
  getters as baseGetters,
} from './BaseStore'

const items = [
  { id: 1, name: '耐震性能' },
  { id: 2, name: '防火性能' },
  { id: 3, name: '耐久性能' },
  { id: 4, name: '維持管理性能' },
  { id: 5, name: '断熱・省エネ性能' },
  { id: 6, name: '空気環境' },
  { id: 7, name: '快適性能(採光等)' },
  { id: 8, name: '防音性能' },
  { id: 9, name: 'バリアフリー性能' },
  { id: 10, name: '防犯性能' },
]

export const state = () => ({
  items,
})

export const getters = Object.assign({}, baseGetters)
