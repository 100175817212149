//
// ビルダー限定ページのアクセス制御
//
export default function ({ route, store, redirect }) {
  if (route.path.match(/^\/auth\//)) {
    return
  }

  if (!store.getters['Auth/getRole'] && store.getters['Auth/isNs']) {
    switch (route.name) {
      // 対象：/building -> /list-buildings
      case 'building':
        redirect('/list-buildings/')
        break
      // 対象：/dashboard -> /
      case 'dashboard':
        redirect('/')
        break
      // 対象：/inspection -> /list-inspections
      case 'inspection':
        redirect('/list-inspections')
        break
    }
  }
}
