

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 640,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    _input (e) {
      this.$emit('input', e)
    }
  }
}
