
import CommonIcon from 'atoms/common/CommonIcon'
export default {
  components: {
    CommonIcon,
  },
  props: {
    type: { type: String, default: null },
    icon: { type: String, default: null },
    color: { type: String, default: '' },
    to: { type: String, default: '' },
    outlined: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hoverDisabled: { type: Boolean, default: false },
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
