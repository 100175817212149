

export default {
  props: {
    blobs: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
  },
  computed: {
    imageList () {
      return this.blobs.reduce((all, blob) => {
        const imageData = {
          src: blob.url,
          originalFileUrl: blob.url,
          comment: blob.data.name + ' ' + blob.data.memo,
        }
        return all.concat(imageData)
      }, [])
    },
  },
  methods: {
    _goBackEditor () {
      this.$emit('goBackEditor')
    },
    _done () {
      this.$emit('done', { files: this.blobs.map(blob => blob.data) })
    },
    _photoClick () {
      this.$emit('photoClick', this.imageList)
    },
  }
}
