import $_ from 'underscore'
import { set } from 'vue'

import {
  getters as baseGetters,
  mutations as baseMutations,
  actions as baseActions,
} from './BaseStore'

const endPoint = {
  read: '/v1/item-sheet/{itemSheetId}/',
  versionDetail: '/v1/item-sheet/{itemSheetId}/version/{itemSheetVersionId}/',
  makeDraft: '/v1/item-sheet/{itemSheetId}/v/{version}/',
  sheetProcesses: '/v1/item-sheet/{itemSheetId}/version/{itemSheetVersionId}/processes/',
  complete: '/v1/item-sheet/{itemSheetId}/version/{itemSheetVersionId}/complete',
  state: '/v1/item-sheet/{itemSheetId}/version/{itemSheetVersionId}/status/',
  files: '/v1/item-sheet/{itemSheetId}/version/{itemSheetVersionId}/files',
  clientItem: '/v1/item-sheet/{itemSheetId}/process/{itemSheetProcessId}/inspection-sequence/{itemSheetInspectionSequence}/client-item/{clientItemId}',
}

const itemTypeClient = 'clientItem'

const initialState = () => ({
  items: [],
  item: {
    // FIXME: delete
    item_sheet_processes: []
  },
  processDetailes: {},
  meta: {},
  params: {},
  files: [],
  sheetProcessesSheetCategories: [],
  currentId: null,
  sheetProcessesLoading: false
})

export const state = () => initialState()

export const getters = Object.assign({}, baseGetters, {
  getItemSheet: state => state.item,
  getItemSheetProcesses: state => $_.get(state, ['item', 'item_sheet_processes']),
  getItemSheetProcessDetail: state => itemSheetProcessId => $_.get(state.processDetailes, [itemSheetProcessId, 'data']),
  isLoadingItemSheetProcessDetail: state => itemSheetProcessId => !!$_.get(state.processDetailes, [itemSheetProcessId, 'loading']),
  getItemSheetProcessByIndex: state => index => $_.get(state, ['item', 'item_sheet_processes', index]),
  getSheetProcessesLoading: state => state.sheetProcessesLoading,
  getAllCategories: (state) => {
    return state.item.item_sheet_processes.reduce((categories, process) => {
      categories.push(...process.item_sheet_categories)
      return categories
    }, [])
  },
  getProcess: state => processIndex => $_.get(state, ['item', 'item_sheet_processes', processIndex]),
  getFilesByStandardItemId: state => (standardItemId) => {
    return state.files
      .filter(file => file.attribute.item_appendixes &&
          file.attribute.item_appendixes.some(ia => ia.standard_item_id === standardItemId))
  },
  getFilesByCategoryId: state => (categoryId) => {
    return state.files
      .filter(file => file.attribute.item_category_appendixes &&
        file.attribute.item_category_appendixes.some(ica => ica.item_category_id === categoryId))
  },
  getRegulationFileByStandardItemId: (_, getters) => (standardItemId) => {
    return getters.getFilesByStandardItemId(standardItemId)
      .find(file => file.attribute.item_appendixes.some(ia => ia.type === 'regulation'))
  },
  getExampleFilesByStandardItemId: (_, getters) => (standardItemId) => {
    return getters.getFilesByStandardItemId(standardItemId)
      .filter(file => file.attribute.item_appendixes.some(ia => ia.type === 'example'))
  },
  getLearningMovieFilesByStandardItemId: (_, getters) => (standardItemId) => {
    return getters.getFilesByStandardItemId(standardItemId)
      .filter(file => file.attribute.item_appendixes
        .some(ia => ['learning-movie', 'learning-movie-sp'].includes(ia.type)))
  },
  getBadFilesByStandardItemId: state => (standardItemId) => {
    if (!state.files) {
      return null
    }
    return state.files.filter(file => file.type === 'item_bad_example' &&
      file.attribute.item_bad_examples.some(ibe => ibe.standard_item_ids.some(
        id => id === standardItemId
      ))
    )
  },
  getExcellentFilesByStandardItemId: (_, getters) => (standardItemId) => {
    return getters.getFilesByStandardItemId(standardItemId)
      .filter(file => file.attribute.item_appendixes.some(
        ia => ia.type === 'excellent' && standardItemId === ia.standard_item_id))
  },
  getExcellentsFilesByCategoryId: (_, getters) => (categoryId) => {
    return getters.getFilesByCategoryId(categoryId)
      .filter(f => f.attribute.item_category_appendixes.some(ica => ica.type === 'excellent'))
  },
  getClientItemCategory: (_, getters) => (processIndex) => {
    const process = getters.getProcess(processIndex)
    if (!process) {
      return null
    }
    const sheetProcess = getters.getItemSheetProcessDetail(process.id)
    if (!sheetProcess) {
      return null
    }

    const categories = sheetProcess.item_sheet_categories
    if (!categories) {
      return null
    }
    return categories.find(category => !category.item_category_id)
  },
  findItemSheetItem: state => (processIndex, type, itemId) => {
    const itemSheetCategories = $_.get(state.item, [
      'item_sheet_processes',
      processIndex,
      'item_sheet_categories'
    ])
    if (!itemSheetCategories) {
      return null
    }
    let itemSheetItem
    itemSheetCategories.find((itemSheetCategory) => {
      itemSheetItem = itemSheetCategory.item_sheet_items
        .find((itemSheetItem) => {
          return itemSheetItem.type === type &&
            itemSheetItem.item_id === itemId
        })
      return !!itemSheetItem
    })
    return itemSheetItem
  },
  findClientItemSheetItem: (_, getters) => (processIndex, itemId) => {
    return getters.findItemSheetItem(processIndex, itemTypeClient, itemId)
  },
  getProcessIndexByInspection: state => (itemSheetInspection) => {
    const sheetProcesses = $_.get(state.item, [
      'item_sheet_processes'
    ])
    if (!sheetProcesses) {
      return null
    }
    return sheetProcesses
      .findIndex((sheetProcess) => {
        return sheetProcess.item_sheet_inspections.some((isi) => {
          return isi.id === itemSheetInspection.id
        })
      })
  }
})

export const mutations = Object.assign({}, baseMutations, {
  reOrderItems (state, { items }) {
    let order = 1
    items.forEach((item) => {
      const target = state.items.find(i => i.id === item.id)
      target.order = order++
    })

    state.items.sort((a, b) => {
      if (a.itemSheetId !== b.itemSheetId) {
        return a.itemSheetId - b.itemSheetId
      }
      return a.order - b.order
    })
  },
  setItem (state, item) {
    state.item = Object.assign(state.item, item)
  },
  // FIXMME: delete
  setSheetProcesses (state, sheetProcesses) {
    set(state.item, 'item_sheet_processes', sheetProcesses)
    state.item.item_sheet_processes.splice()
  },
  // FIXME: delete
  setSheetProcessesLoading (state, loading) {
    state.sheetProcessesLoading = loading
  },
  setProcessDetail (state, { itemSheetProcessId, itemSheetProcess }) {
    set(state.processDetailes, itemSheetProcessId, { data: itemSheetProcess })
  },
  setProcessDetailLoading (state, { itemSheetProcessId }) {
    set(state.processDetailes, itemSheetProcessId, { loading: true })
  },
  setFiles (state, files) {
    state.files = files
  },

  removeClientItem (state, { processIndex, clientItemId }) {
    const itemSheetCategories = $_.get(state.item, [
      'item_sheet_processes',
      processIndex,
      'item_sheet_categories',
    ])
    if (!itemSheetCategories) {
      return
    }
    const categoryIndex = itemSheetCategories.findIndex(itemSheetCategory => !itemSheetCategory.item_category_id)
    if (categoryIndex < 0) {
      return
    }
    const itemIndex = itemSheetCategories[categoryIndex].item_sheet_items.findIndex((itemSheetItem) => {
      return itemSheetItem.item_id === clientItemId && itemSheetItem.type === 'clientItem'
    })
    if (itemIndex < 0) {
      return
    }

    itemSheetCategories[categoryIndex].item_sheet_items.splice(itemIndex, 1)
    if (!itemSheetCategories[categoryIndex].item_sheet_items.length) {
      itemSheetCategories.splice(categoryIndex, 1)
    }

    set(state.item.item_sheet_processes[processIndex].item_sheet_categories,
      'item_sheet_categories', itemSheetCategories)
  },
  toggleInspectionItem (state, { processIndex, itemSheetInspection, itemSheetItem }) {
    const itemSheetInspections = $_.get(state.item, [
      'item_sheet_processes',
      processIndex,
      'item_sheet_inspections',
    ])
    const inspectionindex = itemSheetInspections.indexOf(itemSheetInspection)

    const inspectionItemIndex = itemSheetInspection
      .item_sheet_inspection_items
      .findIndex((inspectionItem) => {
        return (!!itemSheetItem.id && inspectionItem.item_sheet_item_id === itemSheetItem.id) ||
          (
            inspectionItem.type === itemSheetItem.type &&
            inspectionItem.item_id === itemSheetItem.item_id
          )
      })
    if (inspectionItemIndex < 0) {
      itemSheetInspection.item_sheet_inspection_items.push({
        item_sheet_item_id: itemSheetItem.id,
        type: itemSheetItem.type,
        item_id: itemSheetItem.item_id,
      })
    } else {
      itemSheetInspection.item_sheet_inspection_items.splice(inspectionItemIndex, 1)
    }
    set(state.item.item_sheet_processes[processIndex].item_sheet_inspections,
      inspectionindex, itemSheetInspection)
  },
  setInitialState (state) {
    Object.assign(state, initialState())
  }
})

export const actions = Object.assign({}, baseActions, {
  fetchItem (_, { itemSheetId, itemSheetVersionId, options }) {
    let path = endPoint.read.replace(/\{itemSheetId\}/, itemSheetId)
    if (itemSheetVersionId) {
      path = path + 'version/' + itemSheetVersionId
    }
    return this.$_api.get({ path, query: options })
  },
  fetchSheetProcesses (_, { itemSheetId, itemSheetVersionId, options }) {
    const path = endPoint.sheetProcesses.replace(/\{itemSheetId\}/, itemSheetId)
      .replace(/\{itemSheetVersionId\}/, itemSheetVersionId)
    return this.$_api.get({ path, query: options })
  },
  async loadSheetProcess ({ getters, dispatch, commit }, { itemSheetId, itemSheetVersionId, itemSheetProcessId }) {
    if (getters.isLoadingItemSheetProcessDetail(itemSheetProcessId)) {
      return
    }
    if (getters.getItemSheetProcessDetail(itemSheetProcessId)) {
      return
    }
    commit('setProcessDetailLoading', { itemSheetProcessId })
    const options = {
      item_sheet_process_id: itemSheetProcessId,
      limit: 1
    }
    try {
      const { data } = await dispatch('fetchSheetProcesses', {
        itemSheetId, itemSheetVersionId, options
      })
      const itemSheetProcess = data[0]
      commit('setProcessDetail', { itemSheetProcessId, itemSheetProcess })
    } catch (e) {
      commit('setProcessDetail', { itemSheetProcessId, itemSheetProcess: null })
    }
  },
  // FIXME: delete
  async loadAllSheetProcesses ({ dispatch, commit, rootGetters }, { itemSheetId, itemSheetVersionId }) {
    commit('setSheetProcessesLoading', true)
    commit('setSheetProcesses', [])
    const limit = 5
    const packetCount = Math.ceil(rootGetters['ItemSheet/getItem'].item_sheet_processes_count / limit)
    const packets = [...new Array(packetCount)].map((_, i) => dispatch(
      'fetchSheetProcesses', Object.assign(
        { itemSheetId, itemSheetVersionId },
        { options: { limit, page: i + 1 } },
      )
    ))
    const contents = await Promise.all(packets)
    const sheetProcesses = contents.reduce((sheetProcesses, content) => {
      return sheetProcesses.concat(content.data)
    }, [])
    commit('setSheetProcesses', sheetProcesses)
    commit('setSheetProcessesLoading', false)
    return sheetProcesses
  },

  fetchFiles (_, { itemSheetId, itemSheetVersionId, conditions }) {
    const path = endPoint.files.replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetVersionId}', itemSheetVersionId)
    return this.$_api.get({ path, query: conditions })
  },
  async loadFiles ({ dispatch, commit }, { params }) {
    commit('setFiles', [])
    const files = await dispatch('fetchFiles', params)
    commit('setFiles', files)
  },
  async loadProcessFiles ({ dispatch, commit }, params) {
    const { conditions, ...ids } = params
    const { itemSheetProcessId, types } = conditions
    if (!itemSheetProcessId) {
      return null
    }
    commit('setFiles', [])
    let files = []
    if (Array.isArray(conditions.types)) {
      const promises = types.map((type) => {
        return dispatch('fetchFiles', {
          ...ids,
          conditions: { item_sheet_process_id: itemSheetProcessId, type }
        })
      })
      const data = await Promise.all(promises)
      data.forEach(file => files.push(...file))
    } else {
      files = await dispatch('fetchFiles', {
        ...ids,
        condition: { item_sheet_process_id: itemSheetProcessId }
      })
    }
    commit('setFiles', files)
  },

  // client item
  toggleClientItem ({ getters, commit }, { processIndex, itemSheetInspection, clientItemId }) {
    if (!Number.isInteger(processIndex)) {
      processIndex = getters.getProcessIndexByInspection(itemSheetInspection)
      if (processIndex < 0) {
        // bug
        console.error('not found process of item sheet inspection: ' + itemSheetInspection.name) // eslint-disable-line no-console
        return
      }
    }

    let itemSheetItem = getters.findClientItemSheetItem(processIndex, clientItemId)
    if (!itemSheetItem) {
      itemSheetItem = {
        type: itemTypeClient,
        item_id: clientItemId
      }
      commit('addClientItem', { processIndex, itemSheetItem })
    }
    return commit('toggleInspectionItem', { processIndex, itemSheetInspection, itemSheetItem })
  },

  submitClientItem ({ state }, { itemSheetInspection, clientItemId }) {
    if (!state.item) {
      return
    }
    const itemSheetId = state.item.id
    const itemSheetProcessId = itemSheetInspection.item_sheet_process_id
    const itemSheetInspectionSequence = itemSheetInspection.sequence
    const path = endPoint.clientItem
      .replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetProcessId}', itemSheetProcessId)
      .replace('{itemSheetInspectionSequence}', itemSheetInspectionSequence)
      .replace('{clientItemId}', clientItemId)
    return this.$_api.post({ path })
  },
  deleteClientItem ({ state }, { itemSheetInspection, clientItemId }) {
    if (!state.item) {
      return
    }
    const itemSheetId = state.item.id
    const itemSheetProcessId = itemSheetInspection.item_sheet_process_id
    const itemSheetInspectionSequence = itemSheetInspection.sequence
    const path = endPoint.clientItem
      .replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetProcessId}', itemSheetProcessId)
      .replace('{itemSheetInspectionSequence}', itemSheetInspectionSequence)
      .replace('{clientItemId}', clientItemId)
    return this.$_api.del({ path })
  },
  // create draft version
  submitNewVersion (_, { itemSheetId, version }) {
    const path = endPoint.makeDraft
      .replace(/\{itemSheetId\}/, itemSheetId)
      .replace(/\{version\}/, version)
    return this.$_api.post({ path })
  },
  // delete draft version
  deleteVersion (_, { itemSheetId, itemSheetVersionId }) {
    const path = endPoint.versionDetail
      .replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetVersionId}', itemSheetVersionId)
    return this.$_api.del({ path })
  },

  updateItemSheet ({ state }, entity) {
    const itemSheetId = $_.get(state, ['item', 'id'])
    const itemSheetVersionId = $_.get(state, ['item', 'item_sheet_version_id'])
    if (!itemSheetId || !itemSheetVersionId) {
      return false
    }
    const path = endPoint.complete
      .replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetVersionId}', itemSheetVersionId)
    return this.$_api.put({ path, data: entity })
  },
  fetchStatus (_, { itemSheetId, itemSheetVersionId }) {
    const path = endPoint.state
      .replace('{itemSheetId}', itemSheetId)
      .replace('{itemSheetVersionId}', itemSheetVersionId)
    return this.$_api.get({ path })
  },
})
