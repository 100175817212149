export default {
  methods: {
    dynamicLinks (id) {
      if (!id || !Number.isInteger(id)) {
        return null
      }
      return `https://qualiz.page.link/?link=${this.$config.baseURL}/inspection/${id}&ofl=${this.$config.baseURL}/inspection/force/?id%3D${id}&apn=jp.co.qualiz.app&ibi=jp.co.qualiz.app&isi=6468764616&efr=1`
    },
  }
}
