
import CommonModalDialog from 'atoms/common/CommonModalDialog'
import CommonLargeButton from 'atoms/common/CommonLargeButton'
import CommonLargeButtonPrimary from 'atoms/common/CommonLargeButtonPrimary'
import CommonFullWidthButton from 'atoms/common/CommonFullWidthButton'

export default {
  components: {
    CommonLargeButton,
    CommonLargeButtonPrimary,
    CommonModalDialog,
    CommonFullWidthButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: '決定'
    },
    width: {
      type: Number,
      default: 640,
    },
    actionColor: {
      type: String,
      default: 'primary'
    },
    cancelColor: {
      type: String,
      default: 'gray'
    },
  },
  computed: {
    isXS () {
      return this.$vuetify.breakpoint.xs
    },
    buttonWidth () {
      return this.isXS ? 140 : 280
    },
  },
  methods: {
    _cancel () {
      this.$emit('cancel')
      this.$emit('input', false)
    },
    _submit () {
      this.$emit('submit')
      this.$emit('input', false)
    },
  }
}
