
import dynamicLinks from '~/mixins/dynamicLinks'

export default {
  mixins: [
    dynamicLinks,
  ],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    linkId: { type: Number, required: true },
  },
}
