import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'

const {
  required,
  max,
  min,
  digits,
  email,
} = rules

extend('required', {
  ...required,
  message: '{_field_}を入力してください',
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください',
})

extend('min', {
  ...min,
  message: '{length}文字以上入力してください',
})

extend('digits', {
  ...digits,
  message: '{length}桁以内の数字を入力してください. ({_value_})',
})

extend('email', {
  ...email,
  message: '正しいメールアドレスを入力してください',
  skipOptional: true
})

extend('max_value', {
  ...rules.max_value,
  message: '{max}以下で入力してください'
})

extend('min_value', {
  ...rules.min_value,
  message: '{min}以上で入力してください'
})

// 現場監査記録書 表紙の会社名
extend('cover_label', {
  params: ['maxStr', 'maxLine'],
  validate (value, { maxStr, maxLine }) {
    const newLines = value.match(/\r\n|\r|\n/g)

    if (!newLines) {
      return value.length <= maxStr
    }
    if (newLines.length >= maxLine) {
      return false
    }
    const lines = value.split(/\r\n|\r|\n/g)
    return !lines.some(line => line.length > (maxStr / maxLine))
  },
  message: '' // 入力制限については常にコールアウトで表示するためメッセージ不要。
})

// 複数行用
extend('multi_line', {
  params: ['maxLineStr', 'maxLine'],
  validate (value, { maxLineStr, maxLine }) {
    const newLines = value.match(/\r\n|\r|\n/g)

    if (!newLines) {
      return value.length <= maxLineStr
    }
    if (newLines.length >= maxLine) {
      return false
    }
    const lines = value.split(/\r\n|\r|\n/g)
    return !lines.some(line => line.length > maxLineStr)
  },
  message: ''
})

extend('jp_postal', (value) => {
  if (!value || !value.length) {
    return true
  }
  if (/^[0-9]{3}-?[0-9]{4}$/.test(value)) {
    return true
  }
  return '郵便番号を入力してください'
})

extend('password-confirm', {
  params: ['password'],
  validate (value, { password }) {
    return value === password
  },
  message: 'パスワードが一致しません'
})

extend('house_number', {
  params: ['others'],
  validate: (value, { others }) => {
    if (value) {
      return true
    }
    return {
      valid: !!others,
      required: false,
    }
  },
  message: '番地もしくは地番を入力してください',
  computesRequired: true
})

extend('required_if', {
  ...rules.required_if,
  message: '入力してください'
})

extend('required_either', {
  params: ['others'],
  validate: (value, { others }) => {
    if (value) {
      return true
    }
    return {
      valid: !!others,
      required: false,
    }
  },
  message: 'どちらか入力してください',
  computesRequired: true
})

extend('tel', {
  validate: (value) => {
    if (!value) {
      return true
    }
    return /^\+?[\d-.()]+(\d)$/.exec(value)
  },
  computesRequired: false,
  message: '電話番号を入力してください',
})

extend('full_width_kana', {
  validate: (value) => {
    if (!value) {
      return true
    }
    return /^[\u30A0-\u30FF\s]+$/.exec(value)
  },
  message: 'カタカナで入力してください',
})

extend('before_end_month', {
  params: ['endMonth'],
  validate (value, { endMonth }) {
    const start = moment(value)
    const end = moment(endMonth)

    return start.isSameOrBefore(end)
  },
  message: '期間は開始月が終了月以前になるよう入力してください。'
})

extend('within_one_years', {
  params: ['endMonth'],
  validate (value, { endMonth }) {
    const start = moment(value)
    const end = moment(endMonth)

    return end.diff(start, 'months') < 12
  },
  message: '期間は1年以内になるよう入力してください。'
})
