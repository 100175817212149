import moment from 'moment'
import currentVersion from '~/assets/VERSION.txt'

const HASH_LENGTH = 8

export default {
  getUpdate (state) {
    return state.update
  },
  getCurrentVersion (_) {
    if (!currentVersion) {
      return ''
    }
    return currentVersion.substr(0, HASH_LENGTH)
  },
  currentMaintenance (state) {
    if (!state.maintenance) {
      return false
    }
    const now = moment()
    return state.maintenance.find((info) => {
      if (!info.start) {
        return false
      }
      const start = moment(info.start)
      if (now.isBefore(start)) {
        // not yet started
        return false
      }

      if (!info.end) {
        // no end time
        return true
      }
      const end = moment(info.end)
      if (now.isAfter(end)) {
        // already ended
        return false
      }
      return true
    })
  }
}
