
export default {
  props: {
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    setStyle () {
      if (this.size <= 0) {
        return {}
      }
      return {
        width: this.size,
        height: this.size
      }
    }
  },
  methods: {
    iconName () { // リアクティブにする為、methodsで定義
      return this.$slots.default[0].text
    }
  }
}
